"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configureApi = configureApi;
exports.fetch = fetch;

var _axios = _interopRequireDefault(require("axios"));

var _TableActions = require("./actions/TableActions");

var _selectors = require("./store/apiFlow/authRefresh/selectors");

var _actions = require("./store/apiFlow/authRefresh/actions");

var _errorCodes = require("./constants/errorCodes");

var _actions2 = require("./store/auth/actions");

var _NotificationUtils = require("./utils/NotificationUtils");

var _config = require("./config");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// TODO: Remove axios usage.
function configureApi(store) {
  _axios["default"].defaults.baseURL = _config.Config.get('settings', 'apiBaseUrl');

  _axios["default"].interceptors.request.use(function (config) {
    var _store$getState$auth = store.getState().auth,
        accessToken = _store$getState$auth.accessToken,
        tokenType = _store$getState$auth.tokenType;

    if (accessToken) {
      config.headers.authorization = tokenType + " " + accessToken;
    } // Set emulation partner header


    var emulationPartnerId = store.getState().partnerEmulation.partnerId;

    if (emulationPartnerId) {
      config.headers.emulationPartnerId = emulationPartnerId;
    }

    return config;
  }, function (err) {
    return Promise.reject(err);
  });

  _axios["default"].interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.message === 'Network Error') {
      return Promise.reject(error);
    }

    if (_axios["default"].isCancel(error)) {
      console.log('Request canceled', error.message);
      return Promise.reject(error);
    } // Fix for response when try download file but got error


    if (error.response.data instanceof Blob && error.response.data.type === 'application/json') {
      var reader = new FileReader();

      reader.onload = function () {
        var data = JSON.parse(reader.result);
        (0, _NotificationUtils.showError)(data.message);
      };

      reader.readAsText(error.response.data);
    } else {
      (0, _NotificationUtils.showError)(error.message);
    }

    var errorObj = error.response.data;
    var config = error.config;
    var refreshIsFetching = (0, _selectors.getIsFetching)(store.getState());

    if ([_errorCodes.ERROR_CODES.ACCESS_TOKEN_EXPIRED, _errorCodes.ERROR_CODES.INVALID_ACCESS_TOKEN].includes(errorObj.code)) {
      if (!refreshIsFetching) {
        store.dispatch((0, _actions.saga)()); // TODO: This code temporary used, wile saga refactor not completed.

        return new Promise(function (resolve) {
          // Wait 2 seconds, and repeat request
          setTimeout(function () {
            var _store$getState$auth2 = store.getState().auth,
                accessToken = _store$getState$auth2.accessToken,
                tokenType = _store$getState$auth2.tokenType;
            config.headers.authorization = tokenType + " " + accessToken;
            config.baseURL = '';
            config.url = '/' + config.url;
            resolve(_axios["default"].request(config));
          }, 2000);
        });
      }
    }

    if ([_errorCodes.ERROR_CODES.REFRESH_TOKEN_EXPIRED, _errorCodes.ERROR_CODES.REFRESH_FAILURE].includes(error.code)) {
      (0, _NotificationUtils.showError)('auth/inactivityLogout/text');
      store.dispatch((0, _actions2.unauthorizeSaga)());
    }

    return Promise.reject(error);
  });
}

function fetch(url, params) {
  var requestParams = {
    params: params,
    paramsSerializer: function paramsSerializer(params) {
      return (0, _TableActions.parseParams)(params);
    }
  };

  if (!url) {
    throw new Error("Url not set");
  }

  return _axios["default"].get("".concat(url), requestParams);
}