"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIELDS_PRELOAD_COMPLETE = exports.UPDATE_PROPS = exports.UNMOUNT = exports.MOUNT = exports.CREATE = void 0;
var CREATE = 'filter/forms/CREATE';
exports.CREATE = CREATE;
var MOUNT = 'filter/forms/MOUNT';
exports.MOUNT = MOUNT;
var UNMOUNT = 'filter/forms/UNMOUNT';
exports.UNMOUNT = UNMOUNT;
var UPDATE_PROPS = 'filter/forms/UPDATE_PROPS';
exports.UPDATE_PROPS = UPDATE_PROPS;
var FIELDS_PRELOAD_COMPLETE = 'filters/forms/FIELDS_PRELOAD_COMPLETE';
exports.FIELDS_PRELOAD_COMPLETE = FIELDS_PRELOAD_COMPLETE;