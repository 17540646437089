"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET = exports.SET_FIELD = exports.PRELOAD_COMPLETE = exports.APPLY = exports.SET = void 0;
var SET = 'filters/values/SET';
exports.SET = SET;
var APPLY = 'filters/values/APPLY';
exports.APPLY = APPLY;
var PRELOAD_COMPLETE = 'filters/values/PRELOAD_COMPLETE';
exports.PRELOAD_COMPLETE = PRELOAD_COMPLETE;
var SET_FIELD = 'filters/values/SET_FIELD';
exports.SET_FIELD = SET_FIELD;
var RESET = 'filters/values/RESET';
exports.RESET = RESET;