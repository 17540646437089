"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Redirect", {
  enumerable: true,
  get: function get() {
    return _reactRouter.Redirect;
  }
});
Object.defineProperty(exports, "Route", {
  enumerable: true,
  get: function get() {
    return _reactRouter.Route;
  }
});
Object.defineProperty(exports, "Switch", {
  enumerable: true,
  get: function get() {
    return _reactRouter.Switch;
  }
});
Object.defineProperty(exports, "BrowserRouter", {
  enumerable: true,
  get: function get() {
    return _reactRouterDom.BrowserRouter;
  }
});
Object.defineProperty(exports, "Link", {
  enumerable: true,
  get: function get() {
    return _reactRouterDom.Link;
  }
});
Object.defineProperty(exports, "withRouter", {
  enumerable: true,
  get: function get() {
    return _reactRouterDom.withRouter;
  }
});

var _reactRouter = require("react-router");

var _reactRouterDom = require("react-router-dom");