"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TableView", {
  enumerable: true,
  get: function get() {
    return _TableView.TableView;
  }
});

var _TableView = require("./TableView.connect");