"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.App = void 0;

var _reactRedux = require("react-redux");

var _App = require("./App");

var _selectors = require("../../store/messages/selectors");

var _selectors2 = require("../../store/auth/selectors");

var _selectors3 = require("../../store/locale/selectors");

var _selectors4 = require("../../store/apiFlow/messages/selectors");

var _selectors5 = require("../../store/app/updateFlow/selectors");

var _selectors6 = require("../../store/version/selectors");

var mapStateToProps = function mapStateToProps(state) {
  return {
    isTestEnvironment: (0, _selectors6.getIsTestEnvironment)(state),
    isUpdateProcess: (0, _selectors5.getIsProcessing)(state),
    newVersion: (0, _selectors5.getNewVersion)(state),
    version: (0, _selectors6.getVersion)(state),
    locale: (0, _selectors3.getLocale)(state),
    isAuthorized: (0, _selectors2.getIsAuth)(state),
    isMessagesFetching: (0, _selectors4.getIsFetching)(state),
    messages: (0, _selectors.getMessages)(state),
    messagesLoadError: (0, _selectors4.getError)(state)
  };
};

var mapDispatchToProps = {};
var App = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_App.App);
exports.App = App;