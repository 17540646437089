"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = filterValue;

var _actionTypes = require("../constants/actionTypes");

var _filters = require("../constants/filters");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initState = _filters.FILTER_DEFAULTS;

function filterValue() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var newState = _objectSpread({}, state);

  switch (action.type) {
    case _actionTypes.SET_FILTER_DATA:
      if (action.filterType === _filters.FILTER_TYPE.PAYMENT_SUMMARY || action.filterType === _filters.FILTER_TYPE.PAYMENT_TRANSACTIONS) {
        newState[_filters.FILTER_TYPE.PAYMENT_SUMMARY] = _objectSpread({}, state[_filters.FILTER_TYPE.PAYMENT_SUMMARY], {}, action.filterData);
        newState[_filters.FILTER_TYPE.PAYMENT_TRANSACTIONS] = _objectSpread({}, state[_filters.FILTER_TYPE.PAYMENT_TRANSACTIONS], {}, action.filterData);
      } else {
        newState[action.filterType] = _objectSpread({}, state[action.filterType], {}, action.filterData); // TODO: refactor. maybe bring this logic to the OrderFilter component
        // Synchronously update dates interval in common filters

        if (action.filterType.startsWith("commonOrders") && action.filterData && (action.filterData.fromDate || action.filterData.toDate)) {
          newState.commonOrdersCumulative = _objectSpread({}, state.commonOrdersCumulative, {}, action.filterData);
          newState.commonOrdersSecondaryCumulative = _objectSpread({}, state.commonOrdersSecondaryCumulative, {}, action.filterData);
          newState.commonOrdersFull = _objectSpread({}, state.commonOrdersFull, {}, action.filterData);
          newState.commonOrdersChart = _objectSpread({}, state.commonOrdersChart, {}, action.filterData);
          newState.commonOrdersSecondaryChart = _objectSpread({}, state.commonOrdersSecondaryChart, {}, action.filterData);
        }
      }

      return newState;

    case _actionTypes.RESET_FILTER_PARAM:
      newState[action.filterType][action.filterType] = {};

    case _actionTypes.RESET_FILTER_DATA:
      if (action.filterType === _filters.FILTER_TYPE.PAYMENT_SUMMARY || action.filterType === _filters.FILTER_TYPE.PAYMENT_TRANSACTIONS) {
        newState[_filters.FILTER_TYPE.PAYMENT_SUMMARY] = _objectSpread({}, _filters.FILTER_DEFAULTS[_filters.FILTER_TYPE.PAYMENT_SUMMARY] || {});
        newState[_filters.FILTER_TYPE.PAYMENT_TRANSACTIONS] = _objectSpread({}, _filters.FILTER_DEFAULTS[_filters.FILTER_TYPE.PAYMENT_TRANSACTIONS] || {});
      } else {
        newState[action.filterType] = _objectSpread({}, _filters.FILTER_DEFAULTS[action.filterType] || {}); // TODO: refactor. maybe bring this logic to the OrderFilter component
        // Synchronously update data in common filters

        if (action.filterType.startsWith("commonOrders")) {
          newState.commonOrdersCumulative = _objectSpread({}, _filters.FILTER_DEFAULTS.commonOrdersCumulative || {});
          newState.commonOrdersFull = _objectSpread({}, _filters.FILTER_DEFAULTS.commonOrdersFull || {});
          newState.commonOrdersChart = _objectSpread({}, _filters.FILTER_DEFAULTS.commonOrdersChart || {});
          newState.commonOrdersSecondaryChart = _objectSpread({}, _filters.FILTER_DEFAULTS.commonOrdersSecondaryChart || {});
          newState.commonOrdersSecondaryCumulative = _objectSpread({}, _filters.FILTER_DEFAULTS.commonOrdersSecondaryCumulative || {});
        }
      }

      return newState;

    default:
      return state;
  }
}