"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CLOSE = exports.SET_BUTTON_TEXT = exports.SET_TEXT = exports.SET_TITLE = exports.SHOW = void 0;
var SHOW = 'modal/SHOW';
exports.SHOW = SHOW;
var SET_TITLE = 'modal/SET_TITLE';
exports.SET_TITLE = SET_TITLE;
var SET_TEXT = 'modal/SET_TEXT';
exports.SET_TEXT = SET_TEXT;
var SET_BUTTON_TEXT = 'modal/SET_BUTTON_TEXT';
exports.SET_BUTTON_TEXT = SET_BUTTON_TEXT;
var CLOSE = 'modal/CLOSE';
exports.CLOSE = CLOSE;