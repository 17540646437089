"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsFetching = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getIsFetching = function getIsFetching(state) {
  return _lodash["default"].get(state.apiFlow.userPermissions.changeStatusBatch, ['isFetching'], false);
};

exports.getIsFetching = getIsFetching;