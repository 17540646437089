"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsReady = exports.getIsAborted = exports.getErrorReason = exports.getError = exports.getIsFetching = void 0;

var getIsFetching = function getIsFetching(state) {
  return Boolean(state.apiFlow.asyncUpload.isFetching);
};

exports.getIsFetching = getIsFetching;

var getError = function getError(state) {
  return state.apiFlow.asyncUpload.error;
};

exports.getError = getError;

var getErrorReason = function getErrorReason(state) {
  return state.apiFlow.asyncUpload.errorReason;
};

exports.getErrorReason = getErrorReason;

var getIsAborted = function getIsAborted(state) {
  return state.apiFlow.asyncUpload.isAborted;
};

exports.getIsAborted = getIsAborted;

var getIsReady = function getIsReady(state) {
  return state.apiFlow.asyncUpload.ready;
};

exports.getIsReady = getIsReady;