"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginForm = void 0;

var _reactRedux = require("react-redux");

var _TranslateAware = require("../../components/TranslateAware/TranslateAware");

var _LoginForm = require("./LoginForm");

var _antd = require("antd");

var _actions = require("../../store/apiFlow/auth/actions");

var _selectors = require("../../store/apiFlow/auth/selectors");

var _selectors2 = require("../../store/error/selectors");

var mapStateToProps = function mapStateToProps(state) {
  return {
    isLoading: (0, _selectors.getIsFetching)(state),
    error: (0, _selectors2.getError)(state)
  };
};

var mapDispatchToProps = {
  auth: _actions.saga
};
var LoginForm = (0, _TranslateAware.TranslateAware)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_antd.Form.create()(_LoginForm.LoginForm)));
exports.LoginForm = LoginForm;