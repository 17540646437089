"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiFlowSagas = void 0;

var _saga = require("./auth/saga");

var _saga2 = require("./authRefresh/saga");

var _saga3 = require("./data/saga");

var _saga4 = require("./filterOptions/saga");

var _saga5 = require("./messages/saga");

var _saga6 = require("./version/saga");

var _sagas = require("./emailSettings/sagas");

var _sagas2 = require("./customTableViews/sagas");

var _sagas3 = require("./userPermissions/sagas");

var _saga7 = require("./download/saga");

var _saga8 = require("./download/async/saga");

var _saga9 = require("./upload/async/saga");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var apiFlowSagas = [_saga.authWatcher, _saga2.authRefreshWatcher, _saga3.dataWatcher, _saga4.filterOptionsWatcher, _saga5.messagesWatcher, _saga6.versionWatcher, _saga7.downloadWatcher, _saga8.asyncDownloadWatcher, _saga9.asyncUploadWatcher].concat(_toConsumableArray(_sagas.emailSettingsSagas), _toConsumableArray(_sagas2.customTableViewsSagas), _toConsumableArray(_sagas3.userPermissionsSagas));
exports.apiFlowSagas = apiFlowSagas;