"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MEDIA_SELF_REPORT_EXCEL_URL = exports.COCKPIT_SLA_RATIOS_REPORT_EXCEL_URL = exports.ORDER_STATISTICS_REPORT_EXCEL_URL = exports.SALES_REPORT_EXCEL_URL = exports.PRODUCT_STATUS_OPEN_TASK_HISTORY_COUNT_GRAPH_URL = exports.PAYMENTS_SUMMARY_REPORT_XLS_URL = exports.PAYMENTS_SUMMARY_REPORT_ZIP_URL = exports.FIRST_IS_PRESENT_URL = exports.UPLOAD_URLS = exports.DOWNLOAD_URLS = void 0;
var DOWNLOAD_URLS = {
  DOWNLOAD_STATUS: 'orders/download',
  ASYNC_FILE_DOWNLOAD: "async/file/",
  PRODUCT_STATUS_REPORTS_SINGLE_REPORT_URL: 'productStatus/reports/singleReport',
  PRODUCT_DATA_REPORTS_SINGLE_REPORT_URL: 'product-data/reports/singleReport',
  WARNING_HISTORY_REPORTS_URL: 'productStatus/warnings/reports/singleReport',
  IMPORT_ADMIN_DEBUG_VALUE_GROUP_ANALYSIS_REPORT_URL: 'import/admin-debug/value-group-analysis/reports/excel',
  INVOICE_OPOS_LIST: 'invoices/history/excelOpos'
};
exports.DOWNLOAD_URLS = DOWNLOAD_URLS;
var UPLOAD_URLS = {
  UPLOAD_STATUS: 'orders/upload',
  IMPORT_UPLOAD_FILE_MASTER_DATA_URL: 'data-import/file-upload/master-data/file'
};
exports.UPLOAD_URLS = UPLOAD_URLS;
var FIRST_IS_PRESENT_URL = 'orders/first';
exports.FIRST_IS_PRESENT_URL = FIRST_IS_PRESENT_URL;
var PAYMENTS_SUMMARY_REPORT_ZIP_URL = 'payments/summary/singleZipDoc';
exports.PAYMENTS_SUMMARY_REPORT_ZIP_URL = PAYMENTS_SUMMARY_REPORT_ZIP_URL;
var PAYMENTS_SUMMARY_REPORT_XLS_URL = 'payments/summary/singleExcelDoc';
exports.PAYMENTS_SUMMARY_REPORT_XLS_URL = PAYMENTS_SUMMARY_REPORT_XLS_URL;
var PRODUCT_STATUS_OPEN_TASK_HISTORY_COUNT_GRAPH_URL = 'productStatus/open-task-history-charts/count';
exports.PRODUCT_STATUS_OPEN_TASK_HISTORY_COUNT_GRAPH_URL = PRODUCT_STATUS_OPEN_TASK_HISTORY_COUNT_GRAPH_URL;
var SALES_REPORT_EXCEL_URL = 'orders/sales/excel';
exports.SALES_REPORT_EXCEL_URL = SALES_REPORT_EXCEL_URL;
var ORDER_STATISTICS_REPORT_EXCEL_URL = 'order-statistics/excel';
exports.ORDER_STATISTICS_REPORT_EXCEL_URL = ORDER_STATISTICS_REPORT_EXCEL_URL;
var COCKPIT_SLA_RATIOS_REPORT_EXCEL_URL = 'cockpit/sla-ratios/excel';
exports.COCKPIT_SLA_RATIOS_REPORT_EXCEL_URL = COCKPIT_SLA_RATIOS_REPORT_EXCEL_URL;
var MEDIA_SELF_REPORT_EXCEL_URL = 'media-self/excel';
exports.MEDIA_SELF_REPORT_EXCEL_URL = MEDIA_SELF_REPORT_EXCEL_URL;