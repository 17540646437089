"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET = exports.SET_FIELD = exports.SET = exports.APPLY = void 0;
var APPLY = 'data/filters/APPLY';
exports.APPLY = APPLY;
var SET = 'data/filters/SET';
exports.SET = SET;
var SET_FIELD = 'data/filters/SET_FIELD';
exports.SET_FIELD = SET_FIELD;
var RESET = 'data/filters/RESET';
exports.RESET = RESET;