"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filters = void 0;

var _redux = require("redux");

var _reducer = require("./forms/reducer");

var _reducer2 = require("./fields/reducer");

var _reducer3 = require("./value/reducer");

var _reducer4 = require("./options/reducer");

var _reducer5 = require("./settings/reducer");

var filters = (0, _redux.combineReducers)({
  forms: _reducer.forms,
  fields: _reducer2.fields,
  value: _reducer3.value,
  options: _reducer4.options,
  settings: _reducer5.settings
});
exports.filters = filters;