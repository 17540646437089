"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValue = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getValue = function getValue(state, dataType) {
  return _lodash["default"].get(state.data.filters, dataType, {});
}; // export const getFilterFieldValue = (state, filterType, fieldName) =>
//     _.get(state.dataView.filters, [filterType, fieldName], null);


exports.getValue = getValue;