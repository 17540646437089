"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_VIEW_DATA_FILTER = exports.FETCH_VIEW_DATA = exports.UNMOUNT_VIEW_DATA_OBJECT = exports.MOUNT_VIEW_DATA_OBJECT = exports.UPDATE_FRONT_VERSION_COMPLETE = exports.UPDATE_FRONT_VERSION_BEGIN = exports.LOAD_MESSAGES_FAILURE = exports.LOAD_MESSAGES_REQUEST = exports.SET_LOCALE = exports.MODAL_USER_FORBIDDEN = exports.DELAYED = exports.CLOSE_MODAL = exports.FILE_DOWNLOAD_STARTED = exports.CLOSE_NOTIFICATION = exports.SHOW_NOTIFICATION = exports.SEARCH_COLUMN_FILTER_OPTIONS = exports.FETCH_COLUMN_FILTER_OPTIONS_ERROR = exports.FETCH_COLUMN_FILTER_OPTIONS = exports.SET_COLUMN_FILTER_OPTIONS = exports.INIT_COLUMN_FILTER_OPTIONS = exports.RESET_FILTER_DATA = exports.RESET_FILTER_PARAM = exports.SET_FILTER_DATA = exports.RESET_SLA_GRAPHS_FILTER = exports.RESET_ORDER_FILTER_DATA = exports.SET_SLA_GRAPHS_FILTER = exports.SET_PRODUCT_STATUS_FILTER = exports.SET_ORDER_DATE_TABLE_FILTER = exports.SET_ORDER_FILTER_DATA = exports.SET_CUMULATIVE_FILTER_DATA = exports.SET_FULL_FILTER_DATA = exports.CHANGE_NOTIFICATION_STATUS_FAILURE = exports.CHANGE_NOTIFICATION_STATUS_SUCCESS = exports.CHANGE_NOTIFICATION_STATUS_REQUEST = exports.SAVE_EMAIL_SETTINGS_FAILURE = exports.SAVE_EMAIL_SETTINGS_SUCCESS = exports.SAVE_EMAIL_SETTINGS_REQUEST = exports.LOAD_EMAIL_SETTINGS_FAILURE = exports.LOAD_EMAIL_SETTINGS_SUCCESS = exports.LOAD_EMAIL_SETTINGS_REQUEST = exports.LOAD_PAYMENT_TRANSACTIONS = exports.LOAD_PAYMENT_SUMMARY = exports.LOAD_ORDERS_CONFIRMATIONS = exports.LOAD_CANCELLATION_ALERTS_HISTORY = exports.LOAD_ERROR = exports.LOAD_IS_PRESENT = exports.LOAD_CUMULATIVE = exports.LOAD_FULL_LIST = exports.LOAD_OK = exports.LOAD_STARTED_RESET = exports.LOAD_STARTED = void 0;
// Table core.actions
var LOAD_STARTED = 'LOAD_STARTED';
exports.LOAD_STARTED = LOAD_STARTED;
var LOAD_STARTED_RESET = 'LOAD_STARTED_RESET';
exports.LOAD_STARTED_RESET = LOAD_STARTED_RESET;
var LOAD_OK = 'LOAD_OK';
exports.LOAD_OK = LOAD_OK;
var LOAD_FULL_LIST = 'LOAD_FULL_LIST';
exports.LOAD_FULL_LIST = LOAD_FULL_LIST;
var LOAD_CUMULATIVE = 'LOAD_CUMULATIVE';
exports.LOAD_CUMULATIVE = LOAD_CUMULATIVE;
var LOAD_IS_PRESENT = 'LOAD_IS_PRESENT';
exports.LOAD_IS_PRESENT = LOAD_IS_PRESENT;
var LOAD_ERROR = 'LOAD_ERROR';
exports.LOAD_ERROR = LOAD_ERROR;
var LOAD_CANCELLATION_ALERTS_HISTORY = 'LOAD_CANCELLATION_ALERTS_HISTORY';
exports.LOAD_CANCELLATION_ALERTS_HISTORY = LOAD_CANCELLATION_ALERTS_HISTORY;
var LOAD_ORDERS_CONFIRMATIONS = 'LOAD_ORDERS_CONFIRMATIONS';
exports.LOAD_ORDERS_CONFIRMATIONS = LOAD_ORDERS_CONFIRMATIONS;
var LOAD_PAYMENT_SUMMARY = 'LOAD_PAYMENT_SUMMARY';
exports.LOAD_PAYMENT_SUMMARY = LOAD_PAYMENT_SUMMARY;
var LOAD_PAYMENT_TRANSACTIONS = 'LOAD_PAYMENT_TRANSACTIONS'; // Settings core.actions

exports.LOAD_PAYMENT_TRANSACTIONS = LOAD_PAYMENT_TRANSACTIONS;
var LOAD_EMAIL_SETTINGS_REQUEST = 'LOAD_EMAIL_SETTINGS_REQUEST';
exports.LOAD_EMAIL_SETTINGS_REQUEST = LOAD_EMAIL_SETTINGS_REQUEST;
var LOAD_EMAIL_SETTINGS_SUCCESS = 'LOAD_EMAIL_SETTINGS_SUCCESS';
exports.LOAD_EMAIL_SETTINGS_SUCCESS = LOAD_EMAIL_SETTINGS_SUCCESS;
var LOAD_EMAIL_SETTINGS_FAILURE = 'LOAD_EMAIL_SETTINGS_FAILURE';
exports.LOAD_EMAIL_SETTINGS_FAILURE = LOAD_EMAIL_SETTINGS_FAILURE;
var SAVE_EMAIL_SETTINGS_REQUEST = 'SAVE_EMAIL_SETTINGS_REQUEST';
exports.SAVE_EMAIL_SETTINGS_REQUEST = SAVE_EMAIL_SETTINGS_REQUEST;
var SAVE_EMAIL_SETTINGS_SUCCESS = 'SAVE_EMAIL_SETTINGS_SUCCESS';
exports.SAVE_EMAIL_SETTINGS_SUCCESS = SAVE_EMAIL_SETTINGS_SUCCESS;
var SAVE_EMAIL_SETTINGS_FAILURE = 'SAVE_EMAIL_SETTINGS_FAILURE';
exports.SAVE_EMAIL_SETTINGS_FAILURE = SAVE_EMAIL_SETTINGS_FAILURE;
var CHANGE_NOTIFICATION_STATUS_REQUEST = 'CHANGE_NOTIFICATION_STATUS_REQUEST';
exports.CHANGE_NOTIFICATION_STATUS_REQUEST = CHANGE_NOTIFICATION_STATUS_REQUEST;
var CHANGE_NOTIFICATION_STATUS_SUCCESS = 'CHANGE_NOTIFICATION_STATUS_SUCCESS';
exports.CHANGE_NOTIFICATION_STATUS_SUCCESS = CHANGE_NOTIFICATION_STATUS_SUCCESS;
var CHANGE_NOTIFICATION_STATUS_FAILURE = 'CHANGE_NOTIFICATION_STATUS_FAILURE'; // Filter core.actions

exports.CHANGE_NOTIFICATION_STATUS_FAILURE = CHANGE_NOTIFICATION_STATUS_FAILURE;
var SET_FULL_FILTER_DATA = 'SET_FULL_FILTER_DATA';
exports.SET_FULL_FILTER_DATA = SET_FULL_FILTER_DATA;
var SET_CUMULATIVE_FILTER_DATA = 'SET_CUMULATIVE_FILTER_DATA';
exports.SET_CUMULATIVE_FILTER_DATA = SET_CUMULATIVE_FILTER_DATA;
var SET_ORDER_FILTER_DATA = 'SET_ORDER_FILTER_DATA';
exports.SET_ORDER_FILTER_DATA = SET_ORDER_FILTER_DATA;
var SET_ORDER_DATE_TABLE_FILTER = 'SET_ORDER_DATE_TABLE_FILTER';
exports.SET_ORDER_DATE_TABLE_FILTER = SET_ORDER_DATE_TABLE_FILTER;
var SET_PRODUCT_STATUS_FILTER = 'SET_PRODUCT_STATUS_FILTER';
exports.SET_PRODUCT_STATUS_FILTER = SET_PRODUCT_STATUS_FILTER;
var SET_SLA_GRAPHS_FILTER = 'SET_SLA_GRAPHS_FILTER';
exports.SET_SLA_GRAPHS_FILTER = SET_SLA_GRAPHS_FILTER;
var RESET_ORDER_FILTER_DATA = 'RESET_ORDER_FILTER_DATA';
exports.RESET_ORDER_FILTER_DATA = RESET_ORDER_FILTER_DATA;
var RESET_SLA_GRAPHS_FILTER = 'RESET_SLA_GRAPHS_FILTER';
exports.RESET_SLA_GRAPHS_FILTER = RESET_SLA_GRAPHS_FILTER;
var SET_FILTER_DATA = 'SET_FILTER_DATA';
exports.SET_FILTER_DATA = SET_FILTER_DATA;
var RESET_FILTER_PARAM = 'RESET_FILTER_PARAM';
exports.RESET_FILTER_PARAM = RESET_FILTER_PARAM;
var RESET_FILTER_DATA = 'RESET_FILTER_DATA';
exports.RESET_FILTER_DATA = RESET_FILTER_DATA;
var INIT_COLUMN_FILTER_OPTIONS = 'INIT_COLUMN_FILTER_OPTIONS';
exports.INIT_COLUMN_FILTER_OPTIONS = INIT_COLUMN_FILTER_OPTIONS;
var SET_COLUMN_FILTER_OPTIONS = 'SET_COLUMN_FILTER_OPTIONS';
exports.SET_COLUMN_FILTER_OPTIONS = SET_COLUMN_FILTER_OPTIONS;
var FETCH_COLUMN_FILTER_OPTIONS = 'FETCH_COLUMN_FILTER_OPTIONS';
exports.FETCH_COLUMN_FILTER_OPTIONS = FETCH_COLUMN_FILTER_OPTIONS;
var FETCH_COLUMN_FILTER_OPTIONS_ERROR = 'FETCH_COLUMN_FILTER_OPTIONS_ERROR';
exports.FETCH_COLUMN_FILTER_OPTIONS_ERROR = FETCH_COLUMN_FILTER_OPTIONS_ERROR;
var SEARCH_COLUMN_FILTER_OPTIONS = 'SEARCH_COLUMN_FILTER_OPTIONS'; // Notifications

exports.SEARCH_COLUMN_FILTER_OPTIONS = SEARCH_COLUMN_FILTER_OPTIONS;
var SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
exports.SHOW_NOTIFICATION = SHOW_NOTIFICATION;
var CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'; // Modal core.actions

exports.CLOSE_NOTIFICATION = CLOSE_NOTIFICATION;
var FILE_DOWNLOAD_STARTED = 'FILE_DOWNLOAD_STARTED';
exports.FILE_DOWNLOAD_STARTED = FILE_DOWNLOAD_STARTED;
var CLOSE_MODAL = 'CLOSE_MODAL';
exports.CLOSE_MODAL = CLOSE_MODAL;
var DELAYED = 'DELAYED';
exports.DELAYED = DELAYED;
var MODAL_USER_FORBIDDEN = 'MODAL_USER_FORBIDDEN'; // Locale core.actions

exports.MODAL_USER_FORBIDDEN = MODAL_USER_FORBIDDEN;
var SET_LOCALE = "SET_LOCALE";
exports.SET_LOCALE = SET_LOCALE;
var LOAD_MESSAGES_REQUEST = "LOAD_MESSAGES_REQUEST";
exports.LOAD_MESSAGES_REQUEST = LOAD_MESSAGES_REQUEST;
var LOAD_MESSAGES_FAILURE = "LOAD_MESSAGES_FAILURE";
exports.LOAD_MESSAGES_FAILURE = LOAD_MESSAGES_FAILURE;
var UPDATE_FRONT_VERSION_BEGIN = "UPDATE_FRONT_VERSION_BEGIN";
exports.UPDATE_FRONT_VERSION_BEGIN = UPDATE_FRONT_VERSION_BEGIN;
var UPDATE_FRONT_VERSION_COMPLETE = "UPDATE_FRONT_VERSION_COMPLETE"; // View data

exports.UPDATE_FRONT_VERSION_COMPLETE = UPDATE_FRONT_VERSION_COMPLETE;
var MOUNT_VIEW_DATA_OBJECT = 'MOUNT_VIEW_DATA_OBJECT';
exports.MOUNT_VIEW_DATA_OBJECT = MOUNT_VIEW_DATA_OBJECT;
var UNMOUNT_VIEW_DATA_OBJECT = 'UNMOUNT_VIEW_DATA_OBJECT';
exports.UNMOUNT_VIEW_DATA_OBJECT = UNMOUNT_VIEW_DATA_OBJECT;
var FETCH_VIEW_DATA = 'FETCH_VIEW_DATA';
exports.FETCH_VIEW_DATA = FETCH_VIEW_DATA;
var UPDATE_VIEW_DATA_FILTER = 'UPDATE_VIEW_DATA_FILTER';
exports.UPDATE_VIEW_DATA_FILTER = UPDATE_VIEW_DATA_FILTER;