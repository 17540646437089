import React from "react";
import {Config} from "@onquality/dashboard-ui/lib/config";
import AsyncComponent from "@onquality/dashboard-ui/lib/components/AsyncComponent";
import {Redirect} from "@onquality/dashboard-ui/lib/router";
import {PERMISSIONS} from "@onquality/dashboard-ui/lib/constants/permissions";
import {RedirectToFirstMenu} from "@onquality/dashboard-ui/lib/components/RedirectToFirstMenu";

const router = {
    routes: [
        {
            path: '/',
            exact: true,
            children: <RedirectToFirstMenu/>
        },
        {
            path: '/sales-dashboard',
            exact: true,
            children: <Redirect to="/sales-dashboard/GrossRevenue"/>
        },
        {
            path: '/cockpit',
            title: 'sidebar/cockpit',
            component: AsyncComponent(() => import("views/Cockpit/Cockpit")),
            access: PERMISSIONS.VIEW_DASHBOARD
        }, {
            path: "/sales-dashboard/:reportKey",
            title: 'sidebar/salesDashboard',
            component: AsyncComponent(() => import("views/SalesDashboard/SalesDashboard")),
            access: PERMISSIONS.VIEW_KPIS
        }, {
            path: '/opened',
            title: 'sidebar/openedOrders',
            component: AsyncComponent(() => import("views/OpenedOrders/OpenedOrders")),
            access: PERMISSIONS.VIEW_ORDERS
        }, {
            path: '/sent-orders',
            title: 'sidebar/sentOrders',
            component: AsyncComponent(() => import("views/SentOrders/SentOrders")),
            access: PERMISSIONS.VIEW_ORDERS
        }, {
            path: '/delayed',
            title: 'sidebar/delayedOrders',
            component: AsyncComponent(() => import("views/DelayedOrders/DelayedOrders")),
            access: PERMISSIONS.VIEW_ORDERS
        }, {
            path: '/pending',
            title: 'sidebar/pendingOrders',
            component: AsyncComponent(() => import("views/PendingOrders/PendingOrders")),
            access: PERMISSIONS.VIEW_ORDERS
        }, {
            path: '/lacking_tracking_codes',
            title: 'sidebar/lackingTrackingCodes',
            component: AsyncComponent(() => import("views/LackingTCOrders/LackingTCOrders")),
            access: PERMISSIONS.VIEW_LACKING_TRACKING_CODES
        }, {
            path: '/first',
            title: 'sidebar/firstOrders',
            component: AsyncComponent(() => import("views/FirstOrders/FirstOrders")),
            access: PERMISSIONS.VIEW_FIRST_ORDERS
        }, {
            path: '/cancellations',
            title: 'sidebar/cancellations',
            component: AsyncComponent(() => import("views/CancelOrders/CancelOrders")),
            access: PERMISSIONS.VIEW_CANCELLATIONS
        }, {
            path: "/sla",
            title: 'sidebar/sla',
            component: AsyncComponent(() => import("views/SLA/SLA")),
            access: PERMISSIONS.VIEW_SLA
        }, {
            path: "/returns",
            title: 'sidebar/returns',
            component: AsyncComponent(() => import("views/ReturnOrders/ReturnOrders")),
            access: PERMISSIONS.VIEW_RETURNS
        }, {
            path: "/blocked-articles",
            title: 'sidebar/blockedArticles',
            component: AsyncComponent(() => import("views/BlockedArticles/BlockedArticles")),
            access: PERMISSIONS.VIEW_BLOCKED_ARTICLES
        }, {
            path: "/oversales",
            title: 'sidebar/overSales',
            component: AsyncComponent(() => import("views/Oversales/Oversales")),
            access: PERMISSIONS.VIEW_OVER_SALES
        }, {
            path: "/price-validation",
            title: 'sidebar/priceValidation',
            component: AsyncComponent(() => import("views/PriceValidation/PriceValidation")),
            access: PERMISSIONS.VIEW_PRICE_VALIDATION
        },
        {
            path: "/product-status",
            title: 'sidebar/productStatus',
            component: AsyncComponent(() => import("views/ProductStatus/ExportInfo/ExportInfo")),
            access: PERMISSIONS.VIEW_PRODUCT_STATUS
        },
        // TODO uncomment in DEVPD-1092
        // {
        //     path: "/product-status/overview",
        //     title: 'sidebar/productStatus/overview',
        //     component: AsyncComponent(() => import("views/ProductStatus/Overview/Overview"))
        // },
        // {
        //     path: "/product-status/warnings",
        //     title: 'sidebar/productStatus/warnings',
        //     component: AsyncComponent(() => import("views/ProductStatus/Warnings/Warnings"))
        // },
        // {
        //     path: "/product-status/export-info",
        //     title: 'sidebar/productStatus/exportInfo',
        //     component: AsyncComponent(() => import("views/ProductStatus/ExportInfo/ExportInfo"))
        // },
        // {
        //     path: "/product-status/channel-feedback",
        //     title: 'sidebar/productStatus/channelFeedback',
        //     component: AsyncComponent(() => import("common/views/Pages/Page404"))
        // },
        {
            path: "/invoices",
            title: 'sidebar/invoices',
            component: AsyncComponent(() => import("views/Invoices/Invoices")),
            access: PERMISSIONS.VIEW_INVOICES
        }, {
            path: "/payments",
            title: 'sidebar/payments',
            component: AsyncComponent(() => import("views/Payments/Payments")),
            access: PERMISSIONS.VIEW_PAYMENTS
        }, {
            path: "/settings",
            title: 'sidebar/settings',
            component: AsyncComponent(() => import("views/Settings/Settings")),
            access: PERMISSIONS.VIEW_EMAIL_SETTINGS
        }, {
            path: "/order-statistics",
            title: 'sidebar/orderStatistics',
            component: AsyncComponent(() => import("views/OrderStatistics")),
            access: PERMISSIONS.VIEW_ORDERS
        }, {
            path: "/summary-warehouse",
            title: 'sidebar/summaryWarehouse',
            component: AsyncComponent(() => import("views/SummaryWarehouses/SummaryWarehouse")),
            access: PERMISSIONS.VIEW_WAREHOUSE_STATISTICS
        }, {
            path: "/data-import",
            title: 'sidebar/dataImport',
            component: AsyncComponent(() => import("views/DataImport")),
            access: PERMISSIONS.VIEW_IMPORT_SERVICES
        }]
};

Config.applyConfig('router', router);
