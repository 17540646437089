"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.abort = exports.failure = exports.success = exports.setReady = exports.setStatus = exports.sagaStatus = exports.start = exports.saga = void 0;

var _reduxActions = require("redux-actions");

var types = _interopRequireWildcard(require("./types"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var saga = (0, _reduxActions.createAction)(types.SAGA, function (url, filterParams, comparison, method) {
  return {
    url: url,
    filterParams: filterParams,
    comparison: comparison,
    method: method
  };
});
exports.saga = saga;
var start = (0, _reduxActions.createAction)(types.START);
exports.start = start;
var sagaStatus = (0, _reduxActions.createAction)(types.SAGA_STATUS, function (exportKey, fileUUID) {
  return {
    exportKey: exportKey,
    fileUUID: fileUUID
  };
});
exports.sagaStatus = sagaStatus;
var setStatus = (0, _reduxActions.createAction)(types.SET_STATUS, function (status) {
  return {
    status: status
  };
});
exports.setStatus = setStatus;
var setReady = (0, _reduxActions.createAction)(types.SET_READY);
exports.setReady = setReady;
var success = (0, _reduxActions.createAction)(types.SUCCESS);
exports.success = success;
var failure = (0, _reduxActions.createAction)(types.FAILURE);
exports.failure = failure;
var abort = (0, _reduxActions.createAction)(types.ABORT);
exports.abort = abort;