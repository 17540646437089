"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tables = tables;

var _actionTypes = require("../constants/actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initState = {};

function tables() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.LOAD_STARTED:
      return _objectSpread({}, state, _defineProperty({
        isLoading: true
      }, action.tableType, _objectSpread({}, state[action.tableType], {
        isLoading: true
      })));

    case _actionTypes.LOAD_STARTED_RESET:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        isLoading: true
      })));

    case _actionTypes.LOAD_OK:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        list: action.payload,
        percent: action.payload.percent,
        status: action.status,
        isLoading: false
      })));

    case _actionTypes.LOAD_FULL_LIST:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        fullList: action.payload,
        isLoading: false
      })));

    case _actionTypes.LOAD_CUMULATIVE:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        cumulative: action.payload,
        percent: action.payload.percent,
        status: action.status,
        isLoading: false
      })));

    case _actionTypes.LOAD_IS_PRESENT:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        isPresent: action.payload,
        isLoading: false
      })));

    case _actionTypes.LOAD_ERROR:
      return _objectSpread({}, state, {}, initState, _defineProperty({}, action.tableType, {
        status: action.status ? action.status : 900,
        isLoading: false
      }));

    case _actionTypes.LOAD_CANCELLATION_ALERTS_HISTORY:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        alerts: action.response.data,
        status: action.response.status,
        isLoading: false
      })));

    case _actionTypes.LOAD_ORDERS_CONFIRMATIONS:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        confirmations: action.response.data,
        status: action.response.status,
        isLoading: false
      })));

    case _actionTypes.LOAD_PAYMENT_SUMMARY:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        paymentSummary: action.response.data,
        status: action.status,
        isLoading: false
      })));

    case _actionTypes.LOAD_PAYMENT_TRANSACTIONS:
      return _objectSpread({}, state, _defineProperty({}, action.tableType, _objectSpread({}, state[action.tableType], {
        paymentTransactions: action.response.data,
        status: action.status,
        isLoading: false
      })));

    default:
      return state;
  }
}