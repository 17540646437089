"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRelatedFilterFormByFilterType = exports.getFilterFormByFilterType = exports.getProperty = exports.getAllFilterForms = exports.getFilterForm = exports.getProps = exports.getNextId = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _component = require("../../../constants/component");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getNextId = function getNextId(state) {
  return state.filters.forms.reduce(function (maxId, form) {
    return Math.max(form.id, maxId);
  }, 0) + 1;
};

exports.getNextId = getNextId;

var getProps = function getProps(state, id) {
  return _lodash["default"].findLast(state.filters.forms, function (form) {
    return form.id === id;
  });
};

exports.getProps = getProps;

var getFilterForm = function getFilterForm(state, id) {
  return getProps(state, id);
};

exports.getFilterForm = getFilterForm;

var getAllFilterForms = function getAllFilterForms(state) {
  return state.filters.forms;
};

exports.getAllFilterForms = getAllFilterForms;

var getProperty = function getProperty(state, id, propertyName) {
  return _lodash["default"].get(getProps(state, id), propertyName);
};

exports.getProperty = getProperty;

var getFilterFormByFilterType = function getFilterFormByFilterType(state, filterType) {
  return state.filters.forms.filter(function (form) {
    return form[_component.PROPS.FILTER_TYPE] === filterType;
  });
};

exports.getFilterFormByFilterType = getFilterFormByFilterType;

var getRelatedFilterFormByFilterType = function getRelatedFilterFormByFilterType(state, filterType) {
  return state.filters.forms.filter(function (form) {
    return form[_component.PROPS.RELATED_FILTERS] && form[_component.PROPS.RELATED_FILTERS].includes(filterType);
  });
};

exports.getRelatedFilterFormByFilterType = getRelatedFilterFormByFilterType;