"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeTableViewButton = void 0;

var _TranslateAware = require("../TranslateAware");

var _connect = _interopRequireDefault(require("react-redux/es/connect/connect"));

var _ChangeTableViewButton = require("./ChangeTableViewButton");

var _selectors = require("../../store/customTableViews/selectors");

var _actions = require("../../store/apiFlow/customTableViews/loadAll/actions");

var _actions2 = require("../../store/apiFlow/customTableViews/save/actions");

var _selectors2 = require("../../store/locale/selectors");

var _selectors3 = require("../../store/user/selectors");

var _selectors4 = require("../../store/apiFlow/customTableViews/save/selectors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var mapStateToProps = function mapStateToProps(state) {
  return {
    locale: (0, _selectors2.getLocale)(state),
    savedViews: (0, _selectors.getCustomTableViews)(state),
    userName: (0, _selectors3.getUserName)(state),
    saveIsFetching: (0, _selectors4.getIsFetching)(state),
    saveError: (0, _selectors4.getError)(state)
  };
};

var mapDispatchToProps = {
  loadAllCustomTableViews: _actions.saga,
  saveCustomTableViews: _actions2.saga
};
var ChangeTableViewButton = (0, _TranslateAware.TranslateAware)((0, _connect["default"])(mapStateToProps, mapDispatchToProps)(_ChangeTableViewButton.ChangeTableViewButton));
exports.ChangeTableViewButton = ChangeTableViewButton;