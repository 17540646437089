"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setSelectTableRows = exports.TABLE_SELECTED_ROWS = void 0;

var _reportConsts = require("../constants/reportConsts");

var TABLE_SELECTED_ROWS = 'TABLE_SELECTED_ROWS';
exports.TABLE_SELECTED_ROWS = TABLE_SELECTED_ROWS;

var setSelectTableRows = function setSelectTableRows(table, ids, props) {
  var selectedIds = '';

  if (table === _reportConsts.TABLE_TYPE.OPENED_INVOICE || table === _reportConsts.TABLE_TYPE.HISTORY_INVOICE) {
    selectedIds = ids.map(function (id) {
      return props.data.records[id - 1].invoiceId;
    });
  } else if (table === _reportConsts.TABLE_TYPE.PAYMENTS) {
    selectedIds = ids.filter(function (id) {
      return props.data.records[id] && props.data.records[id].paymentId;
    }).map(function (id) {
      return props.data.records[id].paymentId;
    });
  }

  return function (dispatch) {
    dispatch({
      type: TABLE_SELECTED_ROWS,
      table: table,
      ids: ids,
      selectedIds: selectedIds
    });
  };
};

exports.setSelectTableRows = setSelectTableRows;