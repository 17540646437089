"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Config = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _settings = _interopRequireDefault(require("./settings"));

var _router = _interopRequireDefault(require("./router"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

global.globalAppConfig = {
  settings: _settings["default"],
  menu: {},
  router: _router["default"]
};
var Config = {
  get: function get(configName, key) {
    return _lodash["default"].get(global.globalAppConfig, [configName, key], null);
  },
  getAll: function getAll(configName) {
    return _lodash["default"].get(global.globalAppConfig, [configName], null);
  },
  applyConfig: function applyConfig(configName, values) {
    global.globalAppConfig[configName] = _lodash["default"].mergeWith(_lodash["default"].get(global.globalAppConfig, configName), values, function (objValue, srcValue) {
      if (_lodash["default"].isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    });
  }
};
exports.Config = Config;