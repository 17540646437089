"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiFlow = void 0;

var _redux = require("redux");

var _reducer = require("./data/reducer");

var _reducer2 = require("./auth/reducer");

var _reducer3 = require("./filterOptions/reducer");

var _reducer4 = require("./authRefresh/reducer");

var _reducer5 = require("./messages/reducer");

var _reducer6 = require("./version/reducer");

var _emailSettings = require("./emailSettings");

var _customTableViews = require("./customTableViews");

var _userPermissions = require("./userPermissions");

var _reducer7 = require("./download/reducer");

var _reducer8 = require("./download/async/reducer");

var _reducer9 = require("./upload/async/reducer");

var apiFlow = (0, _redux.combineReducers)({
  auth: _reducer2.auth,
  data: _reducer.data,
  filterOptions: _reducer3.filterOptions,
  authRefresh: _reducer4.authRefresh,
  messages: _reducer5.messages,
  version: _reducer6.version,
  emailSettings: _emailSettings.emailSettings,
  customTableViews: _customTableViews.customTableViews,
  userPermissions: _userPermissions.userPermissions,
  download: _reducer7.download,
  asyncDownload: _reducer8.asyncDownload,
  asyncUpload: _reducer9.asyncUpload
});
exports.apiFlow = apiFlow;