"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.successNotification = successNotification;
exports.infoNotification = infoNotification;
exports.warningNotification = warningNotification;
exports.errorNotification = errorNotification;
exports.closeNotification = closeNotification;

var _actionTypes = require("../constants/actionTypes");

var _notificationTypes = require("../constants/notificationTypes");

var NOTIFICATION_DEFAULT_TIMEOUT = 3 * 1000;

function successNotification(message) {
  var hideTimeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : NOTIFICATION_DEFAULT_TIMEOUT;
  return {
    type: _actionTypes.SHOW_NOTIFICATION,
    message: message,
    messageType: _notificationTypes.NOTIFICATION_SUCCESS_TYPE,
    hideTimeout: hideTimeout
  };
}

function infoNotification(message) {
  var hideTimeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : NOTIFICATION_DEFAULT_TIMEOUT;
  return {
    type: _actionTypes.SHOW_NOTIFICATION,
    message: message,
    messageType: _notificationTypes.NOTIFICATION_INFO_TYPE,
    hideTimeout: hideTimeout
  };
}

function warningNotification(message) {
  var hideTimeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : NOTIFICATION_DEFAULT_TIMEOUT;
  return {
    type: _actionTypes.SHOW_NOTIFICATION,
    message: message,
    messageType: _notificationTypes.NOTIFICATION_WARNING_TYPE,
    hideTimeout: hideTimeout
  };
}

function errorNotification(message) {
  var hideTimeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : NOTIFICATION_DEFAULT_TIMEOUT;
  return {
    type: _actionTypes.SHOW_NOTIFICATION,
    message: message,
    messageType: _notificationTypes.NOTIFICATION_ERROR_TYPE,
    hideTimeout: hideTimeout
  };
}

function closeNotification(id) {
  return {
    type: _actionTypes.CLOSE_NOTIFICATION,
    id: id
  };
}