"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emailSettings = void 0;

var _redux = require("redux");

var _reducer = require("./changeAllStatus/reducer");

var _reducer2 = require("./changeProperty/reducer");

var emailSettings = (0, _redux.combineReducers)({
  changeAllStatus: _reducer.changeAllStatus,
  changeProperty: _reducer2.changeProperty
});
exports.emailSettings = emailSettings;