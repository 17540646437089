"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEBUG_LOCALE = exports.USE_TRANSLATION_CONTEXT = exports.SKIP_TRANSLATION_CONTEXT = void 0;
var SKIP_TRANSLATION_CONTEXT = -1;
exports.SKIP_TRANSLATION_CONTEXT = SKIP_TRANSLATION_CONTEXT;
var USE_TRANSLATION_CONTEXT = 1;
exports.USE_TRANSLATION_CONTEXT = USE_TRANSLATION_CONTEXT;
var DEBUG_LOCALE = 'debugLocale';
exports.DEBUG_LOCALE = DEBUG_LOCALE;