"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ABORT = exports.FAILURE_WITH_PAYLOAD = exports.FAILURE = exports.SUCCESS = exports.SET_STATUS = exports.SET_READY = exports.SAGA_STATUS = exports.START = exports.SAGA = void 0;
var SAGA = 'apiFlow/upload/async/SAGA';
exports.SAGA = SAGA;
var START = 'apiFlow/upload/async/START';
exports.START = START;
var SAGA_STATUS = 'apiFlow/upload/async/SAGA_STATUS';
exports.SAGA_STATUS = SAGA_STATUS;
var SET_READY = 'apiFlow/upload/async/SET_READY';
exports.SET_READY = SET_READY;
var SET_STATUS = 'apiFlow/upload/async/SET_STATUS';
exports.SET_STATUS = SET_STATUS;
var SUCCESS = 'apiFlow/upload/async/SUCCESS';
exports.SUCCESS = SUCCESS;
var FAILURE = 'apiFlow/upload/async/FAILURE';
exports.FAILURE = FAILURE;
var FAILURE_WITH_PAYLOAD = 'apiFlow/upload/async/FAILURE_WITH_PAYLOAD';
exports.FAILURE_WITH_PAYLOAD = FAILURE_WITH_PAYLOAD;
var ABORT = 'apiFlow/upload/async/ABORT';
exports.ABORT = ABORT;