"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PROPS = void 0;
var PROPS = {
  ID: 'id',
  DATA_TYPE: 'dataType',
  FORM_ID: 'formId',
  FILTER_TYPE: 'filterType',
  RELATED_FILTERS: 'relatedFilters',
  FIELD_NAME: 'fieldName',
  IS_DEFAULT_FIRST_OPTION: 'defaultFirstOption',
  DEFAULT_OPTIONS: 'defaultOptions',
  IS_PRIMARY_FILTER: 'primaryFilter',
  OPTIONS_SOURCE: 'optionsSource',
  DATA_SOURCE: 'dataSource',
  FILTER_PARAMS: 'filterParams',
  MOUNTED: 'mounted',
  IS_FIELDS_PRELOADED: 'fieldsPreloaded',
  LOCALE: 'locale'
};
exports.PROPS = PROPS;