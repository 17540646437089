"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "UserPermissions", {
  enumerable: true,
  get: function get() {
    return _UserPermissions.UserPermissions;
  }
});
exports["default"] = void 0;

var _UserPermissions = require("./UserPermissions.connect");

var _default = _UserPermissions.UserPermissions;
exports["default"] = _default;