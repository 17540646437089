"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Full", {
  enumerable: true,
  get: function get() {
    return _Full.Full;
  }
});

var _Full = require("./Full.connect");