"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DATA_URLS = exports.DATA_TYPE = void 0;
var DATA_TYPE = {
  ARCHIVED_ARTICLES: 'archivedArticles',
  ARTICLES_CREATION: 'ARTICLES_CREATION',
  ARTICLES_CREATION_CHART: 'ARTICLES_CREATION_CHART',
  BLOCKED_FULL: 'blockedFull',
  BUSINESS_SUMMARY_EMAIL_HISTORY_WARNINGS: 'BusinessSummaryEmailHistoryWarnings',
  BUSINESS_SUMMARY_MESSAGES: 'BUSINESS_SUMMARY_MESSAGES',
  BUSINESS_SUMMARY_SOLUTIONS: 'BUSINESS_SUMMARY_SOLUTIONS',
  BUSINESS_SUMMARY_WARNINGS: 'BusinessSummaryWarnings',
  CANCELLED_ALERTS: 'cancelledOrdersAlerts',
  CANCELLED_CHART: 'cancelledOrdersChart',
  CANCELLED_CUMULATIVE: 'cancelledOrdersCumulative',
  CANCELLED_CUMULATIVE_CHART: 'cancelledOrdersCumulativeChart',
  CANCELLED_FULL: 'cancelledOrdersFull',
  COCKPIT_CANCELLATIONS_GRAPH: 'cockpitCancellationsGraph',
  COCKPIT_CHANNEL_GROSS: 'COCKPIT_CHANNEL_GROSS',
  COCKPIT_CHANNEL_GROSS_TABLE: 'COCKPIT_CHANNEL_GROSS_TABLE',
  COCKPIT_CHANNEL_YEAR_GROSS: 'COCKPIT_CHANNEL_YEAR_GROSS',
  COCKPIT_DELAYED: 'COCKPIT_DELAYED',
  COCKPIT_GROSS_REVENUE: 'COCKPIT_GROSS_REVENUE',
  COCKPIT_GROSS_REVENUE_10WEEKS: 'COCKPIT_GROSS_REVENUE_10WEEKS',
  COCKPIT_OPENED: 'COCKPIT_OPENED',
  COCKPIT_SLA12WEEKS: 'COCKPIT_SLA12WEEKS',
  COCKPIT_SLA4WEEKS: 'COCKPIT_SLA4WEEKS',
  COCKPIT_SLA7DAYS: 'COCKPIT_SLA7DAYS',
  COCKPIT_SLA_FAIL_GRAPH: 'cockpitSlaFailGraph',
  COCKPIT_TRENDS: 'COCKPIT_TRENDS',
  DELAYED_CHART: 'DELAYED_CHART',
  DELAYED_CUMULATIVE: 'DELAYED_CUMULATIVE',
  DELAYED_FULL: 'DELAYED_FULL',
  SENT_ORDER_UNCONFIRMED_WITH_CARRIER: 'SENT_ORDER_UNCONFIRMED_WITH_CARRIER',
  SENT_ORDER_DELIVERY_PROGRESS: 'SENT_ORDER_DELIVERY_PROGRESS',
  EMAIL_SETTINGS: 'emailSettings',
  FIRST_FULL: 'firstFull',
  HISTORY_INVOICE_FULL: 'historyInvoiceFull',
  LACKING_TC_CUMULATIVE: 'lackingTCOrdersCumulative',
  LACKING_TC_FULL: 'lackingTCOrdersFull',
  MEDIA_BLOCKED_GRAPH: 'MEDIA_BLOCKED_GRAPH',
  MEDIA_EMAIL_HISTORY_WARNINGS: 'MediaEmailHistoryWarnings',
  MEDIA_MESSAGES: 'MEDIA_MESSAGES',
  MEDIA_SELF_DATA: 'MEDIA_SELF_DATA',
  MEDIA_SOLUTIONS: 'MEDIA_SOLUTIONS',
  MEDIA_WARNINGS: 'productStatusMediaWarnings',
  MISSING_IDENT_CODE_FULL: 'missingIdentCodeFull',
  OPENED_CHART: 'OPENED_CHART',
  OPENED_CONFIRMATION: 'OPENED_CONFIRMATION',
  OPENED_CUMULATIVE: 'OPENED_CUMULATIVE',
  OPENED_FULL: 'OPENED_FULL',
  OPENED_INVOICE_FULL: 'openedInvoicesFull',
  ORDER_STATISTICS: 'orderStatistics',
  ORDER_STATISTICS_CANCELLATIONS: 'orderStatisticsCancellations',
  ORDER_STATISTICS_CONFIRMED: 'orderStatisticsConfirmed',
  ORDER_STATISTICS_RETURNS: 'orderStatisticsReturns',
  ORDER_STATISTICS_SHIPMENTS: 'orderStatisticsShipments',
  OVER_SALES_FULL: 'oversalesFull',
  PAYMENT_SUMMARY: 'paymentSummary',
  PAYMENT_TRANSACTIONS: 'paymentTransactions',
  PENDING_CHART: 'PENDING_CHART',
  PENDING_CUMULATIVE: 'PENDING_CUMULATIVE',
  PENDING_FULL: 'PENDING_FULL',
  PRICE_VALIDATION_FULL: 'priceValidationFull',
  PRODUCT_DATA_EMAIL_HISTORY_WARNINGS: 'productDataEmailHistoryWarnings',
  PRODUCT_DATA_MESSAGES: 'PRODUCT_DATA_MESSAGES',
  PRODUCT_DATA_OVERVIEW: 'productDataOverview',
  PRODUCT_DATA_SOLUTIONS: 'PRODUCT_DATA_SOLUTIONS',
  PRODUCT_DATA_WARNINGS: 'productDataWarnings',
  PRODUCT_STATUS_ALL_WARNINGS: 'productStatusAllWarnings',
  PRODUCT_STATUS_CUMULATIVE: 'productStatusCumulative',
  PRODUCT_STATUS_CUMULATIVE_CHART: 'productStatusCumulativeChart',
  PRODUCT_STATUS_EMAIL_WARNINGS: 'productStatusEmailWarnings',
  PRODUCT_STATUS_FULL: 'PRODUCT_STATUS_REPORTS',
  PRODUCT_STATUS_HISTORY_EMAIL_WARNINGS: 'productStatusHistoryEmailWarnings',
  PRODUCT_STATUS_LINE_GRAPH: 'productStatusLineGraph',
  PRODUCT_STATUS_MEDIA_WARNINGS: 'productStatusMediaWarnings',
  PRODUCT_STATUS_OPEN_TASK_HISTORY_LINE_GRAPH: 'productStatusOpenTaskHistoryLineGraph',
  PRODUCT_STATUS_OPEN_TASK_HISTORY_PIE_GRAPH: 'productStatusOpenTaskHistoryPieGraph',
  PRODUCT_STATUS_OVERVIEW: 'productStatusOverview',
  PRODUCT_STATUS_PIE_GRAPH: 'productStatusPieGraph',
  PRODUCT_STATUS_WARNINGS_HELP: 'PRODUCT_STATUS_WARNINGS_HELP',
  RETURNED_CHART: 'returnedChart',
  RETURNED_CUMULATIVE: 'returnedCumulative',
  RETURNED_CUMULATIVE_CHART: 'returnedCumulativeChart',
  RETURNED_FULL: 'returnedFull',
  RETURNED_OPEN: 'returnedOpen',
  SALES_DASHBOARD_CANCELLED_ARTICLES: 'CancelledArticles',
  SALES_DASHBOARD_DELIVERIES: 'Deliveries',
  SALES_DASHBOARD_GROSS_ARTICLES: 'GrossArticles',
  SALES_DASHBOARD_GROSS_BASKETS: 'GrossBaskets',
  SALES_DASHBOARD_GROSS_REVENUE: 'GrossRevenue',
  SALES_DASHBOARD_GROSS_SALES_PER_PRODUCT: 'GrossSalesPerProduct',
  SALES_DASHBOARD_GROSS_SALES_PER_PRODUCT_BY_CREATION_DATE: 'GrossSalesPerProductByCreationDate',
  SALES_DASHBOARD_NET_REVENUE: 'NetRevenue',
  SALES_DASHBOARD_NET_SALES_PER_PRODUCT_EURO: 'NetSalesPerProductEuro',
  SALES_DASHBOARD_PERCENTAGE_CANCELLED_ARTICLES: 'PercentageCancelledArticles',
  SALES_DASHBOARD_PERCENTAGE_RETURNED_AFTER_CANCELLATION_ARTICLES: 'PercentageReturnedAfterCancellationArticles',
  SALES_DASHBOARD_PERCENTAGE_RETURNED_ARTICLES: 'PercentageReturnedArticles',
  SALES_DASHBOARD_PERCENTAGE_RETURNED_VALUE_ARTICLES: 'PercentageReturnedValueArticles',
  SALES_DASHBOARD_PERCENTAGE_RETURNS_EACH_PRODUCT: 'PercentageReturnsEachProduct',
  SALES_DASHBOARD_QUANTITY_RETURNED_ARTICLES: 'QuantityReturnedArticles',
  SALES_DASHBOARD_RETURNS: 'Returns',
  SALES_DASHBOARD_RETURNS_EACH_PRODUCT_PIECE: 'ReturnsEachProductPiece',
  SALES_DASHBOARD_SUMMARIES_AFTER_RETURNS: 'SummariesAfterReturns',
  SALES_DASHBOARD_SUMMARIES_AFTER_RETURNS_GRAPH: 'SummariesAfterReturnsGraph',
  SALES_DASHBOARD_SUMMARIES_GROSS: 'SummariesGross',
  SALES_DASHBOARD_SUMMARIES_GROSS_GRAPH: 'SummariesGrossGraph',
  SALES_DASHBOARD_SUMMARIES_HISTORY: 'SummariesHistory',
  SLA_BY_DELIVERY: 'slaDelivery',
  SLA_CUMULATIVE: 'slaCumulative',
  SLA_CUMULATIVE_BY_DELIVERY: 'slaCumulativeByDeliveryOrders',
  SLA_CUMULATIVE_BY_SHIPMENT: 'slaCumulativeByShipmentOrders',
  SLA_GRAPH: 'slaGraph',
  SLA_HANDOVER_HISTORY: 'slaHandoverHistory',
  SLA_PARCEL_HISTORY: 'slaParcelHistory',
  SUMMARY_WAREHOUSES: 'summaryWarehouses',
  SUMMARY_WAREHOUSE_CUMULATIVE: 'summaryWarehouseCumulative',
  SUMMARY_WAREHOUSE_CUMULATIVE_CHART: 'summaryWarehouseCumulativeChart',
  USER_PERMISSIONS: 'userPermissions',
  IMPORT_ADMIN_DEBUG_TEMPLATE_ANALYSIS_OVERVIEW: 'templateAnalysisOverview',
  IMPORT_ADMIN_DEBUG_TEMPLATE_ANALYSIS_PARTNER_DETAILS: 'templateAnalysisPartnerDetails'
};
exports.DATA_TYPE = DATA_TYPE;
var DATA_URLS = {
  ARCHIVED_ARTICLES: 'archived-articles',
  ARTICLES_CREATION_CHART_URL: 'articles-creation/chart',
  ARTICLES_CREATION_URL: 'articles-creation',
  BLOCKED_FULL_URL: 'orders/blocked-articles',
  BUSINESS_SUMMARY_EMAIL_HISTORY_WARNINGS_URL: 'business-summary/messages/email-history-warnings',
  BUSINESS_SUMMARY_MESSAGES_URL: 'business-summary/messages',
  BUSINESS_SUMMARY_SOLUTIONS_URL: 'business-summary/solutions',
  BUSINESS_SUMMARY_WARNINGS_URL: 'business-summary/messages/warnings',
  CANCELLED_ALERTS_URL: 'orders/cancel/alerts',
  CANCELLED_CUMULATIVE_CHART_URL: 'orders/cancel/charts/cumulative',
  CANCELLED_CUMULATIVE_URL: 'orders/cancel/cumulative',
  CANCELLED_FULL_URL: 'orders/cancel',
  CANCELLED_GRAPH_URL: 'orders/cancel/graph',
  COCKPIT_CANCELLATIONS_GRAPH_URL: 'cockpit/cancellations-graph',
  COCKPIT_CHANNEL_GROSS_URL: 'cockpit/channel-gross-revenue',
  COCKPIT_CHANNEL_YEAR_GROSS_URL: 'cockpit/channel-gross-year-revenue',
  COCKPIT_DELAYED_URL: 'cockpit/delayed',
  COCKPIT_GROSS_REVENUE_10WEEKS_URL: 'cockpit/gross-revenue',
  COCKPIT_GROSS_REVENUE_URL: 'cockpit/gross-revenue',
  COCKPIT_OPENED_URL: 'cockpit/opened',
  COCKPIT_SLA12WEEKS_URL: 'cockpit/sla-ratios?weeks=12',
  COCKPIT_SLA4WEEKS_URL: 'cockpit/sla-ratios?weeks=4',
  COCKPIT_SLA7DAYS_URL: 'cockpit/sla-ratios',
  COCKPIT_SLA_FAIL_GRAPH_URL: 'cockpit/sla-fail-graph',
  COCKPIT_TRENDS_URL: 'cockpit/item-trends',
  CUSTOM_TABLE_VIEW_LOAD_URL: 'custom-table-view/getAll',
  CUSTOM_TABLE_VIEW_SAVE_URL: 'custom-table-view/save',
  DELAYED_CHART_URL: 'orders/delayed/graph',
  DELAYED_CUMULATIVE_URL: 'orders/delayed/cumulative',
  DELAYED_FULL_URL: 'orders/delayed',
  EMAIL_SETTINGS_CHANGE_ALL_STATUS_URL: 'settings/emailSettings/setAll',
  EMAIL_SETTINGS_CHANGE_PROPERTY_URL: 'settings/emailSettings/save',
  EMAIL_SETTINGS_URL: 'settings/emailSettings',
  FIRST_FULL_URL: 'orders/first',
  HISTORY_INVOICE_FULL_URL: 'invoices/history',
  HISTORY_INVOICE_SINGLE_REPORT_URL: 'invoices/history/singlePdfDoc',
  HISTORY_INVOICE_SINGLE_EXCEL_REPORT_URL: 'invoices/history/singleExcelDoc',
  LACKING_TC_CUMULATIVE_URL: 'orders/lacking-tc/cumulative',
  LACKING_TC_FULL_URL: 'orders/lacking-tc',
  MEDIA_EMAIL_HISTORY_WARNINGS_URL: 'mediaWarnings/media-email-history-warnings',
  MEDIA_MESSAGES_URL: 'media/messages',
  MEDIA_SELF_ARTICLE_IMAGES_URL: 'media-self/media-images',
  MEDIA_SELF_DATA_URL: 'media-self/all-data',
  MEDIA_SELF_MEDIA_REQUIREMENTS_URL: 'media-self/media-requirements',
  MEDIA_SELF_PRODUCT_DETAILS_URL: 'media-self/product-details',
  MEDIA_SELF_SERVICE_PRODUCT_CHANNELS_URL: 'media-self/product-channels',
  MEDIA_SOLUTIONS_URL: 'media/solutions',
  MEDIA_WARNINGS_URL: 'mediaWarnings/media-warnings',
  MISSING_IDENT_CODE_FULL_URL: 'orders/missing-identcode',
  SENT_ORDERS_DELIVERY_URL: 'orders/sent/deliveryProgress',
  SENT_ORDERS_UNCONFIRMED_URL: 'orders/sent/unconfirmed-with-carrier',
  OPENED_CONFIRMATION_URL: 'orders/opened/confirmations',
  OPENED_CUMULATIVE_URL: 'orders/opened/cumulative',
  OPENED_FULL_URL: 'orders/opened',
  OPENED_EXCLUSIVE_FULL_URL: 'orders/opened/exclusive',
  OPENED_GRAPH_URL: 'orders/opened/graph',
  OPENED_INVOICE_FULL_URL: 'invoices/open',
  OPENED_INVOICE_SINGLE_REPORT_URL: 'invoices/open/singlePdfDoc',
  ORDER_STATISTICS_URL: 'order-statistics',
  OVERSALES_FULL_URL: 'orders/oversales',
  PAYMENT_SUMMARY_URL: 'payments/summary',
  PAYMENT_TRANSACTIONS_URL: 'payments/transactions',
  PENDING_CUMULATIVE_URL: 'orders/pending/cumulative',
  PENDING_FULL_URL: 'orders/pending',
  PENDING_GRAPH_URL: 'orders/pending/graph',
  PRICE_VALIDATION_FULL_URL: 'orders/price-validation',
  PRODUCT_DATA_EMAIL_HISTORY_WARNINGS_URL: 'product-data/messages/email-history-warnings',
  PRODUCT_DATA_CUMULATIVE_CHART_URL: 'product-data/cumulative/chart',
  PRODUCT_DATA_CUMULATIVE_URL: 'product-data/cumulative',
  PRODUCT_DATA_FULL_URL: 'product-data/reports',
  PRODUCT_DATA_GRAPH_URL: 'product-data/charts',
  PRODUCT_DATA_MESSAGES_URL: 'product-data/messages',
  PRODUCT_DATA_OPEN_TASK_HISTORY_GRAPH_URL: 'product-data/open-task-history-charts',
  PRODUCT_DATA_OVERVIEW_URL: 'product-data/overview/chart',
  PRODUCT_DATA_SOLUTIONS_URL: 'product-data/solutions',
  PRODUCT_DATA_WARNINGS_URL: 'product-data/messages/warnings',
  PRODUCT_STATUS_ALL_HISTORY_WARNINGS_URL: 'productStatus/warnings/email-history-warnings',
  PRODUCT_STATUS_ALL_WARNINGS_URL: 'productStatus/warnings/all-warnings',
  PRODUCT_STATUS_CUMULATIVE_CHART_URL: 'productStatus/cumulativeChart',
  PRODUCT_STATUS_CUMULATIVE_URL: 'productStatus/cumulative',
  PRODUCT_STATUS_EMAIL_WARNINGS_URL: 'productStatus/warnings/email-warnings',
  PRODUCT_STATUS_FULL_URL: 'productStatus/reports',
  PRODUCT_STATUS_GRAPH_URL: 'productStatus/charts',
  PRODUCT_STATUS_MEDIA_WARNINGS_URL: 'productStatus/warnings/media-warnings',
  PRODUCT_STATUS_OPEN_TASK_HISTORY_GRAPH_URL: 'productStatus/open-task-history-charts',
  PRODUCT_STATUS_OVERVIEW_URL: 'productStatus/overview-chart',
  RETURNED_CHART_URL: 'orders/return/graph',
  RETURNED_CUMULATIVE_CHART_URL: 'orders/return/charts/cumulative',
  RETURNED_CUMULATIVE_URL: 'orders/return/cumulative',
  RETURNED_FULL_URL: 'orders/return',
  RETURNED_OPEN_URL: 'orders/open-return',
  RETURNED_OPEN_EXCLUSIVE_URL: 'orders/open-return/exclusive',
  SALES_DASHBOARD_ORDERS_URL: 'orders/sales',
  SLA_BY_DELIVERY_URL: 'orders/sla/sla-by-delivery',
  SLA_CUMULATIVE_BY_DELIVERY_URL: 'orders/sla/cumulative-by-delivery',
  SLA_CUMULATIVE_URL: 'orders/sla/cumulative',
  SLA_FULL_URL: 'orders/sla',
  SLA_GRAPH_URL: 'orders/sla/graphs',
  SLA_HANDOVER_HISTORY_FULL_URL: 'orders/sla/handover-history',
  SLA_PARCEL_HISTORY_FULL_URL: 'orders/sla/parcel-history',
  SUMMARY_WAREHOUSE_CHART_URL: 'summary-warehouse/chart',
  SUMMARY_WAREHOUSE_CUMULATIVE_CHART_URL: 'summary-warehouse/cumulative/chart',
  SUMMARY_WAREHOUSE_CUMULATIVE_URL: 'summary-warehouse/cumulative',
  USER_PERMISSIONS_CHANGE_STATUS_BATCH_URL: 'user-permissions/change-status-batch',
  USER_PERMISSIONS_CHANGE_STATUS_URL: 'user-permissions/change-status',
  USER_PERMISSIONS_URL: 'user-permissions',
  IMPORT_ADMIN_DEBUG_VALUE_GROUP_ANALYSIS_RESYNC_URL: 'import/admin-debug/value-group-analysis/sync/restart',
  IMPORT_ADMIN_DEBUG_VALUE_GROUP_ANALYSIS_SYNC_STATUS_URL: 'import/admin-debug/value-group-analysis/sync/status',
  IMPORT_ADMIN_DEBUG_TEMPLATE_ANALYSIS_RESYNC_URL: 'import/admin-debug/template-analysis/sync/restart',
  IMPORT_ADMIN_DEBUG_TEMPLATE_ANALYSIS_SYNC_STATUS_URL: 'import/admin-debug/template-analysis/sync/status',
  IMPORT_ADMIN_DEBUG_TEMPLATE_ANALYSIS_OVERVIEW_URL: 'import/admin-debug/template-analysis/overview',
  IMPORT_ADMIN_DEBUG_TEMPLATE_ANALYSIS_PARTNER_DETAILS_URL: 'import/admin-debug/template-analysis/partner-details',
  IMPORT_ADMIN_DEBUG_CHECK_XML_URL: 'import/admin-debug/check-xml'
};
exports.DATA_URLS = DATA_URLS;