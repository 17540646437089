"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RETRY = exports.RESET = exports.SET = void 0;
var SET = 'error/SET';
exports.SET = SET;
var RESET = 'error/RESET';
exports.RESET = RESET;
var RETRY = 'error/RETRY';
exports.RETRY = RETRY;