"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.close = exports.setButtonText = exports.setText = exports.setTitle = exports.show = void 0;

var _reduxActions = require("redux-actions");

var types = _interopRequireWildcard(require("./types"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var show = (0, _reduxActions.createAction)(types.SHOW, function (title, text, type, buttonText) {
  return {
    title: title,
    text: text,
    type: type,
    buttonText: buttonText
  };
});
exports.show = show;
var setTitle = (0, _reduxActions.createAction)(types.SET_TITLE, function (title, type) {
  return {
    title: title,
    type: type
  };
});
exports.setTitle = setTitle;
var setText = (0, _reduxActions.createAction)(types.SET_TEXT, function (text, type) {
  return {
    text: text,
    type: type
  };
});
exports.setText = setText;
var setButtonText = (0, _reduxActions.createAction)(types.SET_BUTTON_TEXT, function (buttonText) {
  return {
    buttonText: buttonText
  };
});
exports.setButtonText = setButtonText;
var close = (0, _reduxActions.createAction)(types.CLOSE);
exports.close = close;