"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginPage = void 0;

var _react = _interopRequireDefault(require("react"));

var _Simple = _interopRequireDefault(require("../../components/Simple/Simple"));

var _Version = require("../../components/Version");

var _LoginForm = require("./LoginForm.connect");

var _config = require("../../config");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var LoginPage = function LoginPage() {
  var logo = _config.Config.get("settings", "logo");

  return _react["default"].createElement(_Simple["default"], null, _react["default"].createElement("div", {
    className: "login-background-wrapper align-items-center"
  }, _react["default"].createElement("div", {
    className: "container"
  }, _react["default"].createElement("div", {
    className: "row"
  }, _react["default"].createElement("div", {
    className: "col-lg-4 col-sm-12",
    style: {
      margin: '0 auto'
    }
  }, _react["default"].createElement("div", {
    className: "card-group mb-0"
  }, _react["default"].createElement("div", {
    className: "card p-4 login-background-card"
  }, _react["default"].createElement("div", {
    className: "card-block"
  }, logo && _react["default"].createElement("div", {
    className: "logo",
    style: {
      backgroundImage: "url(" + logo + ")"
    }
  }), _react["default"].createElement(_LoginForm.LoginForm, null), _react["default"].createElement("div", {
    style: {
      textAlign: "center",
      fontSize: "11px",
      paddingTop: "20px"
    }
  }, _react["default"].createElement(_Version.Version, {
    withDetails: false
  }))))))))));
};

exports.LoginPage = LoginPage;