import { Config } from "@onquality/dashboard-ui/lib/config";
import { reports } from "views/SalesDashboard/tableData";
import { PERMISSIONS } from "@onquality/dashboard-ui/lib/constants/permissions";

const menu = {
    menuItems: [
        {
            path: '/cockpit',
            title: 'sidebar/cockpit',
            icon: 'icon-grid',
            access: PERMISSIONS.VIEW_DASHBOARD
        },
        {
            title: 'sidebar/salesDashboard',
            icon: 'icon-basket',
            path: '/sales-dashboard/GrossRevenue',
            access: PERMISSIONS.VIEW_KPIS,
            menuItems: [
                {
                    title: 'sales/menu/title/TimeRelated',
                    isItemGroup: true,
                    menuItems: [
                        {
                            path: '/sales-dashboard/GrossBaskets',
                            title: reports.GrossBaskets.menu,
                            tooltip: reports.GrossBaskets.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/GrossArticles',
                            title: reports.GrossArticles.menu,
                            tooltip: reports.GrossArticles.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/GrossRevenue',
                            title: reports.GrossRevenue.menu,
                            tooltip: reports.GrossRevenue.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/PercentageCancelledArticles',
                            title: reports.PercentageCancelledArticles.menu,
                            tooltip: reports.PercentageCancelledArticles.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/CancelledArticles',
                            title: reports.CancelledArticles.menu,
                            tooltip: reports.CancelledArticles.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/Deliveries',
                            title: reports.Deliveries.menu,
                            tooltip: reports.Deliveries.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/PercentageReturnedArticles',
                            title: reports.PercentageReturnedArticles.menu,
                            tooltip: reports.PercentageReturnedArticles.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/Returns',
                            title: reports.Returns.menu,
                            tooltip: reports.Returns.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/QuantityReturnedArticles',
                            title: reports.QuantityReturnedArticles.menu,
                            tooltip: reports.QuantityReturnedArticles.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/NetRevenue',
                            title: reports.NetRevenue.menu,
                            tooltip: reports.NetRevenue.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/SummariesHistory',
                            title: reports.SummariesHistory.menu,
                            tooltip: reports.SummariesHistory.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/SummariesOverview',
                            title: reports.SummariesOverview.menu,
                            tooltip: reports.SummariesOverview.info,
                            icon: 'icon-info'
                        }
                    ]
                }, {
                    title: 'sales/menu/title/ItemRelated',
                    isItemGroup: true,
                    menuItems: [
                        {
                            path: '/sales-dashboard/GrossSalesPerProductByCreationDate',
                            title: reports.GrossSalesPerProductByCreationDate.menu,
                            tooltip: reports.GrossSalesPerProductByCreationDate.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/GrossSalesPerProduct',
                            title: reports.GrossSalesPerProduct.menu,
                            tooltip: reports.GrossSalesPerProduct.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/NetSalesPerProductEuro',
                            title: reports.NetSalesPerProductEuro.menu,
                            tooltip: reports.NetSalesPerProductEuro.info,
                            icon: 'icon-info'
                        },
                        {
                            path: '/sales-dashboard/ReturnsEachProductPiece',
                            title: reports.ReturnsEachProductPiece.menu,
                            tooltip: reports.ReturnsEachProductPiece.info,
                            icon: 'icon-info',
                        },
                        {
                            path: '/sales-dashboard/PercentageReturnsEachProduct',
                            title: reports.PercentageReturnsEachProduct.menu,
                            tooltip: reports.PercentageReturnsEachProduct.info,
                            icon: 'icon-info'
                        }
                    ]
                }
            ]
        },
        {
            title: 'sidebar/orders',
            path: '/sent-orders',
            icon: 'icon-chart',
            access: PERMISSIONS.VIEW_ORDERS,
            menuItems: [
                {
                    path: '/sent-orders',
                    title: 'sidebar/sentOrders',
                    tooltip: 'sidebar/sentOrders',
                    icon: 'icon-graph',
                },
                {
                    path: '/delayed',
                    title: 'sidebar/delayedOrders',
                    tooltip: 'sidebar/delayedOrders',
                    icon: 'icon-clock',
                },
                {
                    path: '/opened',
                    title: 'sidebar/openedOrders',
                    tooltip: 'sidebar/openedOrders',
                    icon: 'icon-control-play',
                },
                {
                    path: '/pending',
                    title: 'sidebar/pendingOrders',
                    tooltip: 'sidebar/pendingOrders',
                    icon: 'icon-control-pause',
                },
                {
                    path: '/order-statistics',
                    title: 'sidebar/orderStatistics',
                    tooltip: 'sidebar/orderStatistics',
                    icon: 'icon-chart',
                },
            ]
        },
        {
            path: '/lacking_tracking_codes',
            title: 'sidebar/lackingTrackingCodes',
            icon: 'icon-social-dropbox',
            access: PERMISSIONS.VIEW_LACKING_TRACKING_CODES
        },
        {
            path: '/first',
            title: 'sidebar/firstOrders',
            icon: 'icon-fire',
            access: PERMISSIONS.VIEW_FIRST_ORDERS
        },
        {
            path: '/cancellations',
            title: 'sidebar/cancellations',
            icon: 'icon-action-undo',
            access: PERMISSIONS.VIEW_CANCELLATIONS
        },
        {
            title: 'sidebar/sla',
            icon: 'icon-speedometer',
            path: '/sla',
            access: PERMISSIONS.VIEW_SLA
        },
        {
            path: '/returns',
            title: 'sidebar/returns',
            icon: 'icon-reload',
            access: PERMISSIONS.VIEW_RETURNS
        },
        {
            title: 'sidebar/blockedArticles',
            path: '/blocked-articles',
            icon: 'icon-shield',
            access: PERMISSIONS.VIEW_BLOCKED_ARTICLES
        },
        {
            title: 'sidebar/overSales',
            path: '/oversales',
            icon: 'icon-basket-loaded',
            access: PERMISSIONS.VIEW_OVER_SALES
        },
        {
            title: 'sidebar/priceValidation',
            path: '/price-validation',
            icon: 'icon-tag',
            // showOnlyForAdmin: true,
            access: PERMISSIONS.VIEW_PRICE_VALIDATION
        },
        {
            title: 'sidebar/productStatus',
            path: '/product-status',
            icon: 'icon-pie-chart',
            // hideInLogisticsMode: true,
            access: PERMISSIONS.VIEW_PRODUCT_STATUS
        },
        // TODO uncomment in DEVPD-1092
        // {
        //     title: 'sidebar/productStatus',
        //     path: '/product-status/overview',
        //     icon: 'icon-pie-chart',
        //     hideInLogisticsMode: true,
        //     menuItems: [
        //         {
        //             path: '/product-status/overview',
        //             title: 'sidebar/productStatus/overview',
        //             tooltip: 'sidebar/productStatus/overview',
        //             icon: 'anticon anticon-bars',
        //         },
        //         {
        //             path: '/product-status/warnings',
        //             title: 'sidebar/productStatus/warnings',
        //             tooltip: 'sidebar/productStatus/warnings',
        //             icon: 'anticon anticon-warning',
        //         },
        //         {
        //             path: '/product-status/export-info',
        //             title: 'sidebar/productStatus/exportInfo',
        //             tooltip: 'sidebar/productStatus/exportInfo',
        //             icon: 'anticon anticon-export',
        //         },
        //         {
        //             path: '/product-status/channel-feedback',
        //             title: 'sidebar/productStatus/channelFeedback',
        //             tooltip: 'sidebar/productStatus/channelFeedback',
        //             icon: 'anticon anticon-notification',
        //         },
        //     ]
        // },
        {
            title: 'sidebar/invoices',
            path: '/invoices',
            // needAccess: true,
            // hideInLogisticsMode: true,
            icon: 'icon-docs',
            access: PERMISSIONS.VIEW_INVOICES,
        },
        {
            title: 'sidebar/summaryWarehouse',
            path: '/summary-warehouse',
            // showOnlyForAdmin: true,
            icon: 'icon-home',
            access: PERMISSIONS.VIEW_WAREHOUSE_STATISTICS,
        },
        {
            title: 'sidebar/payments',
            path: '/payments',
            // hideInLogisticsMode: true,
            icon: 'icon-wallet',
            // needAccess: true,
            access: PERMISSIONS.VIEW_PAYMENTS
        },
        {
            title: 'sidebar/settings',
            path: '/settings',
            icon: 'icon-settings',
            access: PERMISSIONS.VIEW_EMAIL_SETTINGS
        },
        {
            title: 'sidebar/dataImport',
            path: '/data-import',
            access: PERMISSIONS.VIEW_IMPORT_SERVICES,
            icon: 'icon-cloud-upload'
        },
        {
            title: 'sidebar/userPermissions',
            path: '/user-permissions',
            icon: 'icon-user',
            access: PERMISSIONS.VIEW_USER_PERMISSIONS
        }
    ]
};

Config.applyConfig('menu', menu);
