"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.allSagas = void 0;

var _sagas = require("./app/sagas");

var _sagas2 = require("./apiFlow/sagas");

var _sagas3 = require("./data/sagas");

var _sagas4 = require("./filters/sagas");

var _saga = require("./auth/saga");

var _saga2 = require("./locale/saga");

var _saga3 = require("./partnerEmulation/saga");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var allSagas = [].concat(_toConsumableArray(_sagas.appSagas), _toConsumableArray(_sagas2.apiFlowSagas), _toConsumableArray(_sagas3.dataSagas), _toConsumableArray(_sagas4.filtersSagas), [_saga.authWatcher, _saga2.changeLocaleFlow, _saga3.partnerEmulationInitWatcher]);
exports.allSagas = allSagas;