"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDataViewsByFilterTypes = exports.getDataViewsByFilterType = exports.getDataViewsByDataType = exports.getRelatedFilterTypes = exports.getProperty = exports.getAllDataViews = exports.getDataView = exports.getProps = exports.getNextId = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _component = require("../../../constants/component");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var getNextId = function getNextId(state) {
  return state.data.views.reduce(function (maxId, view) {
    return Math.max(view.id, maxId);
  }, 0) + 1;
};

exports.getNextId = getNextId;

var getProps = function getProps(state, id) {
  return _lodash["default"].findLast(state.data.views, function (view) {
    return view.id === id;
  });
};

exports.getProps = getProps;

var getDataView = function getDataView(state, id) {
  return getProps(state, id);
};

exports.getDataView = getDataView;

var getAllDataViews = function getAllDataViews(state) {
  return state.data.views;
};

exports.getAllDataViews = getAllDataViews;

var getProperty = function getProperty(state, id, propertyName) {
  return _lodash["default"].get(getProps(state, id), propertyName);
};

exports.getProperty = getProperty;

var getRelatedFilterTypes = function getRelatedFilterTypes(state, dataType) {
  return _lodash["default"].uniq(_lodash["default"].map(getDataViewsByDataType(state, dataType), function (view) {
    return view.filterType;
  }));
}; // TODO: refactor. use dataType as object


exports.getRelatedFilterTypes = getRelatedFilterTypes;

var getDataViewsByDataType = function getDataViewsByDataType(state, dataType) {
  var type = _typeof(dataType) === 'object' ? dataType.type : dataType;
  return state.data.views.filter(function (view) {
    return view[_component.PROPS.DATA_TYPE].type === type;
  });
};

exports.getDataViewsByDataType = getDataViewsByDataType;

var getDataViewsByFilterType = function getDataViewsByFilterType(state, filterType) {
  return state.data.views.filter(function (dataView) {
    return dataView[_component.PROPS.FILTER_TYPE] === filterType;
  });
};

exports.getDataViewsByFilterType = getDataViewsByFilterType;

var getDataViewsByFilterTypes = function getDataViewsByFilterTypes(state, filterTypes) {
  return state.data.views.filter(function (dataView) {
    return filterTypes.includes(dataView[_component.PROPS.FILTER_TYPE]) || _lodash["default"].isArray(dataView[_component.PROPS.RELATED_FILTERS]) && dataView[_component.PROPS.RELATED_FILTERS].filter(function (relatedFilterType) {
      return filterTypes.includes(relatedFilterType);
    }).length > 0;
  });
};

exports.getDataViewsByFilterTypes = getDataViewsByFilterTypes;