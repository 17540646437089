"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ABORT = exports.FAILURE = exports.SUCCESS = exports.SET_STATUS = exports.SET_READY = exports.SAGA_STATUS = exports.START = exports.SAGA = void 0;
var SAGA = 'apiFlow/download/async/SAGA';
exports.SAGA = SAGA;
var START = 'apiFlow/download/async/START';
exports.START = START;
var SAGA_STATUS = 'apiFlow/download/async/SAGA_STATUS';
exports.SAGA_STATUS = SAGA_STATUS;
var SET_READY = 'apiFlow/download/async/SET_READY';
exports.SET_READY = SET_READY;
var SET_STATUS = 'apiFlow/download/async/SET_STATUS';
exports.SET_STATUS = SET_STATUS;
var SUCCESS = 'apiFlow/download/async/SUCCESS';
exports.SUCCESS = SUCCESS;
var FAILURE = 'apiFlow/download/async/FAILURE';
exports.FAILURE = FAILURE;
var ABORT = 'apiFlow/download/async/ABORT';
exports.ABORT = ABORT;