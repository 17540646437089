"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SwitchStatus = void 0;

var _reactRedux = require("react-redux");

var _SwitchStatus = require("./SwitchStatus");

var _actions = require("../../../store/apiFlow/userPermissions/changeStatus/actions");

var _selectors = require("../../../store/apiFlow/userPermissions/changeStatus/selectors");

var mapStateToProps = function mapStateToProps(state, props) {
  return {
    isFetching: (0, _selectors.getIsFetching)(state, props.index)
  };
};

var mapDispatchToProps = {
  changeStatus: _actions.saga
};
var SwitchStatus = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_SwitchStatus.SwitchStatus);
exports.SwitchStatus = SwitchStatus;