"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsDisabled = exports.getIsFetching = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getIsFetching = function getIsFetching(state, props) {
  return _lodash["default"].get(state.apiFlow.filterOptions, [props.filterType, props.fieldName, 'isFetching'], false);
};

exports.getIsFetching = getIsFetching;

var getIsDisabled = function getIsDisabled(state, type, fieldName) {
  return _lodash["default"].get(state.apiFlow.filterOptions, [type, fieldName, 'disableValue'], 0);
};

exports.getIsDisabled = getIsDisabled;