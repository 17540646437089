"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalWindow = void 0;

var _reactRedux = require("react-redux");

var _TranslateAware = require("../TranslateAware");

var _ModalWindow = require("./ModalWindow");

var _selectors = require("../../store/modal/selectors");

var _actions = require("../../store/modal/actions");

var mapStateToProps = function mapStateToProps(state) {
  return {
    visible: (0, _selectors.getVisible)(state),
    title: (0, _selectors.getTitle)(state),
    text: (0, _selectors.getText)(state),
    type: (0, _selectors.getType)(state),
    buttonText: (0, _selectors.getButtonText)(state)
  };
};

var mapDispatchToProps = {
  closeModal: _actions.close
};
var ModalWindow = (0, _TranslateAware.TranslateAware)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_ModalWindow.ModalWindow));
exports.ModalWindow = ModalWindow;