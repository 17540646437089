"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTableData = getTableData;
exports.parseParams = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _actionTypes = require("../constants/actionTypes");

var _Utils = _interopRequireDefault(require("../utils/Utils"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var createAction = function createAction(tableType) {
  return {
    LOAD_STARTED: function LOAD_STARTED() {
      return {
        type: _actionTypes.LOAD_STARTED,
        tableType: tableType
      };
    },
    LOAD_STARTED_RESET: function LOAD_STARTED_RESET() {
      return {
        type: _actionTypes.LOAD_STARTED_RESET,
        tableType: tableType
      };
    },
    LOAD_OK: function LOAD_OK(response) {
      return {
        type: _actionTypes.LOAD_OK,
        tableType: tableType,
        payload: response.data,
        status: response.status
      };
    },
    LOAD_FULL_LIST: function LOAD_FULL_LIST(payload) {
      return {
        type: _actionTypes.LOAD_FULL_LIST,
        tableType: tableType,
        payload: payload
      };
    },
    LOAD_CUMULATIVE: function LOAD_CUMULATIVE(response) {
      return {
        type: _actionTypes.LOAD_CUMULATIVE,
        tableType: tableType,
        payload: response.data,
        status: response.status
      };
    },
    LOAD_IS_PRESENT: function LOAD_IS_PRESENT(payload) {
      return {
        type: _actionTypes.LOAD_IS_PRESENT,
        tableType: tableType,
        payload: payload
      };
    },
    LOAD_ERROR: function LOAD_ERROR(response) {
      return {
        type: _actionTypes.LOAD_ERROR,
        tableType: tableType,
        status: response.status
      };
    }
  };
};

function getTableData(params) {
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var tableType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'forgotToSetTableType';
  var reqInitiatorObj = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  return function (dispatch) {
    var tableActions = createAction(tableType);

    if (params.resetData) {
      dispatch(tableActions.LOAD_STARTED_RESET());
    } else {
      dispatch(tableActions.LOAD_STARTED());
    }

    var convertedParams = params ? params.params : params;
    var requestParams = {
      params: convertedParams,
      paramsSerializer: function paramsSerializer(params) {
        return parseParams(params);
      }
    };

    if (reqInitiatorObj !== null) {
      _Utils["default"].cancelDoubleRequest(requestParams, reqInitiatorObj);
    }

    return _axios["default"].post("/".concat(url), _objectSpread({}, requestParams.params)).then(function (response) {
      // TODO: refactor this conditions
      if (url.indexOf('cumulative') !== -1) {
        dispatch(tableActions.LOAD_CUMULATIVE(response));
      } else if (url.indexOf('isPresent') !== -1) {
        dispatch(tableActions.LOAD_IS_PRESENT(response.data));
      } else if (_lodash["default"].isEmpty(params) || !params.params.pageNum) {
        dispatch(tableActions.LOAD_FULL_LIST(response.data));
      } else {
        dispatch(tableActions.LOAD_OK(response));
      }
    })["catch"](function (response) {
      if (!_axios["default"].isCancel(response)) {
        dispatch(tableActions.LOAD_ERROR(response));
      }
    });
  };
} // TODO: move it to Utils


var parseParams = function parseParams(params) {
  var keys = Object.keys(params);
  var options = '';

  _lodash["default"].forEach(keys, function (key) {
    if (params[key] == null) return;

    var isParamTypeObject = _lodash["default"].isObject(params[key]);

    var isParamTypeArray = Array.isArray(params[key]);

    if (!isParamTypeObject) {
      options += "".concat(key, "=").concat(params[key], "&");
    }

    if (isParamTypeArray) {
      params[key].forEach(function (element) {
        options += "".concat(key, "=").concat(element, "&");
      });
    }

    if (isParamTypeObject && !isParamTypeArray) {
      var stringObj = JSON.stringify(params[key]);
      stringObj = encodeURI(stringObj);
      options += "".concat(key, "=").concat(stringObj, "&");
    }
  });

  return options ? options.slice(0, -1) : options;
};

exports.parseParams = parseParams;