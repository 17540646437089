"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DownloadButtons", {
  enumerable: true,
  get: function get() {
    return _DownloadButtons.DownloadButtons;
  }
});
exports["default"] = void 0;

var _DownloadButtons = require("./DownloadButtons.connect");

var _default = _DownloadButtons.DownloadButtons;
exports["default"] = _default;