"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsPartnerEmulationEnabled = exports.getEmulationPartnerId = void 0;

var getEmulationPartnerId = function getEmulationPartnerId(state) {
  return state.partnerEmulation.partnerId;
};

exports.getEmulationPartnerId = getEmulationPartnerId;

var getIsPartnerEmulationEnabled = function getIsPartnerEmulationEnabled(state) {
  return state.partnerEmulation.enabled;
};

exports.getIsPartnerEmulationEnabled = getIsPartnerEmulationEnabled;