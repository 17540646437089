"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EXPORT_KEY = exports.SALES_REPORT_TYPE = exports.REPORT_TYPE = exports.TABLE_TYPE = void 0;
var TABLE_TYPE = {
  OPENED: 'openedOrders',
  DELAYED: 'delayedOrders',
  SENT: 'sentOrders',
  PENDING: 'pendingOrders',
  LACKING_TC: 'lackingTCOrders',
  MISSING_IDENT_CODE: 'missingIdentCodeOrders',
  FIRST: 'FIRST',
  CANCELLED: 'cancelledOrders',
  SLA: 'slaOrders',
  SLA_CUMULATIVE_BY_DELIVERY: 'slaCumulativeOrders',
  SLA_DELIVERY: 'slaDelivery',
  PARCEL_HISTORY: 'parcelHistoryOrders',
  HANDOVER_HISTORY: 'handoverHistories',
  RETURNED: 'returnOrders',
  OPEN_RETURN: 'openReturnOrders',
  BLOCKED: 'blockedArticles',
  OVER_SALES: 'overSales',
  PRICE_VALIDATION: 'priceValidation',
  HISTORY_INVOICE: 'historyInvoices',
  OPENED_INVOICE: 'openedInvoices',
  PAYMENTS: 'payments',
  PRODUCT_STATUS: 'productStatus',
  SUMMARY_WAREHOUSES: 'summaryWarehouses',
  // TODO: Temporary added for refactor. Remove it.
  SUMMARY_WAREHOUSES_CHART: 'summaryWarehousesChart',
  PRODUCT_STATUS_CHART: 'productStatusChart',
  PRODUCT_STATUS_OPEN_TASK_HISTORY_LINE_GRAPH: 'productStatusOpenTaskHistoryLineGraph',
  PRODUCT_STATUS_OPEN_TASK_HISTORY_PIE_GRAPH: 'productStatusOpenTaskHistoryPieGraph',
  PRODUCT_STATUS_OPEN_TASK_HISTORY_COUNT: 'productStatusOpenTaskHistoryCount',
  PRODUCT_STATUS_LINE_GRAPH: 'productStatusLineGraph',
  PRODUCT_STATUS_PIE_GRAPH: 'productStatusPieGraph'
};
exports.TABLE_TYPE = TABLE_TYPE;
var REPORT_TYPE = {
  SALES_DASHBOARD_SUMMARIES_GROSS_GRAPH: 'SummariesGrossGraph',
  SALES_DASHBOARD_SUMMARIES_GROSS: 'SummariesGross',
  SALES_DASHBOARD_SUMMARIES_HISTORY: 'SummariesHistory',
  SALES_DASHBOARD_SUMMARIES_AFTER_RETURNS: 'SummariesAfterReturns',
  OPENED_CUMULATIVE: 'OPENED_CUMULATIVE',
  OPENED_FULL: 'OPENED',
  OPENED_CONFIRMATION: 'OPENED_CONFIRMATION',
  DELAYED_CUMULATIVE: 'DELAYED_CUMULATIVE',
  DELAYED_FULL: 'DELAYED',
  SENT_ORDER_UNCONFIRMED_WITH_CARRIER: 'SENT_ORDER_UNCONFIRMED_WITH_CARRIER',
  SENT_ORDER_DELIVERY_PROGRESS: 'SENT_ORDER_DELIVERY_PROGRESS',
  PENDING_CUMULATIVE: 'PENDING_CUMULATIVE',
  PENDING_FULL: 'PENDING',
  LACKING_TC_CUMULATIVE: 'LACKING_TC_CUMULATIVE',
  LACKING_TC_FULL: 'LACKING_TC',
  MISSING_IDENT_CODE_FULL: 'MISSING_IDENT_CODE',
  FIRST_FULL: 'FIRST',
  CANCELLED_CUMULATIVE: 'CANCELLED_CUMULATIVE',
  CANCELLED_FULL: 'CANCELLED',
  CANCELLED_ALERT: 'CANCELLED_ALERT',
  SLA_CUMULATIVE: 'SLA_CUMULATIVE',
  SLA_CUMULATIVE_BY_DELIVERY: 'SLA_CUMULATIVE_BY_DELIVERY',
  SLA_FULL: 'SLA',
  SLA_BY_DELIVERY: 'SLA_BY_DELIVERY',
  PARCEL_HISTORY_FULL: 'PARCEL_HISTORY',
  HANDOVER_HISTORY_FULL: 'HANDOVER_HISTORY',
  RETURNED_CUMULATIVE: 'RETURNED_CUMULATIVE',
  RETURNED_FULL: 'RETURNED',
  OPEN_RETURN_FULL: 'OPEN_RETURN',
  BLOCKED_FULL: 'BLOCKED',
  OVERSALES_FULL: 'OVER_SALES',
  PRICE_VALIDATION_FULL: 'PRICE_VALIDATION',
  HISTORY_INVOICE_FULL: 'HISTORY_INVOICE',
  OPENED_INVOICE_FULL: 'OPENED_INVOICE',
  PRODUCT_STATUS_CUMULATIVE: 'PRODUCT_STATUS_CUMULATIVE',
  PRODUCT_STATUS_FULL: 'PRODUCT_STATUS_REPORT',
  PRODUCT_STATUS_GRAPH: 'PRODUCT_STATUS_GRAPH',
  PRODUCT_STATUS_HISTORY_GRAPH: 'PRODUCT_STATUS_HISTORY_GRAPH',
  PRODUCT_STATUS_OVERVIEW_GRAPH: 'PRODUCT_STATUS_OVERVIEW_GRAPH',
  PRODUCT_STATUS_OVERVIEW: 'PRODUCT_STATUS_OVERVIEW',
  PRODUCT_STATUS_EMAIL_WARNINGS: 'PRODUCT_STATUS_EMAIL_WARNINGS',
  PRODUCT_HISTORY_EMAIL_WARNINGS: 'PRODUCT_HISTORY_EMAIL_WARNINGS',
  WARNINGS_WITH_CATEGORY: 'WARNINGS_WITH_CATEGORY',
  PRODUCT_STATUS_ALL_WARNINGS: 'PRODUCT_STATUS_ALL_WARNINGS',
  SUMMARY_WAREHOUSE_CUMULATIVE: 'SUMMARY_WAREHOUSE_CUMULATIVE',
  ALL_TRANSACTION: 'ALL_TRANSACTION',
  PAYMENT_SUMMARY: 'PAYMENT_SUMMARY',
  COCKPIT_SLA_RATIOS: 'COCKPIT_SLA_RATIOS',
  MEDIA_SOLUTIONS: 'MEDIA_SOLUTIONS',
  VALUE_GROUP_ANALYSIS: 'VALUE_GROUP_ANALYSIS',
  TEMPLATE_ANALYSIS: 'TEMPLATE_ANALYSIS',
  TEMPLATE_ANALYSIS_OVERVIEW: 'TEMPLATE_ANALYSIS_OVERVIEW',
  TEMPLATE_ANALYSIS_PARTNER_DETAILS: 'TEMPLATE_ANALYSIS_PARTNER_DETAILS',
  ARCHIVED_WARNINGS_WITH_CATEGORY: 'ARCHIVED_WARNINGS_WITH_CATEGORY'
};
exports.REPORT_TYPE = REPORT_TYPE;
var SALES_REPORT_TYPE = {
  ORDER_STATISTICS_ORDERS: 'StatisticOrders',
  ORDER_STATISTICS_SHIPMENTS: 'StatisticShipments',
  ORDER_STATISTICS_CANCELLATIONS: 'StatisticCancellations',
  ORDER_STATISTICS_RETURNS: 'StatisticReturns'
};
exports.SALES_REPORT_TYPE = SALES_REPORT_TYPE;
var EXPORT_KEY = {
  OPENED: 'opened',
  DELAYED: 'delayed',
  PENDING: 'pending',
  LACKING_TC: 'lacking',
  MISSING_IDENT_CODE: 'missingIdentCode',
  FIRST: 'first',
  CANCELLED: 'cancelled',
  SLA: 'sla',
  PARCEL_HISTORY: 'parcelHistory',
  HANDOVER_HISTORY: 'handoverHistory',
  RETURNED: 'return',
  OPEN_RETURN: 'openReturnCode',
  BLOCKED: 'blocked',
  OVER_SALES: 'oversales',
  PRICE_VALIDATION: 'priceValidation',
  PRODUCT_STATUS_CUMULATIVE: 'productStatusCumulative',
  PRODUCT_STATUS_REPORTS: 'productStatusReport',
  PAYMENTS: 'payments'
};
exports.EXPORT_KEY = EXPORT_KEY;