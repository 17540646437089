"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TAB_TYPE = void 0;
var TAB_TYPE = {
  ALL_TRANSACTION: 'ALL_TRANSACTIONS',
  BLOCKED_FULL: 'BLOCKED_ARTICLES',
  BUSINESS_SUMMARY_ALL_WARNINGS: 'BUSINESS_SUMMARY_ALL_WARNINGS',
  BUSINESS_SUMMARY_HISTORY_EMAIL_WARNINGS: 'BUSINESS_SUMMARY_HISTORY_EMAIL_WARNINGS',
  BUSINESS_SUMMARY_MESSAGES: 'BUSINESS_SUMMARY_MESSAGES',
  BUSINESS_SUMMARY_SOLUTIONS: 'BUSINESS_SUMMARY_SOLUTIONS',
  CANCELLED_ALERT: 'CANCELLED_ALERT',
  CANCELLED_CUMULATIVE: 'CANCELLED_CUMULATIVE',
  CANCELLED_FULL: 'CANCELLED_TABLE_VIEW',
  DELAYED_CUMULATIVE: 'DELAYED_CUMULATIVE',
  DELAYED_FULL: 'DELAYED',
  SENT_ORDER_UNCONFIRMED_WITH_CARRIER: 'SENT_ORDER_UNCONFIRMED_WITH_CARRIER',
  SENT_ORDER_DELIVERY_PROGRESS: 'SENT_ORDER_DELIVERY_PROGRESS',
  FIRST: 'FIRST',
  FIRST_FULL: 'FIRST_ORDER_TABLE_VIEW',
  HANDOVER_HISTORY_FULL: 'HANDOVER_HISTORY',
  HISTORY_INVOICE_FULL: 'INVOICE_HISTORY',
  LACKING_TC_CUMULATIVE: 'LACKING_CUMULATIVE',
  LACKING_TC_FULL: 'LACKING',
  WARNINGS_WITH_CATEGORY: 'WARNINGS_WITH_CATEGORY',
  ARCHIVED_WARNINGS_WITH_CATEGORY: 'ARCHIVED_WARNINGS_WITH_CATEGORY',
  MEDIA_HISTORY_EMAIL_WARNINGS: 'MEDIA_HISTORY_EMAIL_WARNINGS',
  MEDIA_MESSAGES: 'MEDIA_MESSAGES',
  MEDIA_SOLUTIONS: 'MEDIA_SOLUTIONS',
  MISSING_IDENT_CODE_FULL: 'MISSING_IDENT_CODE',
  OPENED_CONFIRMATION: 'OPENED_CONFIRMATION',
  OPENED_CUMULATIVE: 'OPEN_CUMULATIVE',
  OPENED_FULL: 'OPEN_TABLE_VIEW',
  OPENED_INVOICE_FULL: 'OPENED_INVOICE',
  OPEN_RETURN_FULL: 'OPEN_RETURN',
  ORDER_STATISTICS_CANCELLATIONS: 'ORDER_STATISTICS_CANCELLATIONS',
  ORDER_STATISTICS_ORDERS: 'ORDER_STATISTICS_ORDERS',
  ORDER_STATISTICS_RETURNS: 'ORDER_STATISTICS_RETURNS',
  ORDER_STATISTICS_SHIPMENTS: 'ORDER_STATISTICS_SHIPMENTS',
  OVERSALES_FULL: 'OVER_SALES',
  PARCEL_HISTORY_FULL: 'PARCEL_HISTORY',
  PAYMENT_SUMMARY: 'PAYMENT_SUMMARY',
  PENDING_CUMULATIVE: 'PENDING_CUMULATIVE',
  PENDING_FULL: 'PENDING_TABLE_VIEW',
  PRICE_VALIDATION_FULL: 'PRICE_VALIDATION',
  PRODUCT_DATA_ALL_WARNINGS: 'PRODUCT_DATA_ALL_WARNINGS',
  PRODUCT_DATA_HISTORY_EMAIL_WARNINGS: 'PRODUCT_DATA_HISTORY_EMAIL_WARNINGS',
  PRODUCT_STATUS_ALL_WARNINGS: 'PRODUCT_STATUS_ALL_WARNINGS',
  PRODUCT_STATUS_CUMULATIVE: 'PRODUCT_STATUS_CUMULATIVE',
  PRODUCT_STATUS_EMAIL_WARNINGS: 'PRODUCT_STATUS_EMAIL_WARNINGS',
  PRODUCT_STATUS_FULL: 'PRODUCT_STATUS_REPORTS',
  PRODUCT_STATUS_GRAPH: 'PRODUCT_STATUS_GRAPH',
  PRODUCT_STATUS_HISTORY_EMAIL_WARNINGS: 'PRODUCT_STATUS_HISTORY_EMAIL_WARNINGS',
  PRODUCT_STATUS_HISTORY_GRAPH: 'PRODUCT_STATUS_HISTORY_GRAPH',
  RETURNED_CUMULATIVE: 'RETURNED_CUMULATIVE',
  RETURNED_FULL: 'RETURNED',
  SALES_DASHBOARD_GROSS: 'gross',
  SALES_GROSS_SALES_PER_PRODUCT: 'SALES_GROSS_SALES_PER_PRODUCT',
  SALES_GROSS_SALES_PER_PRODUCT_BY_CREATION_DATE: 'SALES_GROSS_SALES_PER_PRODUCT_BY_CREATION_DATE',
  SALES_NET_SALES_PER_PRODUCT_EURO: 'SALES_NET_SALES_PER_PRODUCT_EURO',
  SALES_NET_SALES_PER_PRODUCT_PIECE: 'SALES_NET_SALES_PER_PRODUCT_PIECE',
  SALES_ORDER_HISTORY: 'SALES_ORDER_HISTORY',
  SALES_PERCENTAGE_RETURNS_EACH_PRODUCT: 'SALES_PERCENTAGE_RETURNS_EACH_PRODUCT',
  SALES_RETURNS_EACH_PRODUCT_PIECE: 'SALES_RETURNS_EACH_PRODUCT_PIECE',
  SALES_SUMMARIES_AFTER_RETURNS: 'SALES_SUMMARIES_AFTER_RETURNS',
  SALES_SUMMARIES_GROSS: 'SALES_SUMMARIES_GROSS',
  SLA_BY_DELIVERY: 'SLA_BY_DELIVERY',
  SLA_CUMULATIVE: 'SLA_CUMULATIVE',
  SLA_CUMULATIVE_BY_DELIVERY: 'SLA_CUMULATIVE_BY_DELIVERY',
  SLA_TABLE_VIEW: 'SLA_TABLE_VIEW',
  SUMMARY_WAREHOUSE_CUMULATIVE: 'SUMMARY_WAREHOUSE_CUMULATIVE',
  TEMPLATE_ANALYSIS_OVERVIEW: 'TEMPLATE_ANALYSIS_OVERVIEW',
  TEMPLATE_ANALYSIS_PARTNER_DETAILS: 'TEMPLATE_ANALYSIS_PARTNER_DETAILS'
};
exports.TAB_TYPE = TAB_TYPE;