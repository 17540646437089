"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FAILURE = exports.SUCCESS = exports.START = exports.SAGA = void 0;
var SAGA = 'apiFlow/version/SAGA';
exports.SAGA = SAGA;
var START = 'apiFlow/version/START';
exports.START = START;
var SUCCESS = 'apiFlow/version/SUCCESS';
exports.SUCCESS = SUCCESS;
var FAILURE = 'apiFlow/version/FAILURE';
exports.FAILURE = FAILURE;