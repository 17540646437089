"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EMAIL_SETTINGS_PROPERTY_TYPE = void 0;
var EMAIL_SETTINGS_PROPERTY_TYPE = {
  RECIPIENT_TYPE: "recipientType",
  ENABLED: "enabled"
};
exports.EMAIL_SETTINGS_PROPERTY_TYPE = EMAIL_SETTINGS_PROPERTY_TYPE;