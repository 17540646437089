"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DownloadButtons = void 0;

var _reactRedux = require("react-redux");

var _TranslateAware = require("../TranslateAware");

var _DownloadButtons = require("./DownloadButtons");

var _actions = require("../../store/apiFlow/download/actions");

var mapStateToProps = function mapStateToProps(state) {
  return {
    fullFilter: state.fullFilter,
    modal: state.modal
  };
};

var mapDispatchToProps = {
  startDownload: _actions.saga
};
var DownloadButtons = (0, _TranslateAware.TranslateAware)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_DownloadButtons.DownloadButtons));
exports.DownloadButtons = DownloadButtons;