"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "PageAccessForbidden", {
  enumerable: true,
  get: function get() {
    return _PageAccessForbidden.PageAccessForbidden;
  }
});

var _PageAccessForbidden = require("./PageAccessForbidden.connect");