"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = modal;

var _actionTypes = require("../constants/actionTypes");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var initialState = [];
var nextNotificationId = 0;

function modal() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.SHOW_NOTIFICATION:
      return [].concat(_toConsumableArray(state), [{
        id: nextNotificationId++,
        message: String(action.message),
        messageType: action.messageType,
        hideTimeout: action.hideTimeout
      }]);

    case _actionTypes.CLOSE_NOTIFICATION:
      return state.filter(function (notification) {
        return notification.id !== action.id;
      });

    default:
      return state;
  }
}