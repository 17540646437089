"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getData = exports.getDataValue = exports.getValue = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _selectors = require("../../apiFlow/data/selectors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// TODO: deprecated. replace to getDataValue
var getValue = function getValue(state, dataType) {
  return _lodash["default"].get(state.data.value, dataType, null);
};

exports.getValue = getValue;

var getDataValue = function getDataValue(state, dataType) {
  return _lodash["default"].get(state.data.value, dataType.type, null);
};

exports.getDataValue = getDataValue;

var getData = function getData(state, dataType) {
  return {
    loading: (0, _selectors.getIsDataLoading)(state, dataType),
    value: getDataValue(state, dataType)
  };
};

exports.getData = getData;