"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getError = exports.getIsFetching = void 0;

var getIsFetching = function getIsFetching(state) {
  return Boolean(state.apiFlow.authRefresh.isFetching);
};

exports.getIsFetching = getIsFetching;

var getError = function getError(state) {
  return state.apiFlow.authRefresh.error;
};

exports.getError = getError;