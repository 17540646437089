"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _en_GB = _interopRequireDefault(require("antd/lib/locale-provider/en_GB"));

var _fr_FR = _interopRequireDefault(require("antd/lib/locale-provider/fr_FR"));

var _de_DE = _interopRequireDefault(require("antd/lib/locale-provider/de_DE"));

var _translation = require("../constants/translation");

var _enGb = _interopRequireDefault(require("moment/locale/en-gb"));

var _de = _interopRequireDefault(require("moment/locale/de"));

var _fr = _interopRequireDefault(require("moment/locale/fr"));

var _localeMap;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var settings = {
  logo: null,
  apiBaseUrl: '/api/v1/',
  env: process.env.NODE_ENV,
  loadTimeout: 6000,
  checkTokenExpireTimeout: 60,
  preloadTimeout: 500,
  internalServerError: 500,
  requestRetry: 1,
  requestRetryDelay: 2000,
  noConnection: 900,
  timeFormat: "MM-DD-YYYY",
  storagePrefix: '@Dashboard_v8',
  expiredCredentialsStatus: 401,
  forbidden: 403,
  defaultLocale: 'en-GB',
  numberFormatLocale: 'de-DE',
  localeMap: (_localeMap = {}, _defineProperty(_localeMap, _translation.DEBUG_LOCALE, {
    code: 'debug',
    name: 'Debug',
    provider: _en_GB["default"],
    moment: _enGb["default"],
    momentCode: 'en-gb'
  }), _defineProperty(_localeMap, 'en-GB', {
    code: 'en-gb',
    name: 'English',
    provider: _en_GB["default"],
    moment: _enGb["default"],
    momentCode: 'en-gb'
  }), _defineProperty(_localeMap, 'de-DE', {
    code: 'de',
    name: 'Deutsch',
    provider: _de_DE["default"],
    moment: _de["default"],
    momentCode: 'de'
  }), _defineProperty(_localeMap, 'fr-FR', {
    code: 'fr',
    name: 'French',
    provider: _fr_FR["default"],
    moment: _fr["default"],
    momentCode: 'fr'
  }), _localeMap),
  // Table View default pagination
  defaultPagination: {
    page: 1,
    pageSize: 10
  },
  defaultCurrency: 'EUR',
  cookieMaxAge: 2147483647,
  onlineStatusApiCheckUrl: '/version',
  translationPrefix: 'dashboard'
};
var _default = settings;
exports["default"] = _default;