"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFilterFormHidden = void 0;

var isFilterFormHidden = function isFilterFormHidden(state) {
  return state.filters.settings.isFilterFormHidden;
};

exports.isFilterFormHidden = isFilterFormHidden;