"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpanI18nWithContext = exports.SpanI18nWithSuffix = exports.SpanI18n = void 0;

var _TranslateAware = _interopRequireDefault(require("./TranslateAware"));

var _react = _interopRequireDefault(require("react"));

var _translation = require("../../constants/translation");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var SpanI18n = (0, _TranslateAware["default"])(function (_ref) {
  var translate = _ref.translate,
      title = _ref.title;
  return _react["default"].createElement("span", null, translate(title));
});
exports.SpanI18n = SpanI18n;
var SpanI18nWithSuffix = (0, _TranslateAware["default"])(function (_ref2) {
  var translate = _ref2.translate,
      title = _ref2.title,
      suffixvalue = _ref2.suffixvalue;
  return _react["default"].createElement("span", null, translate(title) + " " + suffixvalue);
});
exports.SpanI18nWithSuffix = SpanI18nWithSuffix;
var SpanI18nWithContext = (0, _TranslateAware["default"])(function (_ref3) {
  var translate = _ref3.translate,
      title = _ref3.title;
  return _react["default"].createElement("span", null, translate(title));
}, _translation.USE_TRANSLATION_CONTEXT);
exports.SpanI18nWithContext = SpanI18nWithContext;