import React from "react";
import Utils from "@onquality/dashboard-ui/lib/utils/Utils";
import { SpanI18n } from "@onquality/dashboard-ui/lib/components/TranslateAware/Span";
import { TAB_TYPE } from "@onquality/dashboard-ui/lib/constants/tabConsts";
import { DATA_TYPE } from "@onquality/dashboard-ui/lib/constants/data";
import { REPORT_TYPE } from "@onquality/dashboard-ui/lib/constants/reportConsts";

export const kpiType = {
    SUMMARY: "summaryTable",
    GRAPH: "graph",
    ITEM_TABLE: "itemTable"
};

export const viewType = {
    TABLE: "table",
    GRAPH: "graph"
};

export const GRAPH_TYPE = {
    BAR: "bar",
    LINE: "line"
};

export const reports = {
    // SUMMARY
    SummariesGross: {
        menu: "sales/menu/SummariesGross",
        info: "sales/menu/SummariesGrossInfo",
        kpiType: kpiType.SUMMARY,
        widgets: [
            {
                type: REPORT_TYPE.SALES_DASHBOARD_SUMMARIES_GROSS_GRAPH,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR,
                tableType: "gross",
                showComparison: false,
                showDownload: false,
                layout: "twoChart"
            },
            {
                type: REPORT_TYPE.SALES_DASHBOARD_SUMMARIES_GROSS,
                viewType: viewType.TABLE,
                tableType: "gross",
                tabType: TAB_TYPE.SALES_SUMMARIES_GROSS,
                showComparison: false,
                showDownload: false,
                layout: "twoChart"
            }
        ]
    },
    SummariesHistory: {
        menu: "sales/menu/SummariesHistory",
        info: "sales/menu/SummariesHistoryInfo",
        kpiType: kpiType.SUMMARY,
        showReturnWarehouseCombo: true,
        widgets: [
            {
                type: REPORT_TYPE.SALES_DASHBOARD_SUMMARIES_HISTORY,
                viewType: viewType.TABLE,
                menu: "sales/menu/SummariesHistory",
                info: "sales/menu/SummariesHistoryInfo",
                tableType: "summariesHistory",
                tabType: TAB_TYPE.SALES_ORDER_HISTORY
            }
        ]
    },
    SummariesOverview: {
        menu: "sales/menu/SummariesOverview",
        info: "sales/menu/SummariesOverviewInfo",
        kpiType: kpiType.SUMMARY,
        showHeadLine: false,
        widgets: [
            {
                type: REPORT_TYPE.SALES_DASHBOARD_SUMMARIES_GROSS,
                viewType: viewType.TABLE,
                defaultSort: "shipPrice",
                menu: "sales/menu/SummariesGross",
                info: "sales/menu/SummariesGrossInfo",
                tableType: "gross",
                tabType: TAB_TYPE.SALES_SUMMARIES_GROSS,
                headLine: "sales/menu/SummariesGross"
            },
            {
                type: DATA_TYPE.SALES_DASHBOARD_SUMMARIES_AFTER_RETURNS,
                viewType: viewType.TABLE,
                defaultSort: "shipPrice",
                menu: "sales/menu/SummariesAfterReturns",
                info: "sales/menu/SummariesAfterReturnsInfo",
                tableType: "afterReturns",
                tabType: TAB_TYPE.SALES_SUMMARIES_AFTER_RETURNS,
                headLine: "sales/menu/SummariesAfterReturns"
            }
        ]
    },
    // GRAPH
    GrossRevenue: {
        menu: "sales/menu/GrossRevenue",
        info: "sales/menu/GrossRevenueInfo",
        kpiType: kpiType.GRAPH,
        showGroupBarByCombo: true,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_GROSS_REVENUE,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR,
                isShowArticleAverage: true
            }
        ]
    },
    NetRevenue: {
        menu: "sales/menu/NetRevenue",
        info: "sales/menu/NetRevenueInfo",
        kpiType: kpiType.GRAPH,
        showGroupBarByCombo: true,
        showHeadLine: false,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_NET_REVENUE,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR,
                layout: "twoChart",
                headLine: "sales/NetRevenue/headLine",
                subHeadLine: "sales/NetRevenue/subHeadLine",
                isShowArticleAverage: true
            },
            {
                type: DATA_TYPE.SALES_DASHBOARD_SUMMARIES_AFTER_RETURNS_GRAPH,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR,
                showComparison: false,
                layout: "twoChart",
                headLine: "sales/SummariesAfterReturnsGraph/headLine",
                subHeadLine: "sales/SummariesAfterReturnsGraph/subHeadLine"
            }
        ]
    },
    Deliveries: {
        menu: "sales/menu/Deliveries",
        info: "sales/menu/DeliveriesInfo",
        showGroupBarByCombo: true,
        kpiType: kpiType.GRAPH,
        showHeadLine: false,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_DELIVERIES,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR,
                layout: "twoChart",
                headLine: "sales/Deliveries/headLine",
                subHeadLine: "sales/Deliveries/subHeadLine"
            },
            {
                type: DATA_TYPE.SALES_DASHBOARD_SUMMARIES_GROSS_GRAPH,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR,
                showComparison: false,
                layout: "twoChart",
                headLine: "sales/SummariesGrossGraph/headLine",
                subHeadLine: "sales/SummariesGrossGraph/subHeadLine"
            }
        ]
    },
    Returns: {
        menu: "sales/menu/Returns",
        info: "sales/menu/ReturnsInfo",
        showGroupBarByCombo: true,
        kpiType: kpiType.GRAPH,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_RETURNS,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR
            }
        ]
    },
    GrossBaskets: {
        menu: "sales/menu/GrossBasketsNew",
        info: "sales/menu/GrossBasketsInfo",
        showGroupBarByCombo: true,
        kpiType: kpiType.GRAPH,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_GROSS_BASKETS,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR,
                isShowArticleAverage: true
            }
        ]
    },
    GrossArticles: {
        menu: "sales/menu/GrossArticles",
        info: "sales/menu/GrossArticlesInfoNew",
        showGroupBarByCombo: true,
        kpiType: kpiType.GRAPH,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_GROSS_ARTICLES,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR,
                isShowArticleAverage: true
            }
        ]
    },
    CancelledArticles: {
        menu: "sales/menu/CancelledArticles",
        info: "sales/menu/CancelledArticlesInfo",
        showGroupBarByCombo: true,
        kpiType: kpiType.GRAPH,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_CANCELLED_ARTICLES,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR
            }
        ]
    },
    PercentageCancelledArticles: {
        menu: "sales/menu/PercentageCancelledArticles",
        info: "sales/menu/PercentageCancelledArticlesInfo",
        showGroupBarByCombo: true,
        kpiType: kpiType.GRAPH,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_PERCENTAGE_CANCELLED_ARTICLES,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.LINE,
                showPercentChange: true
            }
        ]
    },
    QuantityReturnedArticles: {
        menu: "sales/menu/QuantityReturnedArticles",
        info: "sales/menu/QuantityReturnedArticlesInfo",
        showGroupBarByCombo: true,
        kpiType: kpiType.GRAPH,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_QUANTITY_RETURNED_ARTICLES,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.BAR
            }
        ]
    },
    PercentageReturnedArticles: {
        menu: "sales/menu/PercentageReturnedArticles",
        info: "sales/menu/PercentageReturnedArticlesInfo",
        showGroupBarByCombo: true,
        kpiType: kpiType.GRAPH,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_PERCENTAGE_RETURNED_ARTICLES,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.LINE,
                showPercentChange: true,
                layout: "twoChart",
                headLine: "sales/PercentageReturnedArticles/headLine",
                subHeadLine: "sales/PercentageReturnedArticles/subHeadLine"
            },
            {
                type:
                    DATA_TYPE.SALES_DASHBOARD_PERCENTAGE_RETURNED_AFTER_CANCELLATION_ARTICLES,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.LINE,
                showPercentChange: true,
                layout: "twoChart",
                headLine:
                    "sales/PercentageReturnedAfterCancellationArticles/headLine",
                subHeadLine:
                    "sales/PercentageReturnedAfterCancellationArticles/subHeadLine"
            },
            {
                type:
                    DATA_TYPE.SALES_DASHBOARD_PERCENTAGE_RETURNED_VALUE_ARTICLES,
                viewType: viewType.GRAPH,
                graphType: GRAPH_TYPE.LINE,
                showPercentChange: true,
                layout: "twoChart",
                headLine: "sales/PercentageReturnedValueArticles/headLine",
                subHeadLine: "sales/PercentageReturnedValueArticles/subHeadLine"
            }
        ]
    },

    // ITEM TABLE
    GrossSalesPerProduct: {
        menu: "sales/menu/GrossSalesPerProduct",
        info: "sales/menu/GrossSalesPerProductInfo",
        showGroupByVariant: true,
        kpiType: kpiType.ITEM_TABLE,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_GROSS_SALES_PER_PRODUCT,
                viewType: viewType.TABLE,
                defaultSort: "qtyPrice",
                tableType: "grossPerProduct",
                tabType: TAB_TYPE.SALES_GROSS_SALES_PER_PRODUCT
            }
        ]
    },
    GrossSalesPerProductByCreationDate: {
        menu: "sales/menu/GrossSalesPerProductByCreationDate",
        info: "sales/menu/GrossSalesPerProductByCreationDateInfo",
        showGroupByVariant: true,
        kpiType: kpiType.ITEM_TABLE,
        widgets: [
            {
                type:
                    DATA_TYPE.SALES_DASHBOARD_GROSS_SALES_PER_PRODUCT_BY_CREATION_DATE,
                viewType: viewType.TABLE,
                defaultSort: "qtyPrice",
                tableType: "fullGrossSalesPerProduct",
                tabType: TAB_TYPE.SALES_GROSS_SALES_PER_PRODUCT_BY_CREATION_DATE
            }
        ]
    },
    NetSalesPerProductEuro: {
        menu: "sales/menu/NetSalesPerProductEuro",
        info: "sales/menu/NetSalesPerProductEuroInfo",
        showGroupByVariant: true,
        kpiType: kpiType.ITEM_TABLE,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_NET_SALES_PER_PRODUCT_EURO,
                viewType: viewType.TABLE,
                defaultSort: "shipReturnCancelPrice",
                tableType: "NetSalesPerProductEuro",
                tabType: TAB_TYPE.SALES_NET_SALES_PER_PRODUCT_EURO,
                showArticleReportCSVButton: true
            }
        ]
    },
    ReturnsEachProductPiece: {
        menu: "sales/menu/ReturnsEachProductPieceMenu",
        info: "sales/menu/ReturnsEachProductPieceInfo",
        showGroupByVariant: true,
        kpiType: kpiType.ITEM_TABLE,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_RETURNS_EACH_PRODUCT_PIECE,
                viewType: viewType.TABLE,
                defaultSort: "returnedQty",
                tableType: "ReturnsEachProductPiece",
                tabType: TAB_TYPE.SALES_RETURNS_EACH_PRODUCT_PIECE
            }
        ]
    },
    PercentageReturnsEachProduct: {
        menu: "sales/menu/PercentageReturnsEachProduct",
        info: "sales/menu/PercentageReturnsEachProductInfo",
        showGroupByVariant: true,
        kpiType: kpiType.ITEM_TABLE,
        widgets: [
            {
                type: DATA_TYPE.SALES_DASHBOARD_PERCENTAGE_RETURNS_EACH_PRODUCT,
                viewType: viewType.TABLE,
                defaultSort: "returnsEachProductPercent",
                tableType: "returnsPercentageTable",
                tabType: TAB_TYPE.SALES_PERCENTAGE_RETURNS_EACH_PRODUCT
            }
        ]
    }
};

export const tableData = {
    tableType: "dashboard",
    graphType: "dashboardGraph",
    graphUrl: "orders/sales",
    exportKey: "sales_graph",
    gross: {
        columns: [
            {
                title: "sales/dashboard/TimeFrame",
                sorter: true,
                dataIndex: "date"
            },
            {
                title: "sales/dashboard/Channel",
                sorter: true,
                dataIndex: "channelName"
            },
            {
                title: "sales/dashboard/Partner",
                sorter: true,
                dataIndex: "partnerName"
            },
            {
                title: "sales/dashboard/Brand",
                sorter: true,
                dataIndex: "brandName"
            },
            {
                title: "sales/dashboard/NumberOfOrders",
                sorter: true,
                dataIndex: "numberOfOrders",
                align: "right"
            },
            {
                title: "sales/dashboard/ShipQty",
                sorter: true,
                dataIndex: "shipQty",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/CancelQty",
                sorter: true,
                dataIndex: "cancelQty",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/CancelPercent",
                sorter: true,
                dataIndex: "cancelPercent",
                render: field => Utils.decimalFormat(field) + " %",
                align: "right"
            },
            {
                title: "sales/dashboard/ParcelCount",
                sorter: true,
                dataIndex: "parcelCount",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/CancelLevel",
                sorter: true,
                dataIndex: "cancelLevel",
                render: cancelLevel => {
                    return cancelLevel ? (
                        <SpanI18n
                            title={
                                "customerPerformance/cancellations/Level" +
                                cancelLevel
                            }
                        />
                    ) : (
                        "-"
                    );
                }
            },
            {
                title: "sales/dashboard/Gross",
                sorter: true,
                dataIndex: "shipPrice",
                render: Utils.renderCurrency("result"),
                align: "right",
                hidden: true
            },
            {
                title: "sales/dashboard/GrossExchanged",
                sorter: true,
                dataIndex: "shipPriceExchanged",
                render: Utils.renderCurrency("result"),
                align: "right"
            },
            {
                title: "sales/dashboard/Sla1ShipQty",
                sorter: true,
                dataIndex: "sla1ShippedQty",
                render: field => Utils.decimalFormat(field) + " %",
                align: "right"
            },
            {
                title: "sales/dashboard/Sla2ShipQty",
                sorter: true,
                dataIndex: "sla2ShippedQty",
                render: field => Utils.decimalFormat(field) + " %",
                align: "right"
            },
            {
                title: "sales/dashboard/Sla3ShipQty",
                sorter: true,
                dataIndex: "sla3ShippedQty",
                render: field => Utils.decimalFormat(field) + " %",
                align: "right"
            },
            {
                title: "sales/dashboard/Sla3Level",
                sorter: true,
                dataIndex: "sla3Level",
                render: sla3Level => {
                    return sla3Level ? (
                        <SpanI18n
                            title={"customerPerformance/SLA3/Level" + sla3Level}
                        />
                    ) : (
                        "-"
                    );
                }
            }
        ]
    },

    afterReturns: {
        columns: [
            {
                title: "sales/dashboard/TimeFrame",
                sorter: true,
                dataIndex: "date"
            },
            {
                title: "sales/dashboard/Channel",
                sorter: true,
                dataIndex: "channelName"
            },
            {
                title: "sales/dashboard/Partner",
                sorter: true,
                dataIndex: "partnerName"
            },
            {
                title: "sales/dashboard/Brand",
                sorter: true,
                dataIndex: "brandName"
            },
            {
                title: "sales/dashboard/NumberOfOrders",
                sorter: true,
                dataIndex: "numberOfOrders"
            },
            {
                title: "sales/dashboard/ShipQty",
                sorter: true,
                dataIndex: "shipQty",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/ReturnQty",
                sorter: true,
                dataIndex: "returnQty",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/ReturnPercent",
                sorter: true,
                dataIndex: "returnPercent",
                render: field => Utils.decimalFormat(field) + " %",
                align: "right"
            },
            {
                title: "sales/dashboard/Gross",
                sorter: true,
                dataIndex: "shipPrice",
                render: Utils.renderCurrency("result"),
                align: "right",
                hidden: true
            },
            {
                title: "sales/dashboard/GrossExchanged",
                sorter: true,
                dataIndex: "shipPriceExchanged",
                render: Utils.renderCurrency("result"),
                align: "right"
            },
            {
                title: "sales/dashboard/Net",
                sorter: true,
                dataIndex: "shipReturnPrice",
                render: Utils.renderCurrency("result"),
                align: "right",
                hidden : true
            },
            {
                title: "sales/dashboard/NetExchanged",
                sorter: true,
                dataIndex: "shipReturnPriceExchanged",
                render: Utils.renderCurrency("result"),
                align: "right"
            }
        ]
    },

    summariesHistory: {
        columns: [
            {
                title: "sales/dashboard/TimeFrame",
                sorter: true,
                dataIndex: "date"
            },
            {
                title: "sales/dashboard/ShipQty",
                sorter: true,
                dataIndex: "shipQty",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/ReturnQty",
                sorter: true,
                dataIndex: "returnQty",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/CancelQty",
                sorter: true,
                dataIndex: "cancelQty",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/RecordCount",
                sorter: true,
                dataIndex: "recordCount",
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/Xls",
                dataIndex: "interval"
            }
        ]
    },

    NetSalesPerProductEuro: {
        columns: [
            {
                title: "sales/dashboard/TimeFrame",
                sorter: true,
                dataIndex: "date"
            },
            {
                title: "sales/dashboard/ItemRelated/OrderID",
                sorter: true,
                dataIndex: "productId"
            },
            {
                title: "sales/dashboard/ArticleId",
                sorter: true,
                dataIndex: "articleId"
            },
            {
                title: "sales/dashboard/PartnerProductId",
                dataIndex: "partnerProductId",
                sorter: true
            },
            {
                title: "sales/dashboard/PartnerArticleId",
                dataIndex: "partnerArticleId",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleColor",
                dataIndex: "articleColor",
                sorter: true
            },
            {
                title: "sales/dashboard/Ean",
                dataIndex: "ean",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleSize",
                dataIndex: "articleSize",
                sorter: true
            },
            {
                title: "sales/dashboard/Name",
                dataIndex: "name",
                sorter: true
            },
            {
                title: "sales/dashboard/Brand",
                dataIndex: "brand",
                sorter: true
            },
            {
                title: "sales/dashboard/ItemQty",
                dataIndex: "shipReturnCancelQty",
                sorter: true,
                align: "right"
            },
            {
                title: "sales/dashboard/BestsellersAfterReturnsResult",
                dataIndex: "shipReturnCancelPrice",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right",
                hidden: true
            },
            {
                title: "sales/dashboard/BestsellersAfterReturnsResultExchanged",
                dataIndex: "shipReturnCancelPriceExchanged",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right"
            },
            {
                title: "sales/dashboard/seasonLibrary",
                dataIndex: "seasonLibrary",
                sorter: true
            },
            {
                title: "sales/dashboard/targetGroup",
                dataIndex: "targetGroup",
                sorter: true
            },
            {
                title: "sales/dashboard/category",
                dataIndex: "category",
                sorter: true
            }
        ]
    },

    returnsPercentageTable: {
        columns: [
            {
                title: "sales/dashboard/TimeFrame",
                sorter: true,
                dataIndex: "date"
            },
            {
                title: "sales/dashboard/ItemRelated/OrderID",
                sorter: true,
                dataIndex: "productId"
            },
            {
                title: "sales/dashboard/PartnerProductId",
                dataIndex: "partnerProductId",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleId",
                sorter: true,
                dataIndex: "articleId"
            },
            {
                title: "sales/dashboard/PartnerArticleId",
                dataIndex: "partnerArticleId",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleColor",
                dataIndex: "articleColor",
                sorter: true
            },
            {
                title: "sales/dashboard/Name",
                dataIndex: "name",
                sorter: true
            },
            {
                title: "sales/dashboard/Brand",
                dataIndex: "brand",
                sorter: true
            },
            {
                title: "sales/dashboard/Result",
                dataIndex: "returnsEachProductPercent",
                sorter: true,
                render: field => Utils.decimalFormat(field) + " %",
                align: "right"
            },
            {
                title: "sales/dashboard/ReturnedQty",
                dataIndex: "returnedQty",
                sorter: true,
                align: "right"
            },
            {
                title: "sales/dashboard/seasonLibrary",
                dataIndex: "seasonLibrary",
                sorter: true
            },
            {
                title: "sales/dashboard/targetGroup",
                dataIndex: "targetGroup",
                sorter: true
            },
            {
                title: "sales/dashboard/category",
                dataIndex: "category",
                sorter: true
            }
        ]
    },

    ReturnsEachProductPiece: {
        columns: [
            {
                title: "sales/dashboard/TimeFrame",
                sorter: true,
                dataIndex: "date"
            },
            {
                title: "sales/dashboard/ItemRelated/OrderID",
                sorter: true,
                dataIndex: "productId"
            },
            {
                title: "sales/dashboard/PartnerProductId",
                dataIndex: "partnerProductId",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleId",
                sorter: true,
                dataIndex: "articleId"
            },
            {
                title: "sales/dashboard/PartnerArticleId",
                dataIndex: "partnerArticleId",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleColor",
                dataIndex: "articleColor",
                sorter: true
            },
            {
                title: "sales/dashboard/Name",
                dataIndex: "name",
                sorter: true
            },
            {
                title: "sales/dashboard/Brand",
                dataIndex: "brand",
                sorter: true
            },
            {
                title: "sales/dashboard/ReturnsEachProductPieceResult",
                dataIndex: "returnedQty",
                sorter: true,
                align: "right"
            },
            {
                title: "sales/dashboard/seasonLibrary",
                dataIndex: "seasonLibrary",
                sorter: true
            },
            {
                title: "sales/dashboard/targetGroup",
                dataIndex: "targetGroup",
                sorter: true
            },
            {
                title: "sales/dashboard/category",
                dataIndex: "category",
                sorter: true
            }
        ]
    },

    grossPerProduct: {
        columns: [
            {
                title: "sales/dashboard/TimeFrame",
                sorter: true,
                dataIndex: "date"
            },
            {
                title: "sales/dashboard/ItemRelated/OrderID",
                sorter: true,
                dataIndex: "productId"
            },
            {
                title: "sales/dashboard/ArticleId",
                sorter: true,
                dataIndex: "articleId"
            },
            {
                title: "sales/dashboard/PartnerProductId",
                dataIndex: "partnerProductId",
                sorter: true
            },
            {
                title: "sales/dashboard/PartnerArticleId",
                dataIndex: "partnerArticleId",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleColor",
                dataIndex: "articleColor",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleEan",
                dataIndex: "ean",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleSize",
                dataIndex: "articleSize",
                sorter: true
            },
            {
                title: "sales/dashboard/Name",
                dataIndex: "name",
                sorter: true
            },
            {
                title: "sales/dashboard/Brand",
                dataIndex: "brand",
                sorter: true
            },
            {
                title: "sales/dashboard/NumberOfArticles",
                dataIndex: "qty",
                sorter: true,
                align: "right"
            },
            {
                title: "sales/dashboard/BestsellersResult",
                dataIndex: "qtyPrice",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right",
                hidden: true
            },
            {
                title: "sales/dashboard/BestsellersResultExchanged",
                dataIndex: "qtyPriceExchanged",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right"

            },
            {
                title: "sales/dashboard/seasonLibrary",
                dataIndex: "seasonLibrary",
                sorter: true
            },
            {
                title: "sales/dashboard/targetGroup",
                dataIndex: "targetGroup",
                sorter: true
            },
            {
                title: "sales/dashboard/category",
                dataIndex: "category",
                sorter: true
            },
            {
                title: "sales/dashboard/OldestOrderCreationDate",
                dataIndex: "oldestOrderCreationDate",
                sorter: true
            }
        ]
    },

    fullGrossSalesPerProduct: {
        columns: [
            {
                title: "sales/dashboard/TimeFrame",
                sorter: true,
                dataIndex: "date"
            },
            {
                title: "sales/dashboard/ItemRelated/OrderID",
                sorter: true,
                dataIndex: "productId"
            },
            {
                title: "sales/dashboard/PartnerProductId",
                dataIndex: "partnerProductId",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleColor",
                dataIndex: "articleColor",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleEan",
                dataIndex: "ean",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleSize",
                dataIndex: "articleSize",
                sorter: true
            },
            {
                title: "sales/dashboard/ArticleId",
                sorter: true,
                dataIndex: "articleId"
            },
            {
                title: "sales/dashboard/PartnerArticleId",
                dataIndex: "partnerArticleId",
                sorter: true
            },
            {
                title: "sales/dashboard/Name",
                dataIndex: "name",
                sorter: true
            },
            {
                title: "sales/dashboard/Brand",
                dataIndex: "brand",
                sorter: true
            },
            {
                title: "sales/dashboard/FullReportSales/NumberOfArticles",
                dataIndex: "qty",
                sorter: true,
                align: "right"
            },
            {
                title: "sales/dashboard/FullReportSales/Result",
                dataIndex: "qtyPrice",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right",
                hidden: true
            },
            {
                title: "sales/dashboard/FullReportSales/ResultExchanged",
                dataIndex: "qtyPriceExchanged",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right"

            },
            {
                title: "sales/dashboard/NetSalesLessReturnsAmount",
                dataIndex: "qtyReturnPrice",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right",
                hidden: true
            },
            {
                title: "sales/dashboard/NetSalesLessReturnsAmountExchanged",
                dataIndex: "qtyReturnPriceExchanged",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right"
            },
            {
                title: "sales/dashboard/GrossOfReturns",
                dataIndex: "returnPrice",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right",
                hidden : true
            },
            {
                title: "sales/dashboard/GrossOfReturnsExchanged",
                dataIndex: "returnPriceExchanged",
                sorter: true,
                render: Utils.renderCurrency("result"),
                align: "right"
            },
            {
                title: "sales/dashboard/NetSalesLessReturns",
                dataIndex: "shipReturnCancelQty",
                sorter: true,
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/NetSalesReturnQty",
                dataIndex: "returnedQty",
                sorter: true,
                render: field => {
                    return field ? field : 0;
                },
                align: "right"
            },
            {
                title: "sales/dashboard/NetSalesReturnPercentage",
                dataIndex: "returnsEachProductPercent",
                sorter: true,
                render: field => Utils.decimalFormat(field) + " %",
                align: "right"
            },
            {
                title: "sales/dashboard/seasonLibrary",
                dataIndex: "seasonLibrary",
                sorter: true
            },
            {
                title: "sales/dashboard/targetGroup",
                dataIndex: "targetGroup",
                sorter: true
            },
            {
                title: "sales/dashboard/category",
                dataIndex: "category",
                sorter: true
            },
            {
                title: "sales/dashboard/OldestOrderCreationDate",
                dataIndex: "oldestOrderCreationDate",
                sorter: true
            }
        ]
    }
};
