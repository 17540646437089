"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.app = void 0;

var _redux = require("redux");

var _reducer = require("./updateFlow/reducer");

var app = (0, _redux.combineReducers)({
  updateFlow: _reducer.updateFlow
});
exports.app = app;