"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _TranslateAware = _interopRequireDefault(require("../TranslateAware/TranslateAware"));

var _Version = require("../Version");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Footer = function Footer(_ref) {
  var translate = _ref.translate;
  return _react["default"].createElement("footer", {
    className: "app-footer"
  }, _react["default"].createElement(_antd.Row, {
    type: "flex",
    justify: "space-around"
  }, _react["default"].createElement(_antd.Col, {
    sm: 8,
    md: 10,
    className: "left-col"
  }, _react["default"].createElement("a", {
    href: "http://www.onquality.de",
    target: "_blank",
    rel: "noopener noreferrer"
  }, translate("footer/copyright")), _react["default"].createElement("span", {
    className: "app-version-mobile"
  }, "\xA9 ", new Date().getFullYear(), ".", _react["default"].createElement(_Version.Version, null))), _react["default"].createElement(_antd.Col, {
    sm: 8,
    md: 4,
    className: "center-col"
  }, _react["default"].createElement("a", {
    href: "https://www.onquality.de/impressum",
    target: "_blank",
    rel: "noopener noreferrer"
  }, translate('footer/text'), ".")), _react["default"].createElement(_antd.Col, {
    sm: 8,
    md: 5,
    className: "right-col"
  }, translate("footer/poweredBy"))));
};

var _default = (0, _TranslateAware["default"])(Footer);

exports["default"] = _default;