"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userPermissionsSagas = void 0;

var _saga = require("./changeStatus/saga");

var _saga2 = require("./changeStatusBatch/saga");

var userPermissionsSagas = [_saga.changeStatusWatcher, _saga2.changeStatusBatchWatcher];
exports.userPermissionsSagas = userPermissionsSagas;