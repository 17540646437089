"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LanguageSelector = void 0;

var _TranslateAware = require("../../TranslateAware");

var _reactRedux = require("react-redux");

var _LanguageSelector = require("./LanguageSelector");

var _selectors = require("../../../store/version/selectors");

var _selectors2 = require("../../../store/locale/selectors");

var _actions = require("../../../store/locale/actions");

var mapStateToProps = function mapStateToProps(state) {
  return {
    selectedLocale: (0, _selectors2.getLocale)(state),
    isTestEnvironment: (0, _selectors.getIsTestEnvironment)(state)
  };
};

var mapDispatchToProps = {
  setLocale: _actions.saga
};
var LanguageSelector = (0, _TranslateAware.TranslateAware)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_LanguageSelector.LanguageSelector));
exports.LanguageSelector = LanguageSelector;