"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNewVersion = exports.getIsProcessing = void 0;

var getIsProcessing = function getIsProcessing(state) {
  return state.app.updateFlow.isProcessing;
};

exports.getIsProcessing = getIsProcessing;

var getNewVersion = function getNewVersion(state) {
  return state.app.updateFlow.newVersion;
};

exports.getNewVersion = getNewVersion;