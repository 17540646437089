"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NOTIFICATION_WARNING_TYPE = exports.NOTIFICATION_INFO_TYPE = exports.NOTIFICATION_ERROR_TYPE = exports.NOTIFICATION_SUCCESS_TYPE = void 0;
var NOTIFICATION_SUCCESS_TYPE = "success";
exports.NOTIFICATION_SUCCESS_TYPE = NOTIFICATION_SUCCESS_TYPE;
var NOTIFICATION_ERROR_TYPE = "error";
exports.NOTIFICATION_ERROR_TYPE = NOTIFICATION_ERROR_TYPE;
var NOTIFICATION_INFO_TYPE = "info";
exports.NOTIFICATION_INFO_TYPE = NOTIFICATION_INFO_TYPE;
var NOTIFICATION_WARNING_TYPE = "warning";
exports.NOTIFICATION_WARNING_TYPE = NOTIFICATION_WARNING_TYPE;