"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getType = exports.getButtonText = exports.getText = exports.getTitle = exports.getVisible = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _modalTypes = require("../../constants/modalTypes");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getVisible = function getVisible(state) {
  return _lodash["default"].get(state.modal, 'visible', false);
};

exports.getVisible = getVisible;

var getTitle = function getTitle(state) {
  return _lodash["default"].get(state.modal, 'title');
};

exports.getTitle = getTitle;

var getText = function getText(state) {
  return _lodash["default"].get(state.modal, 'text');
};

exports.getText = getText;

var getButtonText = function getButtonText(state) {
  return _lodash["default"].get(state.modal, 'buttonText');
};

exports.getButtonText = getButtonText;

var getType = function getType(state) {
  return _lodash["default"].get(state.modal, 'type', _modalTypes.MODAL_TYPES.DEFAULT);
};

exports.getType = getType;