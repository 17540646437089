import "./config/settings";
import "@onquality/dashboard-ui/lib/utils/os.js";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"
import { store, persistor } from "./store";
import { configureApi } from "@onquality/dashboard-ui/lib/api";
import "./config/menu";
import "./config/router";

import { App } from "@onquality/dashboard-ui/lib/views/App";

// Style
import "assets/scss/boot.scss";
import "assets/css/font-awesome.css";
import "assets/css/simple-line-icons.css";
import "antd/dist/antd.css";
import "assets/scss/style.scss";
import loader from "assets/img/loader.svg";


// import * as serviceWorker from './serviceWorker';
configureApi(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App loaderImg={loader} />
        </PersistGate>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
