"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFieldValue = exports.getFilterValue = exports.getValue = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// TODO: deprecated. Use getFilterValue
var getValue = function getValue(state, filterType) {
  return _lodash["default"].get(state.filters.value, filterType, {});
};

exports.getValue = getValue;

var getFilterValue = function getFilterValue(state, filterType) {
  return _lodash["default"].get(state.filters.value, filterType, {});
};

exports.getFilterValue = getFilterValue;

var getFieldValue = function getFieldValue(state, filterType, fieldName) {
  return _lodash["default"].get(state.filters.value, [filterType, fieldName]);
};

exports.getFieldValue = getFieldValue;