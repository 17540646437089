"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ModalWindow", {
  enumerable: true,
  get: function get() {
    return _ModalWindow.ModalWindow;
  }
});
exports["default"] = void 0;

var _ModalWindow = require("./ModalWindow.connect");

var _default = _ModalWindow.ModalWindow;
exports["default"] = _default;