"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilterForm = void 0;

var _FilterForm = require("./FilterForm");

var _FilterFormProvider = require("./FilterFormProvider");

var _connect = _interopRequireDefault(require("react-redux/es/connect/connect"));

var _TranslateAware = require("../TranslateAware");

var _selectors = require("../../store/filters/settings/selectors");

var _actions = require("../../store/filters/settings/actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var mapStateToProps = function mapStateToProps(state, props) {
  return {
    isFilterFormHidden: (0, _selectors.isFilterFormHidden)(state)
  };
};

var mapDispatchToProps = {
  setFilterFormHidden: _actions.set
};
var FilterForm = (0, _TranslateAware.TranslateAware)((0, _FilterFormProvider.FilterFormProvider)((0, _connect["default"])(mapStateToProps, mapDispatchToProps)(_FilterForm.FilterForm)));
exports.FilterForm = FilterForm;