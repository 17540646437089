"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ABORT = exports.FAILURE = exports.SUCCESS = exports.SET_STATUS = exports.SAGA_STATUS = exports.START = exports.SAGA = void 0;
var SAGA = 'apiFlow/download/SAGA';
exports.SAGA = SAGA;
var START = 'apiFlow/download/START';
exports.START = START;
var SAGA_STATUS = 'apiFlow/download/SAGA_STATUS';
exports.SAGA_STATUS = SAGA_STATUS;
var SET_STATUS = 'apiFlow/download/SET_STATUS';
exports.SET_STATUS = SET_STATUS;
var SUCCESS = 'apiFlow/download/SUCCESS';
exports.SUCCESS = SUCCESS;
var FAILURE = 'apiFlow/download/FAILURE';
exports.FAILURE = FAILURE;
var ABORT = 'apiFlow/download/ABORT';
exports.ABORT = ABORT;