"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsAborted = exports.getError = exports.getIsFetching = void 0;

var getIsFetching = function getIsFetching(state) {
  return Boolean(state.apiFlow.download.isFetching);
};

exports.getIsFetching = getIsFetching;

var getError = function getError(state) {
  return state.apiFlow.download.error;
};

exports.getError = getError;

var getIsAborted = function getIsAborted(state) {
  return state.apiFlow.download.isAborted;
};

exports.getIsAborted = getIsAborted;