"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFieldsByFilterType = exports.getFormFields = exports.getProperty = exports.getField = exports.getProps = exports.getNextId = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getNextId = function getNextId(state) {
  return state.filters.fields.reduce(function (maxId, field) {
    return Math.max(field.id, maxId);
  }, 0) + 1;
};

exports.getNextId = getNextId;

var getProps = function getProps(state, id) {
  return _lodash["default"].findLast(state.filters.fields, function (field) {
    return field.id === id;
  });
};

exports.getProps = getProps;

var getField = function getField(state, id) {
  return getProps(state, id);
};

exports.getField = getField;

var getProperty = function getProperty(state, id, propertyName) {
  return _lodash["default"].get(getProps(state, id), propertyName);
};

exports.getProperty = getProperty;

var getFormFields = function getFormFields(state, formId) {
  return state.filters.fields.filter(function (field) {
    return field.formId === formId;
  });
};

exports.getFormFields = getFormFields;

var getFieldsByFilterType = function getFieldsByFilterType(state, filterType) {
  return state.filters.fields.filter(function (field) {
    return field.filterType === filterType;
  });
};

exports.getFieldsByFilterType = getFieldsByFilterType;