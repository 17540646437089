"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SwitchStatusBatch = void 0;

var _reactRedux = require("react-redux");

var _TranslateAware = _interopRequireDefault(require("../../../components/TranslateAware/TranslateAware"));

var _SwitchStatusBatch = require("./SwitchStatusBatch");

var _actions = require("../../../store/apiFlow/userPermissions/changeStatusBatch/actions");

var _selectors = require("../../../store/filters/value/selectors");

var _selectors2 = require("../../../store/apiFlow/userPermissions/changeStatusBatch/selectors");

var _filters = require("../../../constants/filters");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var mapStateToProps = function mapStateToProps(state, props) {
  return {
    isFetching: (0, _selectors2.getIsFetching)(state),
    filter: (0, _selectors.getValue)(state, _filters.FILTER_TYPE.USER_PERMISSIONS)
  };
};

var mapDispatchToProps = {
  changeStatusBatch: _actions.saga
};
var SwitchStatusBatch = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)((0, _TranslateAware["default"])(_SwitchStatusBatch.SwitchStatusBatch, 'userPermissions/changeStatusBatch'));
exports.SwitchStatusBatch = SwitchStatusBatch;