"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOptions = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getOptions = function getOptions(state, filterType, fieldName) {
  return _lodash["default"].get(state.filters.options, [filterType, fieldName], []);
};

exports.getOptions = getOptions;