"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customTableViews = void 0;

var _redux = require("redux");

var _reducer = require("./loadAll/reducer");

var _reducer2 = require("./save/reducer");

var customTableViews = (0, _redux.combineReducers)({
  loadAll: _reducer.loadAll,
  save: _reducer2.save
});
exports.customTableViews = customTableViews;