"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Version = void 0;

var _reactRedux = require("react-redux");

var _TranslateAware = require("../TranslateAware");

var _selectors = require("../../store/version/selectors");

var _Version = require("./Version");

var mapStateToProps = function mapStateToProps(state) {
  return {
    version: (0, _selectors.getVersion)(state)
  };
};

var mapDispatchToProps = {};
var Version = (0, _TranslateAware.TranslateAware)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Version.Version));
exports.Version = Version;