"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RedirectToFirstMenu = void 0;

var _reactRedux = require("react-redux");

var _RedirectToFirstMenu = require("./RedirectToFirstMenu");

var _selectors = require("../../store/user/selectors");

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: (0, _selectors.getUser)(state)
  };
};

var RedirectToFirstMenu = (0, _reactRedux.connect)(mapStateToProps)(_RedirectToFirstMenu.RedirectToFirstMenu);
exports.RedirectToFirstMenu = RedirectToFirstMenu;