"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOKEN_EXPIRE_CHECKER_SAGA = exports.UNAUTHORIZE_SAGA = exports.UNAUTHORIZE = exports.AUTH = void 0;
var AUTH = 'auth/AUTH';
exports.AUTH = AUTH;
var UNAUTHORIZE = 'auth/UNAUTHORIZE';
exports.UNAUTHORIZE = UNAUTHORIZE;
var UNAUTHORIZE_SAGA = 'auth/UNAUTHORIZE_SAGA';
exports.UNAUTHORIZE_SAGA = UNAUTHORIZE_SAGA;
var TOKEN_EXPIRE_CHECKER_SAGA = 'auth/TOKEN_EXPIRE_CHECKER_SAGA';
exports.TOKEN_EXPIRE_CHECKER_SAGA = TOKEN_EXPIRE_CHECKER_SAGA;