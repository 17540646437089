import { persistStore, persistReducer } from 'redux-persist'
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from "./rootReducer";
import { Config } from "@onquality/dashboard-ui/lib/config";
import { rootSaga } from "./rootSaga";
import * as LocalForage from 'localforage';

// TODO: remove redux-thunk
import thunkMiddleware from "redux-thunk";

// // TODO: remove this middleware and use simple thunk pattern
const reduxAsyncActionsMiddleware = ({ dispatch, getState }) => {
    return next => action => {
        if (action && !action.hasOwnProperty('types')) {
            // Normal action: pass it on
            return next(action);
        }

        const {
            types,
            fetch,
            shouldCallAPI = () => true,
            payload = {}
        } = action;

        if (
            !Array.isArray(types) ||
            types.length !== 3 ||
            !types.every(type => typeof type === 'string')
        ) {
            throw new Error('Expected an array of three string types.');
        }

        if (typeof fetch !== 'function') {
            throw new Error('Expected `fetch` to be a function.');
        }

        if (!shouldCallAPI()) {
            return;
        }

        const [requestType, successType, failureType] = types;

        dispatch(
            Object.assign({}, payload, {
                type: requestType
            })
        );

        return fetch().then(
            response => {
                dispatch(
                    Object.assign({}, payload, {
                        response,
                        type: successType
                    })
                );
            },
            error => {
                dispatch(
                    Object.assign({}, payload, {
                        error,
                        type: failureType
                    })
                );
            }
        );
    }
};


const persistConfig = {
    key: Config.get("settings", "storagePrefix"),
    version: 0,
    whitelist: [
        'auth',
        'user',
        'locale',
        'version',
        "partnerEmulation"
    ],
    storage: LocalForage
};

export const configureStore = () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const sagaMiddleware = createSagaMiddleware();

    let middlewareList = [
        thunkMiddleware,
        sagaMiddleware,
        reduxAsyncActionsMiddleware
    ];

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(...middlewareList))
    );

    const persistor = persistStore(store);

    sagaMiddleware.run(rootSaga);

    return { store, persistor };
};
