"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPLOAD_FILTER_URLS = exports.FILTER_MODE_TYPE = exports.FILTER_RELATION_TYPE = exports.FILTER_COLUMN_LIST_TYPE = exports.FILTER_COLUMN_TYPE = void 0;

var _filters = require("./filters");

var _FILTER_RELATION_TYPE, _FILTER_RELATION_TYPE2, _FILTER_RELATION_TYPE3, _FILTER_RELATION_TYPE4, _FILTER_RELATION_TYPE5, _FILTER_RELATION_TYPE6, _FILTER_RELATION_TYPE7, _FILTER_RELATION_TYPE10, _FILTER_RELATION_TYPE13, _UPLOAD_FILTER_URLS;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// TODO: remove after refactor. Use only FILTER_FIELD
var FILTER_COLUMN_TYPE = {
  CHANNEL_ID: 'channelId',
  PARTNER_ID: 'partnerId',
  CHAN_ORD_REF: 'chanOrdRef',
  ORDER_ID: 'orderId',
  WAREHOUSE_ID: 'warehouseId',
  RETURN_WAREHOUSE_ID: 'returnWarehouseId',
  HAS_SINGLE_ITEM: 'hasSingleItem',
  ORDER_REFERENCE_FULFILMENT: 'orderReferenceFulfilment',
  CURRENCY: 'currency',
  STATUS: 'status',
  REASONS: 'reasons',
  EMAIL_SETTINGS_TYPE_ID: 'emailSettingsTypeId',
  ESCALATION: 'escalation',
  SLA_CATALOG: 'catalogSLA',
  EAN: 'ean',
  PARTNER_ARTICLE_ID: 'partnerArticleId',
  ARTICLE_ID: 'articleId',
  INVOICE_ID: 'invoiceId',
  OWNER_PARTNER_ID: 'ownerPartner',
  SERVED_PARTNER_ID: 'servedPartner',
  GROUP_TIME_BY: 'groupTimeBy',
  COUNTING_MODE: 'countingMode',
  RETURN_CONDITION: 'returnConditions',
  RETURN_REASONS: 'returnReasons',
  START_HUB: "startHub",
  TARGET_HUB: "targetHub",
  PAYMENT_ID: "paymentId",
  TYPE: "type"
};
exports.FILTER_COLUMN_TYPE = FILTER_COLUMN_TYPE;
var FILTER_COLUMN_LIST_TYPE = [FILTER_COLUMN_TYPE.CHANNEL_ID, FILTER_COLUMN_TYPE.PARTNER_ID, FILTER_COLUMN_TYPE.WAREHOUSE_ID, FILTER_COLUMN_TYPE.RETURN_WAREHOUSE_ID, FILTER_COLUMN_TYPE.REASONS, FILTER_COLUMN_TYPE.RETURN_CONDITION, FILTER_COLUMN_TYPE.RETURN_REASONS, FILTER_COLUMN_TYPE.SLA_CATALOG, FILTER_COLUMN_TYPE.ESCALATION, FILTER_COLUMN_TYPE.START_HUB, FILTER_COLUMN_TYPE.TARGET_HUB, FILTER_COLUMN_TYPE.OWNER_PARTNER_ID, FILTER_COLUMN_TYPE.SERVED_PARTNER_ID, FILTER_COLUMN_TYPE.COUNTING_MODE]; // TODO: remove after refactor. Not needed anymore

exports.FILTER_COLUMN_LIST_TYPE = FILTER_COLUMN_LIST_TYPE;
var FILTER_RELATION_TYPE = {
  CUMULATIVE: "cumulative",
  FULL: "full",
  GRAPH: "graph",
  PRODUCT_STATUS_CUMULATIVE: "PRODUCT_STATUS_CUMULATIVE",
  PRODUCT_STATUS_FULL: "PRODUCT_STATUS_FULL",
  PRODUCT_STATUS_GRAPH_PRIMARY: "PRODUCT_STATUS_GRAPH_PRIMARY",
  PRODUCT_STATUS_GRAPH_SECONDARY: "PRODUCT_STATUS_GRAPH_SECONDARY",
  SUMMARY_WAREHOUSE_CUMULATIVE: "SUMMARY_WAREHOUSE_CUMULATIVE",
  SUMMARY_WAREHOUSE_GRAPH: "SUMMARY_WAREHOUSE_GRAPH",
  OPENED_ORDER_CONFIRMATION: "OPENED_ORDER_CONFIRMATION",
  RETURNED_ORDER_CUMULATIVE: "RETURNED_ORDER_CUMULATIVE",
  RETURNED_ORDER_FULL: "RETURNED_ORDER_FULL",
  SLA_PARCEL_HISTORY: "SLA_PARCEL_HISTORY",
  SLA_HANDOUT_HISTORY: "SLA_HANDOUT_HISTORY",
  DEFAULT: "default"
};
exports.FILTER_RELATION_TYPE = FILTER_RELATION_TYPE;
var FILTER_MODE_TYPE = {
  MULTIPLE: "multiple",
  COMBOBOX: "combobox",
  DEFAULT: "default",
  SINGLE: "single",
  DATE: "date"
};
exports.FILTER_MODE_TYPE = FILTER_MODE_TYPE;
var UPLOAD_FILTER_URLS = (_UPLOAD_FILTER_URLS = {}, _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.CUMULATIVE, (_FILTER_RELATION_TYPE = {}, _defineProperty(_FILTER_RELATION_TYPE, FILTER_COLUMN_TYPE.CHANNEL_ID, {
  url: _filters.FILTER_URLS.CHANNEL_RELATED_CUMULATIVE_URL
}), _defineProperty(_FILTER_RELATION_TYPE, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.PARTNER_RELATED_CUMULATIVE_URL
}), _FILTER_RELATION_TYPE)), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.FULL, (_FILTER_RELATION_TYPE2 = {}, _defineProperty(_FILTER_RELATION_TYPE2, FILTER_COLUMN_TYPE.CHANNEL_ID, {
  url: _filters.FILTER_URLS.CHANNEL_RELATED_URL
}), _defineProperty(_FILTER_RELATION_TYPE2, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.PARTNER_RELATED_URL
}), _defineProperty(_FILTER_RELATION_TYPE2, FILTER_COLUMN_TYPE.START_HUB, {
  url: _filters.FILTER_URLS.START_HUB_RELATED_URL
}), _defineProperty(_FILTER_RELATION_TYPE2, FILTER_COLUMN_TYPE.TARGET_HUB, {
  url: _filters.FILTER_URLS.TARGET_HUB_RELATED_URL
}), _FILTER_RELATION_TYPE2)), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.GRAPH, (_FILTER_RELATION_TYPE3 = {}, _defineProperty(_FILTER_RELATION_TYPE3, FILTER_COLUMN_TYPE.CHANNEL_ID, {
  url: _filters.FILTER_URLS.CHANNEL_RELATED_GRAPH_URL
}), _defineProperty(_FILTER_RELATION_TYPE3, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.PARTNER_RELATED_GRAPH_URL
}), _FILTER_RELATION_TYPE3)), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.PRODUCT_STATUS_CUMULATIVE, (_FILTER_RELATION_TYPE4 = {}, _defineProperty(_FILTER_RELATION_TYPE4, FILTER_COLUMN_TYPE.CHANNEL_ID, {
  url: _filters.FILTER_URLS.PRODUCT_STATUS_CHANNEL_CUMULATIVE_URL
}), _defineProperty(_FILTER_RELATION_TYPE4, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.PRODUCT_STATUS_PARTNER_CUMULATIVE_URL
}), _FILTER_RELATION_TYPE4)), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.PRODUCT_STATUS_FULL, (_FILTER_RELATION_TYPE5 = {}, _defineProperty(_FILTER_RELATION_TYPE5, FILTER_COLUMN_TYPE.CHANNEL_ID, {
  url: _filters.FILTER_URLS.PRODUCT_STATUS_CHANNEL_URL
}), _defineProperty(_FILTER_RELATION_TYPE5, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.PRODUCT_STATUS_PARTNER_URL
}), _FILTER_RELATION_TYPE5)), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.SUMMARY_WAREHOUSE_CUMULATIVE, (_FILTER_RELATION_TYPE6 = {}, _defineProperty(_FILTER_RELATION_TYPE6, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.SUMMARY_WAREHOUSE_CUMULATIVE_PARTNER_URL
}), _defineProperty(_FILTER_RELATION_TYPE6, FILTER_COLUMN_TYPE.WAREHOUSE_ID, {
  url: _filters.FILTER_URLS.SUMMARY_WAREHOUSE_CUMULATIVE_WAREHOUSE_URL
}), _FILTER_RELATION_TYPE6)), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.SUMMARY_WAREHOUSE_GRAPH, (_FILTER_RELATION_TYPE7 = {}, _defineProperty(_FILTER_RELATION_TYPE7, FILTER_COLUMN_TYPE.WAREHOUSE_ID, {
  url: _filters.FILTER_URLS.WAREHOUSE_URL
}), _defineProperty(_FILTER_RELATION_TYPE7, FILTER_COLUMN_TYPE.OWNER_PARTNER_ID, {
  url: _filters.FILTER_URLS.PARTNER_URL
}), _defineProperty(_FILTER_RELATION_TYPE7, FILTER_COLUMN_TYPE.SERVED_PARTNER_ID, {
  url: _filters.FILTER_URLS.PARTNER_URL
}), _FILTER_RELATION_TYPE7)), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.PRODUCT_STATUS_GRAPH_PRIMARY, _defineProperty({}, FILTER_COLUMN_TYPE.CHANNEL_ID, {
  url: _filters.FILTER_URLS.CHANNEL_RELATED_GRAPH_URL
})), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.PRODUCT_STATUS_GRAPH_SECONDARY, _defineProperty({}, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.PARTNER_RELATED_GRAPH_URL
})), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.OPENED_ORDER_CONFIRMATION, (_FILTER_RELATION_TYPE10 = {}, _defineProperty(_FILTER_RELATION_TYPE10, FILTER_COLUMN_TYPE.CHANNEL_ID, {
  url: _filters.FILTER_URLS.OPENED_ORDER_CHANNEL_URL
}), _defineProperty(_FILTER_RELATION_TYPE10, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.OPENED_ORDER_PARTNER_URL
}), _FILTER_RELATION_TYPE10)), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.RETURNED_ORDER_CUMULATIVE, _defineProperty({}, FILTER_COLUMN_TYPE.RETURN_CONDITION, {
  url: _filters.FILTER_URLS.RETURN_CONDITION_CUMULATIVE_URL
})), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.RETURNED_ORDER_FULL, _defineProperty({}, FILTER_COLUMN_TYPE.RETURN_CONDITION, {
  url: _filters.FILTER_URLS.RETURN_CONDITION_URL
})), _defineProperty(_UPLOAD_FILTER_URLS, FILTER_RELATION_TYPE.DEFAULT, (_FILTER_RELATION_TYPE13 = {}, _defineProperty(_FILTER_RELATION_TYPE13, FILTER_COLUMN_TYPE.CHANNEL_ID, {
  url: _filters.FILTER_URLS.CHANNEL_URL
}), _defineProperty(_FILTER_RELATION_TYPE13, FILTER_COLUMN_TYPE.PARTNER_ID, {
  url: _filters.FILTER_URLS.PARTNER_URL
}), _defineProperty(_FILTER_RELATION_TYPE13, FILTER_COLUMN_TYPE.EMAIL_SETTINGS_TYPE_ID, {
  url: _filters.FILTER_URLS.EMAIL_NOTIFICATION_TYPE_URL
}), _defineProperty(_FILTER_RELATION_TYPE13, FILTER_COLUMN_TYPE.WAREHOUSE_ID, {
  url: _filters.FILTER_URLS.WAREHOUSE_URL
}), _defineProperty(_FILTER_RELATION_TYPE13, FILTER_COLUMN_TYPE.CURRENCY, {
  url: _filters.FILTER_URLS.CURRENCY_URL
}), _defineProperty(_FILTER_RELATION_TYPE13, FILTER_COLUMN_TYPE.STATUS, {
  url: _filters.FILTER_URLS.PRODUCT_STATUSES
}), _defineProperty(_FILTER_RELATION_TYPE13, FILTER_COLUMN_TYPE.REASONS, {
  url: _filters.FILTER_URLS.REASONS
}), _defineProperty(_FILTER_RELATION_TYPE13, FILTER_COLUMN_TYPE.COUNTING_MODE, {
  url: _filters.FILTER_URLS.COUNTING_MODE_URL
}), _FILTER_RELATION_TYPE13)), _UPLOAD_FILTER_URLS);
exports.UPLOAD_FILTER_URLS = UPLOAD_FILTER_URLS;