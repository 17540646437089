"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.data = void 0;

var _redux = require("redux");

var _reducer = require("./value/reducer");

var _reducer2 = require("./views/reducer");

var _reducer3 = require("./filters/reducer");

var data = (0, _redux.combineReducers)({
  views: _reducer2.views,
  value: _reducer.value,
  filters: _reducer3.filters
});
exports.data = data;