"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CHANGE_STATUS_OPERATION = exports.PERMISSIONS = void 0;
var PERMISSIONS = {
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  VIEW_KPIS: 'VIEW_KPIS',
  VIEW_PAYMENTS: 'VIEW_PAYMENTS',
  VIEW_INVOICES: 'VIEW_INVOICES',
  VIEW_IMPORT_SERVICES: 'VIEW_IMPORT_SERVICES',
  VIEW_USER_PERMISSIONS: 'VIEW_USER_PERMISSIONS',
  VIEW_ORDERS: 'VIEW_ORDERS',
  VIEW_LACKING_TRACKING_CODES: 'VIEW_LACKING_TRACKING_CODES',
  VIEW_FIRST_ORDERS: 'VIEW_FIRST_ORDERS',
  VIEW_CANCELLATIONS: 'VIEW_CANCELLATIONS',
  VIEW_SLA: 'VIEW_SLA',
  VIEW_RETURNS: 'VIEW_RETURNS',
  VIEW_BLOCKED_ARTICLES: 'VIEW_BLOCKED_ARTICLES',
  VIEW_OVER_SALES: 'VIEW_OVER_SALES',
  VIEW_PRICE_VALIDATION: 'VIEW_PRICE_VALIDATION',
  VIEW_PRODUCT_STATUS: 'VIEW_PRODUCT_STATUS',
  VIEW_WAREHOUSE_STATISTICS: 'VIEW_WAREHOUSE_STATISTICS',
  VIEW_EMAIL_SETTINGS: 'VIEW_EMAIL_SETTINGS',
  VIEW_IMPORT: 'VIEW_IMPORT',
  VIEW_WARNINGS: 'VIEW_WARNINGS',
  VIEW_PRODUCT_DATA: 'VIEW_PRODUCT_DATA',
  VIEW_STATUS: 'VIEW_STATUS',
  VIEW_BUSINESS_SUMMARY: 'VIEW_BUSINESS_SUMMARY',
  VIEW_ARCHIVE: 'VIEW_ARCHIVE',
  VIEW_ARTICLE_CREATION: 'VIEW_ARTICLE_CREATION',
  VIEW_PRODUCT_DATA_OVERVIEW: 'VIEW_PRODUCT_DATA_OVERVIEW',
  VIEW_PRODUCT_DATA_MESSAGES: 'VIEW_PRODUCT_DATA_MESSAGES',
  VIEW_PRODUCT_DATA_SOLUTIONS: 'VIEW_PRODUCT_DATA_SOLUTIONS',
  VIEW_MEDIA: 'VIEW_MEDIA',
  VIEW_MEDIA_MESSAGES: 'VIEW_MEDIA_MESSAGES',
  VIEW_MEDIA_SOLUTIONS: 'VIEW_MEDIA_SOLUTIONS',
  VIEW_MEDIA_BLOCKED_MEDIA: 'VIEW_MEDIA_BLOCKED_MEDIA',
  VIEW_STATUS_EXPORT_INFORMATION: 'VIEW_STATUS_EXPORT_INFORMATION',
  VIEW_STATUS_CHANNEL_FEEDBACK: 'VIEW_STATUS_CHANNEL_FEEDBACK'
};
exports.PERMISSIONS = PERMISSIONS;
var CHANGE_STATUS_OPERATION = {
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
  RESET: 'RESET'
};
exports.CHANGE_STATUS_OPERATION = CHANGE_STATUS_OPERATION;