"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getError = void 0;

var getError = function getError(state) {
  return state.error;
};

exports.getError = getError;