"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fields = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _reduxActions = require("redux-actions");

var types = _interopRequireWildcard(require("./types"));

var _handleActions;

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var fields = (0, _reduxActions.handleActions)((_handleActions = {}, _defineProperty(_handleActions, types.CREATE, function (state, _ref) {
  var payload = _ref.payload;
  return [].concat(_toConsumableArray(state), [payload]);
}), _defineProperty(_handleActions, types.MOUNT, function (state, _ref2) {
  var payload = _ref2.payload;
  return state.map(function (props) {
    return props.id === payload.id ? _lodash["default"].assign({}, props, {
      mounted: true
    }) : props;
  });
}), _defineProperty(_handleActions, types.UPDATE_PROPS, function (state, _ref3) {
  var payload = _ref3.payload;
  return state.map(function (props) {
    return props.id === payload.id ? _lodash["default"].assign({}, props, payload.props) : props;
  });
}), _defineProperty(_handleActions, types.UNMOUNT, function (state, _ref4) {
  var payload = _ref4.payload;
  return state.filter(function (props) {
    return props.id !== payload.id;
  });
}), _handleActions), []);
exports.fields = fields;