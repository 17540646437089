"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MODAL_TYPES = void 0;
var MODAL_TYPES = {
  DEFAULT: 'DEFAULT',
  ERROR: 'ERROR'
};
exports.MODAL_TYPES = MODAL_TYPES;