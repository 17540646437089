"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tableData = void 0;

var _react = _interopRequireDefault(require("react"));

var _ColumnFilter = require("../../components/FilterForm/ColumnFilter");

var _antd = require("antd");

var _SwitchStatus = require("./SwitchStatus");

var _filters = require("../../constants/filters");

var _columnFilter = require("../../constants/columnFilter");

var _Span = require("../../components/TranslateAware/Span");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var tableData = {
  userPermissions: {
    tableSort: {
      field: 'screen',
      order: 'ascend'
    },
    columns: [{
      title: _react["default"].createElement(_ColumnFilter.ColumnFilter, {
        title: 'partner',
        fieldName: _filters.FILTER_FIELD.PARTNER_ID,
        optionsSource: {
          url: _filters.FILTER_URLS.PARTNER_URL
        },
        mode: _columnFilter.FILTER_MODE_TYPE.MULTIPLE
      }),
      dataIndex: 'partner.company',
      sorter: false
    }, {
      title: _react["default"].createElement(_ColumnFilter.ColumnFilter, {
        title: 'user',
        fieldName: _filters.FILTER_FIELD.USER_ID,
        optionsSource: {
          url: _filters.FILTER_URLS.USERS_URL
        },
        mode: _columnFilter.FILTER_MODE_TYPE.MULTIPLE
      }),
      dataIndex: 'user.username',
      sorter: false
    }, {
      title: 'screen',
      dataIndex: 'permission',
      sorter: false,
      render: function render(field) {
        return _react["default"].createElement(_Span.SpanI18nWithContext, {
          title: field
        });
      }
    }, {
      title: 'enabled',
      dataIndex: 'denied',
      render: function render(field, record, index) {
        return _react["default"].createElement(_antd.Row, {
          type: 'flex'
        }, _react["default"].createElement(_SwitchStatus.SwitchStatus, {
          index: index,
          record: record,
          checked: !field,
          disabled: record.locked
        }));
      },
      sorter: false
    }]
  }
};
exports.tableData = tableData;