"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ORDER_STATUS_PENDING = exports.ORDER_STATUS_FULFILLED = exports.ORDER_STATUS_OPEN = exports.ORDER_STATUS_CONFIRMED = void 0;
var ORDER_STATUS_CONFIRMED = 'confirmed';
exports.ORDER_STATUS_CONFIRMED = ORDER_STATUS_CONFIRMED;
var ORDER_STATUS_OPEN = 'open';
exports.ORDER_STATUS_OPEN = ORDER_STATUS_OPEN;
var ORDER_STATUS_FULFILLED = 'fulfilled';
exports.ORDER_STATUS_FULFILLED = ORDER_STATUS_FULFILLED;
var ORDER_STATUS_PENDING = 'pending';
exports.ORDER_STATUS_PENDING = ORDER_STATUS_PENDING;