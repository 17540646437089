"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ViewSwitch = void 0;

var _reactRedux = require("react-redux");

var _TranslateAware = _interopRequireDefault(require("../TranslateAware/TranslateAware"));

var _ViewSwitch = require("./ViewSwitch");

var _selectors = require("../../store/user/selectors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: (0, _selectors.getUser)(state)
  };
};

var ViewSwitch = (0, _reactRedux.connect)(mapStateToProps)((0, _TranslateAware["default"])(_ViewSwitch.ViewSwitch));
exports.ViewSwitch = ViewSwitch;