"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocale = void 0;

var getLocale = function getLocale(state) {
  return state.locale;
};

exports.getLocale = getLocale;