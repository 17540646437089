"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emailSettingsSagas = void 0;

var _saga = require("./changeProperty/saga");

var _saga2 = require("./changeAllStatus/saga");

var emailSettingsSagas = [_saga.changePropertyWatcher, _saga2.changeAllStatusWatcher];
exports.emailSettingsSagas = emailSettingsSagas;