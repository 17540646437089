"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageAccessForbidden = void 0;

var _TranslateAware = _interopRequireDefault(require("../../components/TranslateAware/TranslateAware"));

var _PageAccessForbidden = require("./PageAccessForbidden");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var PageAccessForbidden = (0, _TranslateAware["default"])(_PageAccessForbidden.PageAccessForbidden, "pageAccessForbidden");
exports.PageAccessForbidden = PageAccessForbidden;