"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customTableViewsSagas = void 0;

var _saga = require("./loadAll/saga");

var _saga2 = require("./save/saga");

var customTableViewsSagas = [_saga.loadAllCustomTableViewsWatcher, _saga2.saveCustomTableViewsWatcher];
exports.customTableViewsSagas = customTableViewsSagas;