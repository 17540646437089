"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showError = showError;
exports.showWarning = showWarning;

var _store = require("store");

var _NotificationsActions = require("../actions/NotificationsActions");

// TODO: refactor notification core.store
function showError(message, hideTimeout) {
  console.error(message);

  _store.store.dispatch((0, _NotificationsActions.errorNotification)(message, hideTimeout));
} // TODO: refactor notification core.store


function showWarning(message, hideTimeout) {
  console.warn(message);

  _store.store.dispatch((0, _NotificationsActions.warningNotification)(message, hideTimeout));
}