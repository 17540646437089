"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCanAccessPdAccounting = exports.getIsAdmin = exports.getUserName = exports.getUserLocale = exports.getUser = void 0;

var getUser = function getUser(state) {
  return state.user;
};

exports.getUser = getUser;

var getUserLocale = function getUserLocale(state) {
  return state.user.locale;
};

exports.getUserLocale = getUserLocale;

var getUserName = function getUserName(state) {
  return state.user.name;
};

exports.getUserName = getUserName;

var getIsAdmin = function getIsAdmin(state) {
  return state.user.admin;
};

exports.getIsAdmin = getIsAdmin;

var getCanAccessPdAccounting = function getCanAccessPdAccounting(state) {
  return state.user.canAccessPdAccounting;
};

exports.getCanAccessPdAccounting = getCanAccessPdAccounting;