"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TableView = void 0;

var _reactRedux = require("react-redux");

var _TranslateAware = require("../../TranslateAware");

var _TableView = require("./TableView");

var _DavaViewProvider = require("../DavaViewProvider");

var _selectors = require("../../../store/customTableViews/selectors");

var _selectors2 = require("../../../store/locale/selectors");

var _translation = require("../../../constants/translation");

var _actions = require("../../../store/apiFlow/download/actions");

var mapStateToProps = function mapStateToProps(state) {
  return {
    multiSortField: state.filterValue.multiSortField,
    locale: (0, _selectors2.getLocale)(state),
    savedViews: (0, _selectors.getCustomTableViews)(state)
  };
};

var mapDispatchToProps = {
  startDownload: _actions.saga
};
var TableView = (0, _TranslateAware.TranslateAware)((0, _DavaViewProvider.DataViewProvider)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_TableView.TableView)), _translation.SKIP_TRANSLATION_CONTEXT);
exports.TableView = TableView;