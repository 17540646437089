"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnFilter = void 0;

var _TranslateAware = require("../../TranslateAware");

var _reactRedux = require("react-redux");

var _ColumnFilter = require("./ColumnFilter");

var _FilterFieldProvider = require("../FilterFieldProvider");

var _translation = require("../../../constants/translation");

var mapStateToProps = function mapStateToProps(state, props) {
  return {
    // TODO: check how it works
    multiSortField: state.filterValue.multiSortField
  };
};

var mapDispatchToProps = {};
var ColumnFilter = (0, _TranslateAware.TranslateAware)((0, _FilterFieldProvider.FilterFieldProvider)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_ColumnFilter.ColumnFilter)), _translation.USE_TRANSLATION_CONTEXT);
exports.ColumnFilter = ColumnFilter;