"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserPermissions = void 0;

var _UserPermissions = require("./UserPermissions");

var _reactRedux = require("react-redux");

var _TranslateAware = _interopRequireDefault(require("../../components/TranslateAware/TranslateAware"));

var _selectors = require("../../store/user/selectors");

var _selectors2 = require("../../store/partnerEmulation/selectors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var mapStateToProps = function mapStateToProps(state) {
  return {
    isAdmin: (0, _selectors.getIsAdmin)(state),
    isPartnerEmulationMode: (0, _selectors2.getIsPartnerEmulationEnabled)(state)
  };
};

var UserPermissions = (0, _reactRedux.connect)(mapStateToProps)((0, _TranslateAware["default"])(_UserPermissions.UserPermissions, "userPermissions"));
exports.UserPermissions = UserPermissions;