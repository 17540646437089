"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.modal = void 0;

var _reduxActions = require("redux-actions");

var types = _interopRequireWildcard(require("./types"));

var _modalTypes = require("../../constants/modalTypes");

var _handleActions;

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  visible: false,
  title: '',
  text: '',
  type: _modalTypes.MODAL_TYPES.DEFAULT,
  buttonText: ''
};
var modal = (0, _reduxActions.handleActions)((_handleActions = {}, _defineProperty(_handleActions, types.SHOW, function (state, _ref) {
  var payload = _ref.payload;
  return _objectSpread({}, state, {
    visible: true,
    title: payload.title,
    text: payload.text,
    type: payload.type,
    buttonText: payload.buttonText
  });
}), _defineProperty(_handleActions, types.SET_TITLE, function (state, _ref2) {
  var payload = _ref2.payload;
  return _objectSpread({}, state, {
    title: payload.title,
    type: payload.type
  });
}), _defineProperty(_handleActions, types.SET_TEXT, function (state, _ref3) {
  var payload = _ref3.payload;
  return _objectSpread({}, state, {
    text: payload.text,
    type: payload.type
  });
}), _defineProperty(_handleActions, types.SET_BUTTON_TEXT, function (state, _ref4) {
  var payload = _ref4.payload;
  return _objectSpread({}, state, {
    buttonText: payload.buttonText
  });
}), _defineProperty(_handleActions, types.CLOSE, function (state, _ref5) {
  var payload = _ref5.payload;
  return _objectSpread({}, state, {
    visible: false,
    title: '',
    text: ''
  });
}), _handleActions), initialState);
exports.modal = modal;