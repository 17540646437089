"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _store = require("store");

var _config = require("../config");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaultLocale = _config.Config.get('settings', 'defaultLocale');

var numberFormatLocale = _config.Config.get('settings', 'numberFormatLocale');

var localeMap = _config.Config.get('settings', 'localeMap');

var keysToEscape = ['brandId'];
var Utils = {
  getLocale: function getLocale() {
    return _store.store.getState().locale;
  },
  getShortLocale: function getShortLocale() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    return locale ? locale.split("-")[0] : this.getLocale().split("-")[0];
  },
  isGermanLocale: function isGermanLocale() {
    return this.getLocale() === 'de-DE';
  },
  getLocaleCode: function getLocaleCode(locale) {
    var languages = localeMap;
    return languages.hasOwnProperty(locale) ? languages[locale].code : languages[defaultLocale].code;
  },
  getLocaleConfig: function getLocaleConfig(locale) {
    var localMap = _config.Config.get('settings', 'localeMap');

    var defaultLocale = _config.Config.get('settings', 'defaultLocale');

    return localMap.hasOwnProperty(locale) ? localMap[locale] : localMap[defaultLocale];
  },
  setMomentLocale: function setMomentLocale(locale) {
    var languages = localeMap;
    var localeObj = languages.hasOwnProperty(locale) ? languages[locale] : languages[defaultLocale];

    _moment["default"].updateLocale(localeObj.momentCode, localeObj.moment);
  },
  renderCurrency: function renderCurrency(fieldName, options) {
    var _this = this;

    return function (field) {
      if (_lodash["default"].isEmpty(field)) return '';
      var value = field[fieldName] || 0;
      return _this.currencyFormat(value, field.currency, options);
    };
  },
  numberFormat: function numberFormat(number, options) {
    if (!_lodash["default"].isNumber(number)) return null;
    return Number(number).toLocaleString(numberFormatLocale, options);
  },
  decimalFormat: function decimalFormat(number, options) {
    if (_lodash["default"].isEmpty(options)) options = {};
    var numberOptions = Object.assign({
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }, options);
    return this.numberFormat(number, numberOptions);
  },
  currencyFormat: function currencyFormat(number, currency, options) {
    var defaultOption = {};
    if (_lodash["default"].isEmpty(options)) options = {};

    if (!_lodash["default"].isEmpty(currency)) {
      defaultOption['style'] = "currency";
      defaultOption['currency'] = currency;
    }

    var numberOptions = Object.assign(defaultOption, options);
    return parseFloat(number).toLocaleString(numberFormatLocale, numberOptions);
  },
  isDataExist: function isDataExist(tables, tableData, dataType) {
    if (!tables[tableData]) return false;
    return !_lodash["default"].isEmpty(tables[tableData][dataType]);
  },
  // TODO: Remove this method. Use redux and state selectors to get value
  isPartnerEmulationMode: function isPartnerEmulationMode() {
    return _store.store.getState().partnerEmulation.partnerId;
  },
  // TODO: Remote this method. User redux and state selectors to get this value
  isLogisticsMode: function isLogisticsMode() {
    var token = _store.store.getState().user.token;

    return token ? this.parseJwt(_store.store.getState().user.token).logisticsMode : false;
  },
  checkAdmin: function checkAdmin(col, isAdmin, partnerColumnDataIndex) {
    var columns = col;

    if (!this.isLogisticsMode()) {
      columns = col.filter(function (col) {
        return col.dataIndex !== 'orderReferenceFulfilment';
      });
    }

    if (isAdmin && !this.isPartnerEmulationMode() && !this.isLogisticsMode()) return columns;
    return columns.filter(function (col) {
      return col.dataIndex !== partnerColumnDataIndex;
    });
  },
  checkIsAdminToDisplayPartnerFilter: function checkIsAdminToDisplayPartnerFilter(isAdmin) {
    return isAdmin && !this.isPartnerEmulationMode();
  },
  checkArticlesCount: function checkArticlesCount(fullFilter) {
    return _axios["default"].post('orders/delayed/checkArticlesCount', _objectSpread({}, fullFilter)).then(function (response) {
      return response.data;
    });
  },
  isLoading: function isLoading(table, data) {
    return data ? data[table] ? data[table].isLoading : true : true;
  },
  escapeEncodedUrl: function escapeEncodedUrl(urlString) {
    return urlString.replaceAll('+', '%2B').replaceAll('&', '%26').replaceAll('$', '%24').replaceAll(',', '%2C').replaceAll('/', '%2F').replaceAll('@', '%40').replaceAll(',', '%2C').replaceAll('!', '%21').replaceAll(' ', '+');
  },
  addToFavorites: function addToFavorites(filterParams) {
    return _axios["default"].post('favorites/add', {
      tab: String(filterParams.currentTab),
      favName: String(filterParams.favName),
      filterData: String(this.buildBodyString(filterParams.filterData)),
      userName: String(filterParams.user)
    }, {
      params: {
        chosenKPI: String(filterParams.chosenKPI)
      }
    }).then(function (response) {
      return response.data;
    });
  },
  getFavorites: function getFavorites(user, currentTab, chosenKPI) {
    var parameters = {
      user: user,
      currentTab: currentTab,
      chosenKPI: chosenKPI
    };
    return _axios["default"].get('favorites/get', {
      params: parameters
    }).then(function (response) {
      return response.data;
    });
  },
  deleteFavorites: function deleteFavorites(filterParams) {
    return _axios["default"].get('favorites/delete', {
      params: filterParams
    }).then(function (response) {
      return response.data;
    });
  },
  resetFilters: function resetFilters() {
    for (var _len = arguments.length, filters = new Array(_len), _key = 0; _key < _len; _key++) {
      filters[_key] = arguments[_key];
    }

    Object.keys(filters).map(function (p, i) {
      if (filters[i].fromDate) {
        filters[i].fromDate = (0, _moment["default"])().startOf('year').format('DD.MM.YYYY HH:mm:ss');
      }

      if (filters[i].toDate) {
        filters[i].toDate = (0, _moment["default"])().endOf('day').format('DD.MM.YYYY HH:mm:ss');
      }

      return filters;
    });
  },
  capitalizeFirstLetter: function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  diff: function diff(first, second) {
    return _lodash["default"].transform(first, function (result, value, key) {
      if (!_lodash["default"].isEqual(value, second[key])) {
        result[key] = _lodash["default"].isObject(value) && _lodash["default"].isObject(second[key]) ? Utils.diff(value, second[key]) : value;
      }
    });
  },
  parseJwt: function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  },
  cancelDoubleRequest: function cancelDoubleRequest(requestParams, reqInitiatorObj) {
    var CancelToken = _axios["default"].CancelToken;

    if (reqInitiatorObj.hasOwnProperty('_cancelDoubleReqMethod') && typeof reqInitiatorObj._cancelDoubleReqMethod === 'function') {
      reqInitiatorObj._cancelDoubleReqMethod("Cancel double request");
    }

    requestParams['cancelToken'] = new CancelToken(function (c) {
      reqInitiatorObj._cancelDoubleReqMethod = c;
    });
  },
  graphItemsSort: function graphItemsSort(a, b, data) {
    var aStack = data.datasets[a.datasetIndex].stack;
    var bStack = data.datasets[b.datasetIndex].stack;

    if (aStack !== bStack) {
      return aStack - bStack;
    }

    var aData = data.datasets[a.datasetIndex].data[a.index];
    var bData = data.datasets[b.datasetIndex].data[b.index];
    return Math.abs(aData) < Math.abs(bData) ? 1 : -1;
  },
  graphReverseSort: function graphReverseSort(a, b, data) {
    return 1;
  },
  updateUserCustomViews: function updateUserCustomViews(view) {
    return _axios["default"].get('custom-table-view/save', {
      params: view
    }).then(function (response) {
      return response.data;
    });
  },
  getWindowDimensions: function getWindowDimensions() {
    var _window = window,
        width = _window.innerWidth,
        height = _window.innerHeight;
    return {
      width: width,
      height: height
    };
  },
  getOrientation: function getOrientation() {
    var _window2 = window,
        width = _window2.innerWidth,
        height = _window2.innerHeight;
    return width < height ? 'portrait' : 'landscape';
  },
  getChartFontSize: function getChartFontSize() {
    return this.getWindowDimensions().height > 768 ? 12 : 10;
  },
  mapOptions: function mapOptions(rawOptions) {
    var options = [];

    _lodash["default"].forEach(rawOptions, function (option) {
      if (_typeof(option) === 'object') {
        var name;

        if (option.name) {
          name = option.name;
        } else if (option.company) {
          name = option.company;
        } else {
          name = option.description;
        }

        options.push({
          id: encodeURI(option.id.toString()),
          name: name,
          qty: _lodash["default"].get(option, 'qty')
        });
      } else {
        options.push({
          id: option,
          name: option
        });
      }
    });

    return options;
  },
  // TODO: remove this. map options where set dataSource core.constants
  formatRemoteOptions: function formatRemoteOptions(rawOptions) {
    var options = [];

    if (!_lodash["default"].isEmpty(rawOptions)) {
      _lodash["default"].forEach(rawOptions, function (item) {
        var name; // TODO: check and refactor this conditions

        if (item.name) {
          name = item.name;
        } else if (item.company) {
          name = item.company;
        } else {
          name = item.description;
        }

        if (item.id && !_lodash["default"].isEmpty(item.id.toString())) {
          options.push({
            id: encodeURI(item.id),
            name: name,
            currency: _lodash["default"].has(item, 'billing') ? item.billing.currency : ''
          });
        }
      });
    }

    return options;
  },
  deleteMouseOverStyleByClassName: function deleteMouseOverStyleByClassName(className) {
    var parentElement = document.getElementsByClassName(className);

    for (var i = 0; i < parentElement.length; ++i) {
      var item = parentElement[i];
      item.className = item.className.replace(' selected', '');
    }
  },
  addMouseOverStyleByElementId: function addMouseOverStyleByElementId(elementId) {
    var element = document.getElementById(elementId);

    if (element !== null) {
      element.className += ' selected';
    }
  },
  compareStringsUsingLocale: function compareStringsUsingLocale(left, right, locale) {
    if (locale === 'debugLocale') {
      return left.localeCompare(right, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return left.localeCompare(right, locale, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  },
  onlyUnique: function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  },
  buildBodyString: function buildBodyString(params) {
    var _this2 = this;

    var keys = Object.keys(params);

    _lodash["default"].forEach(keys, function (key) {
      if (params[key] == null || !keysToEscape.includes(key)) return;
      var isParamTypeArray = Array.isArray(params[key]);

      if (!isParamTypeArray) {
        params[key] = _this2.escapeEncodedUrl(params[key]);
      } else {
        _lodash["default"].forEach(params[key], function (reason, i) {
          params[key][i] = _this2.escapeEncodedUrl(params[key][i]);
        });
      }
    });

    return JSON.stringify(params);
  },
  buildQueryString: function buildQueryString(params) {
    var _this3 = this;

    var keys = Object.keys(params);
    var options = '';

    _lodash["default"].forEach(keys, function (key) {
      if (params[key] == null) return;

      var isParamTypeObject = _lodash["default"].isObject(params[key]);

      var isParamTypeArray = Array.isArray(params[key]);

      if (!isParamTypeObject) {
        options += "".concat(key, "=").concat(params[key], "&");
      }

      if (isParamTypeArray) {
        if (key === 'reasons') {
          _lodash["default"].forEach(params[key], function (reason, i) {
            params[key][i] = encodeURIComponent(params[key][i].replace(/,/, '%2C'));
          });
        }

        params[key].forEach(function (element) {
          options += "".concat(key, "=").concat(element, "&");
        });
      }

      if (isParamTypeObject && !isParamTypeArray) {
        var stringObj = JSON.stringify(params[key]);
        stringObj = encodeURI(stringObj);
        options += "".concat(key, "=").concat(_this3.escapeEncodedUrl(stringObj), "&");
      }
    });

    return options ? '?' + options.slice(0, -1) : options;
  },
  toBase64: function toBase64(file) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        return resolve(reader.result);
      };

      reader.onerror = function (error) {
        return reject(error);
      };
    });
  }
};
var _default = Utils;
exports["default"] = _default;