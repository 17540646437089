"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fullFilter = fullFilter;
exports.cumulativeFilter = cumulativeFilter;

var _moment = _interopRequireDefault(require("moment/moment"));

var _dateTypes = require("../constants/dateTypes");

var _actionTypes = require("../constants/actionTypes");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initState = {
  fromDate: (0, _moment["default"])().startOf('year').format(_dateTypes.DATE_TIME_TYPE),
  toDate: (0, _moment["default"])().endOf('day').format(_dateTypes.DATE_TIME_TYPE)
};

function fullFilter() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.SET_ORDER_FILTER_DATA:
      if (action.data && (action.data.fromDate || action.data.toDate)) {
        return _objectSpread({}, state, {}, action.data);
      }

      return state;

    case _actionTypes.SET_FULL_FILTER_DATA:
      return _objectSpread({}, state, _defineProperty({}, action.table, _objectSpread({}, state[action.table], {}, action.data)));

    case _actionTypes.RESET_ORDER_FILTER_DATA:
      return _objectSpread({}, state, _defineProperty({}, action.table, {}));

    default:
      return state;
  }
}

function cumulativeFilter() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.SET_ORDER_FILTER_DATA:
      if (action.data && (action.data.fromDate || action.data.toDate)) {
        return _objectSpread({}, state, {}, action.data);
      }

      return state;

    case _actionTypes.SET_CUMULATIVE_FILTER_DATA:
      return _objectSpread({}, state, _defineProperty({}, action.table, _objectSpread({}, state[action.table], {}, action.data)));

    case _actionTypes.RESET_ORDER_FILTER_DATA:
      return _objectSpread({}, state, _defineProperty({}, action.table, {}));

    default:
      return state;
  }
}