"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Sidebar = void 0;

var _reactRedux = require("react-redux");

var _Sidebar = require("./Sidebar");

var _TranslateAware = require("../TranslateAware");

var _selectors = require("../../store/partnerEmulation/selectors");

var _selectors2 = require("../../store/user/selectors");

var _selectors3 = require("../../store/logistics/selectors");

var _router = require("../../router");

var _selectors4 = require("../../store/version/selectors");

var mapStateToProps = function mapStateToProps(state) {
  return {
    orders: state.tables,
    user: (0, _selectors2.getUser)(state),
    // We mean that canAccessPdAccounting false if in partner emulation mode
    canAccessPdAccounting: (0, _selectors.getIsPartnerEmulationEnabled)(state) ? false : (0, _selectors2.getCanAccessPdAccounting)(state),
    isLogisticsMode: (0, _selectors3.getIsLogisticsMode)(state),
    isAdmin: (0, _selectors2.getIsAdmin)(state),
    isTestEnvironment: (0, _selectors4.getIsTestEnvironment)(state),
    isDevEnvironment: (0, _selectors4.getIsDevEnvironment)(state)
  };
};

var Sidebar = (0, _TranslateAware.TranslateAware)((0, _router.withRouter)((0, _reactRedux.connect)(mapStateToProps)(_Sidebar.Sidebar)));
exports.Sidebar = Sidebar;