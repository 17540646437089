"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FAILURE = exports.SUCCESS = exports.REQUEST = exports.SAGA = void 0;
var SAGA = 'apiFlow/userPermissions/changeStatusBatch/SAGA';
exports.SAGA = SAGA;
var REQUEST = 'apiFlow/userPermissions/changeStatusBatch/REQUEST';
exports.REQUEST = REQUEST;
var SUCCESS = 'apiFlow/userPermissions/changeStatusBatch/SUCCESS';
exports.SUCCESS = SUCCESS;
var FAILURE = 'apiFlow/userPermissions/changeStatusBatch/FAILURE';
exports.FAILURE = FAILURE;