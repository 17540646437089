"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dataSagas = void 0;

var _saga = require("./value/saga");

var _saga2 = require("./filters/saga");

var dataSagas = [_saga2.dataViewFiltersWatcher, _saga.dataLoadFlow];
exports.dataSagas = dataSagas;