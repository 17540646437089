"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reset = exports.setField = exports.set = exports.apply = void 0;

var _reduxActions = require("redux-actions");

var types = _interopRequireWildcard(require("./types"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var apply = (0, _reduxActions.createAction)(types.APPLY, function (dataType, value, isViewUpdateRequired) {
  return {
    dataType: dataType,
    value: value,
    isViewUpdateRequired: isViewUpdateRequired
  };
}); // TODO: temporary need to fix bug. refactor this. in redux apply and set got same logic

exports.apply = apply;
var set = (0, _reduxActions.createAction)(types.SET, function (dataType, value) {
  return {
    dataType: dataType,
    value: value
  };
});
exports.set = set;
var setField = (0, _reduxActions.createAction)(types.SET_FIELD, function (dataType, fieldName, value) {
  return {
    dataType: dataType,
    fieldName: fieldName,
    value: value
  };
});
exports.setField = setField;
var reset = (0, _reduxActions.createAction)(types.RESET, function (dataType) {
  return {
    dataType: dataType
  };
});
exports.reset = reset;