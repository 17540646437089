"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCustomTableViews = void 0;

var getCustomTableViews = function getCustomTableViews(state) {
  return state.customTableViews;
};

exports.getCustomTableViews = getCustomTableViews;