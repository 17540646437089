"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ERROR_CODES = void 0;
var ERROR_CODES = {
  NO_AUTH_TOKEN_EXISTS: 'no_auth_token_exists',
  REFRESH_FAILURE: 'refresh_failure',
  TIMEOUT: 'timeout',
  HTTP_ERROR: 'http_error',
  ABORT: 'abort',
  ACCESS_TOKEN_EXPIRED: 'access_token_expired',
  REFRESH_TOKEN_EXPIRED: 'refresh_token_expired',
  INVALID_CREDENTIALS: 'invalid_credentials',
  VALIDATION_ERROR: 'validation_error',
  INVALID_ACCESS_TOKEN: 'invalid_access_token',
  INVALID_REFRESH_TOKEN: 'invalid_refresh_token',
  NOT_ALLOWED_FOR_THIS_PARTNER: 'not_allowed_fot_this_partner',
  DATA_ACCESS_EXCEPTION: 'data_access_exception',
  ACCESS_FORBIDDEN: 'access_forbidden',
  UNKNOWN_ERROR: 'unknown_error'
};
exports.ERROR_CODES = ERROR_CODES;