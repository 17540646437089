"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllowedMenuItems = exports.canAccess = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var canAccess = function canAccess(user, permission) {
  return _lodash["default"].get(user, "allowedRights", []).includes(permission);
};

exports.canAccess = canAccess;

var getAllowedMenuItems = function getAllowedMenuItems(menu, user) {
  return _lodash["default"].filter(menu, function (item) {
    return !_lodash["default"].has(item, 'access') || canAccess(user, item.access);
  });
};

exports.getAllowedMenuItems = getAllowedMenuItems;