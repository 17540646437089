"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LanguageSelector", {
  enumerable: true,
  get: function get() {
    return _LangaugeSelector.LanguageSelector;
  }
});

var _LangaugeSelector = require("./LangaugeSelector.connect");