"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_PROPS = exports.UNMOUNT = exports.MOUNT = exports.CREATE = void 0;
var CREATE = 'filter/fields/CREATE';
exports.CREATE = CREATE;
var MOUNT = 'filter/fields/MOUNT';
exports.MOUNT = MOUNT;
var UNMOUNT = 'filter/fields/UNMOUNT';
exports.UNMOUNT = UNMOUNT;
var UPDATE_PROPS = 'filter/fields/UPDATE_PROPS';
exports.UPDATE_PROPS = UPDATE_PROPS;