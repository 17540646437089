"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWarehousesAvailable = exports.getServedPartners = exports.getIsLogisticsMode = void 0;

var getIsLogisticsMode = function getIsLogisticsMode(state) {
  return state.logistics.logisticsMode;
};

exports.getIsLogisticsMode = getIsLogisticsMode;

var getServedPartners = function getServedPartners(state) {
  return state.logistics.servedPartners;
};

exports.getServedPartners = getServedPartners;

var getWarehousesAvailable = function getWarehousesAvailable(state) {
  return state.logistics.warehousesAvailable;
};

exports.getWarehousesAvailable = getWarehousesAvailable;