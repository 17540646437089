"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeStatusBatch = void 0;

var _reduxActions = require("redux-actions");

var types = _interopRequireWildcard(require("./types"));

var _handleActions;

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var changeStatusBatch = (0, _reduxActions.handleActions)((_handleActions = {}, _defineProperty(_handleActions, types.REQUEST, function (state, _ref) {
  var payload = _ref.payload;
  return {
    isFetching: true,
    error: false
  };
}), _defineProperty(_handleActions, types.SUCCESS, function (state, _ref2) {
  var payload = _ref2.payload;
  return {
    isFetching: false,
    error: false
  };
}), _defineProperty(_handleActions, types.FAILURE, function (state, _ref3) {
  var payload = _ref3.payload;
  return {
    isFetching: false,
    error: true
  };
}), _handleActions), {
  isFetching: false,
  error: false
});
exports.changeStatusBatch = changeStatusBatch;