"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsTokenExpiredError = exports.getRefreshToken = exports.getAccessToken = exports.getTokenType = exports.getAuthState = exports.getIsAuth = void 0;

var getIsAuth = function getIsAuth(state) {
  return Boolean(state.auth.accessToken);
};

exports.getIsAuth = getIsAuth;

var getAuthState = function getAuthState(state) {
  return state.auth;
};

exports.getAuthState = getAuthState;

var getTokenType = function getTokenType(state) {
  return state.auth.tokenType;
};

exports.getTokenType = getTokenType;

var getAccessToken = function getAccessToken(state) {
  return state.auth.accessToken;
};

exports.getAccessToken = getAccessToken;

var getRefreshToken = function getRefreshToken(state) {
  return state.auth.refreshToken;
};

exports.getRefreshToken = getRefreshToken;

var getIsTokenExpiredError = function getIsTokenExpiredError(state) {
  return state.auth.tokenExpiredError;
};

exports.getIsTokenExpiredError = getIsTokenExpiredError;