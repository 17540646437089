"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsError = exports.getIsDataLoading = exports.getIsFetching = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// TODO: deprecated. replace to getIsDataLoading
var getIsFetching = function getIsFetching(state, dataType) {
  return _lodash["default"].get(state.apiFlow.data, [dataType, 'isFetching'], false);
};

exports.getIsFetching = getIsFetching;

var getIsDataLoading = function getIsDataLoading(state, dataType) {
  return _lodash["default"].get(state.apiFlow.data, [dataType.type, 'isFetching'], false);
};

exports.getIsDataLoading = getIsDataLoading;

var getIsError = function getIsError(state, dataType) {
  return _lodash["default"].get(state.apiFlow.data, [dataType, 'error'], false);
};

exports.getIsError = getIsError;