"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsDevEnvironment = exports.getIsTestEnvironment = exports.getVersion = void 0;

var getVersion = function getVersion(state) {
  return state.version;
};

exports.getVersion = getVersion;

var getIsTestEnvironment = function getIsTestEnvironment(state) {
  return Boolean(state.version.isTestEnvironment);
};

exports.getIsTestEnvironment = getIsTestEnvironment;

var getIsDevEnvironment = function getIsDevEnvironment(state) {
  return Boolean(state.version.isDevEnvironment);
};

exports.getIsDevEnvironment = getIsDevEnvironment;