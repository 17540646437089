"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.allReducers = void 0;

var _productStatusFilter = _interopRequireDefault(require("./productStatusFilter"));

var _settings = _interopRequireDefault(require("./settings"));

var _notifications = _interopRequireDefault(require("./notifications"));

var _filterValue = _interopRequireDefault(require("./filterValue"));

var _filterOptions = _interopRequireDefault(require("./filterOptions"));

var _view = _interopRequireDefault(require("./view"));

var _ordersFilterData = _interopRequireDefault(require("./ordersFilterData"));

var _tables = require("./tables");

var _tablesFilterReducer = require("./tablesFilterReducer");

var _tableSelectReducer = require("./tableSelectReducer");

var _apiFlow = require("./apiFlow");

var _data = require("./data");

var _filters = require("./filters");

var _reducer = require("./auth/reducer");

var _reducer2 = require("./user/reducer");

var _reducer3 = require("./customTableViews/reducer");

var _reducer4 = require("./error/reducer");

var _reducer5 = require("./locale/reducer");

var _reducer6 = require("./messages/reducer");

var _app = require("./app");

var _reducer7 = require("./version/reducer");

var _reducer8 = require("./logistics/reducer");

var _reducer9 = require("./partnerEmulation/reducer");

var _reducer10 = require("./modal/reducer");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var allReducers = {
  tables: _tables.tables,
  // modal,
  // download,
  ordersFilter: _ordersFilterData["default"],
  fullFilter: _tablesFilterReducer.fullFilter,
  cumulativeFilter: _tablesFilterReducer.cumulativeFilter,
  productStatusFilter: _productStatusFilter["default"],
  tableSelect: _tableSelectReducer.tableSelect,
  settings: _settings["default"],
  notifications: _notifications["default"],
  filterValue: _filterValue["default"],
  filterOptions: _filterOptions["default"],
  view: _view["default"],
  // Refactored stores
  apiFlow: _apiFlow.apiFlow,
  data: _data.data,
  filters: _filters.filters,
  auth: _reducer.auth,
  user: _reducer2.user,
  customTableViews: _reducer3.customTableViews,
  error: _reducer4.error,
  locale: _reducer5.locale,
  messages: _reducer6.messages,
  app: _app.app,
  version: _reducer7.version,
  logistics: _reducer8.logistics,
  partnerEmulation: _reducer9.partnerEmulation,
  modal: _reducer10.modal
};
exports.allReducers = allReducers;