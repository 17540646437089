"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userPermissions = void 0;

var _redux = require("redux");

var _reducer = require("./changeStatus/reducer");

var _reducer2 = require("./changeStatusBatch/reducer");

var userPermissions = (0, _redux.combineReducers)({
  changeStatus: _reducer.changeStatus,
  changeStatusBatch: _reducer2.changeStatusBatch
});
exports.userPermissions = userPermissions;