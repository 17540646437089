"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ColumnFilter", {
  enumerable: true,
  get: function get() {
    return _ColumnFilter.ColumnFilter;
  }
});

var _ColumnFilter = require("./ColumnFilter.connect");