import { all, fork } from 'redux-saga/effects';
import { allSagas } from "@onquality/dashboard-ui/lib/store/allSagas";

const sagas = [
    ...allSagas,
];

export function* rootSaga() {
    yield all(sagas.map(fork));
}
