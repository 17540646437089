"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ViewSwitch", {
  enumerable: true,
  get: function get() {
    return _ViewSwitch.ViewSwitch;
  }
});

var _ViewSwitch = require("./ViewSwitch.connect");