"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DATE_TIME_SHORT_TYPE = exports.DATE_TIME_TYPE = exports.DATE_TYPE = void 0;
// TODO: rename to DATE_FORMAT & DATE_TIME_FORMAT
var DATE_TYPE = 'DD.MM.YYYY';
exports.DATE_TYPE = DATE_TYPE;
var DATE_TIME_TYPE = 'DD.MM.YYYY HH:mm:ss';
exports.DATE_TIME_TYPE = DATE_TIME_TYPE;
var DATE_TIME_SHORT_TYPE = 'DD.MM.YYYY HH:mm';
exports.DATE_TIME_SHORT_TYPE = DATE_TIME_SHORT_TYPE;