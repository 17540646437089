"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Full = void 0;

var _selectors = require("../../store/user/selectors");

var _reactRedux = require("react-redux");

var _Full = require("./Full");

var mapStateToProps = function mapStateToProps(state) {
  return {
    isAdmin: (0, _selectors.getIsAdmin)(state)
  };
};

var Full = (0, _reactRedux.connect)(mapStateToProps)(_Full.Full);
exports.Full = Full;